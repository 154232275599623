import SocialActions from "../utils/SocialActions";
import SubscribeLocation from "../utils/SubscribeLocation";
import ListFollowing from "../views/ListFollowing";
import ListFollowers from "../views/ListFollowers";
import ListLocalities from "../views/ListLocalities";

export default class UserFollow {

    constructor() {
        this.bindEvents();
        this.socialActions = new SocialActions();
        this.subscribeLocation = new SubscribeLocation();
        this.listFollowing = new ListFollowing();
        this.listFollowers = new ListFollowers();
        this.listLocalities = new ListLocalities();
        window.application.setOnDataChangeListener(this);
        this.selectTab();
    }


    bindEvents() {
    }

    onDataChanged(data) {
        this.listFollowing.onDataChanged(data);
        this.listFollowers.onDataChanged(data);
        this.listLocalities.onDataChanged(data);
    }

    onUpdate(){
        this.socialActions.onUpdate();
        this.subscribeLocation.onUpdate();
    }

    onDestroy() {
        this.socialActions.onDestroy();
        this.subscribeLocation.onDestroy();
        this.listFollowing.onDestroy();
        this.listFollowers.onDestroy();
        this.listLocalities.onDestroy();
    }

    selectTab() {
        const url = document.location.toString();
        if (url.match('tab=')) {
            window.$('a[href="#' + url.split('tab=')[1] + '"]').tab('show');
        }else{
            window.$('a[href="#followers"]').tab('show');
        }
    }
}