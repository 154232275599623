import WorldMap from "../utils/WorldMap.js"
import WorldTable from "../utils/WorldTable.js"

export default class LiveWorldMap {

  constructor() {

    this.players = {};
    this.categoryHash = {};
    this.countryHash = {};

    this.mapHandler = new WorldMap(this);
    this.tableHandler = new WorldTable(this);

    this.following = [];

    this.getConfig();

    this.getLives();


    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    $(document).off('socket-LiveChannel').on('socket-LiveChannel', function (e, data) {
      self.onSocketMessageReceived(data);
    });

    window.application.socket_handler.subscribe({ channel: "LiveChannel", event: "new-live" })
  }

  getConfig() {
    var self = this;
    var ajaxData = {
        url: window.application.getAPIUrl()+"/v1/config/",
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
        },
    };

    $.ajax(ajaxData).done(function(json) {
      var categoriesArray = json.categories;
      var countriesArray = json.countries;

      for(var i =0; i<categoriesArray.length;i++) {
          var category = categoriesArray[i];
          self.categoryHash[category.id] = category;
      }

      for(var i =0; i<countriesArray.length;i++) {
          var country = countriesArray[i];
          self.countryHash[country.code] = country;
      }

      self.tableHandler.refreshDisplay();

    }).fail(function(err) {
      
    });
  }

  getLives() {
    var self = this;
    var ajaxData = {
        url: window.application.getAPIUrl()+"/v1/live/",
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
        },
    };

    $.ajax(ajaxData).done(function(json) {
      self.mapHandler.onLiveDataChanged(json);
      self.tableHandler.onLiveDataChanged(json);

      self.tableHandler.refreshDisplay();
    }).fail(function(err) {
      
    });
  }

  follow(p) {
    this.mapHandler.panTo(p);
    window.application.socket_handler.subscribe({ channel: "LiveChannel", id: p.player.user_id })
  }

  unfollow(p) {
    window.application.socket_handler.unsubscribe({ channel: "LiveChannel", id: p.player.user_id })
  }

  onSocketMessageReceived(data) {

    if(data.e=="point-add") {
      this.mapHandler.onPointReceived(data);
    }
    else if(data.e=="new-live") {
      this.getLives();
    }
  }
}