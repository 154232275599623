export default class LocalityBestScores {
    constructor() {
        this.location_id = 0;
        this.activity_type = 0;
        this.selected_range = 0;
        this.bindEvents();
    }

    bindEvents() {
        let self = this;
        $(".js-filter-best-scores").unbind("click").click(function (e) {
            e.preventDefault();
            self.setFilter(this);
        })
    }


    onDataChanged(data){
        this.location_id = data.location_id;
        this.activity_type = data.activity_type;
        this.reloadScores();
    }

    onDestroy(){
        $(".js-filter-best-scores").unbind("click")
    }


    reloadScores() {
        $.ajax({
            url: '/localities/ajax_best_scores/'+ this.location_id,
            type: "get",
            data: {
                activity_type: this.activity_type,
                selected_range: this.selected_range
            },
            success: function(html) {
                $("#scores-container").empty();
                $("#scores-container").append(html);
                $("#score-loader").removeClass("d-flex");
                $("#score-loader").addClass("d-none");
            },
            error: function(data) {
            }
        });
    }

    setFilter(item) {
        let newFilter = parseInt($(item).data("id"))
        if (this.selected_range !== newFilter){
            this.selected_range = newFilter;
            $("#js-best-scores-btn-span").text($(item).data("name"));
            this.reloadScores()
        }
    }
}