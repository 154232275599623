import ConfirmationModal from "../../common/ConfirmationModal";

export default class LocalitiesIndex {

    constructor() {
        this.confirmationModal = new ConfirmationModal();
        this.bindEvents();
    }

    bindEvents(){
        let self = this;

        $(".js-subscribe").unbind("click").click(function (e) {
            e.preventDefault();
            if(cookies.get("cs") !== null){
                self.subscribe($(this).data("id"));
            }else{
                window.location.replace("/users/sign_in");
            }
        });

        $(".js-unsubscribe").unbind("click").click(function (e) {
            e.preventDefault();
            let item = this;
            if(cookies.get("cs") !== null){
                self.confirmationModal.displayConfirm(I18n.t("website.localities.unsubscribe.title"),
                    I18n.t("website.localities.unsubscribe.message"),
                    {
                        positive_btn: {
                            type: "danger",
                            label: I18n.t("website.localities.unsubscribe.positive_btn"),
                            callback: function() {
                                self.unsubscribe($(item).data("id"));
                            }
                        },
                        negative_btn: {
                            label: I18n.t("website.localities.unsubscribe.negative_btn")
                        }
                    });
            }else{
                window.location.replace("/users/sign_in");
            }
        });
    }

    onDataChanged(data) {
    }

    onDestroy(){

    }

    subscribe(id){
        $(".js-subscribe").unbind("click");
        var self = this;
        var ajaxData = {
            url: window.application.getAPIUrl()+"/v1/localities/"+id+"/subscribe",
            data: {},
            method: "POST",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                $(".js-unsubscribe[data-id="+data.id+"]").removeClass("d-none");
                $(".js-subscribe[data-id="+data.id+"]").addClass("d-none");
                let count = $(".js-subscribers[data-id="+data.id+"]").data("subscribers") + 1;
                $(".js-subscribers[data-id="+data.id+"]").data("subscribers", count);
                $(".js-subscribers[data-id="+data.id+"]").text(count);
            }
            self.onUpdate();
        }).fail(function(err) {
            self.onUpdate();
        });
    }

    unsubscribe(id){
        $(".js-unsubscribe[data-id="+id+"]").unbind("click");
        var self = this;
        var ajaxData = {
            url: window.application.getAPIUrl()+"/v1/localities/"+id+"/unsubscribe",
            data: {},
            method: "DELETE",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                $(".js-unsubscribe[data-id="+data.id+"]").addClass("d-none");
                $(".js-subscribe[data-id="+data.id+"]").removeClass("d-none");
                let count = $(".js-subscribers[data-id="+data.id+"]").data("subscribers") - 1;
                $(".js-subscribers[data-id="+data.id+"]").data("subscribers", count);
                $(".js-subscribers[data-id="+data.id+"]").text(count);
            }
            self.onUpdate();
        }).fail(function(err) {
            self.onUpdate();
        });
    }

    onUpdate(){
        this.onDestroy();
        this.bindEvents();
    }
}