import ToastNotification from "../utils/ToastNotification";

export default class ConfigurationPureTrack {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        let self = this;

        $("#change_puretrack_settings").unbind("click").click(function (e) {
            e.preventDefault();
            self.changePuretrackSettings();
        });
    }

    onDataChanged(data) {
        let self = this;

    }

    onDestroy() {
        $("#change_ffvl_key").unbind("click");
        this.validation.destroy();
    }

    changePuretrackSettings() {
    
        let self = this;
        let data = {
            enable_puretrack: $("input[name='enable_puretrack']:checked").is(":checked"),
        };

        let ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/update_puretrack_setting",
            data: {
                user: data
            },
            method: "PUT",
            enctype: 'multipart/form-data',
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };


        $.ajax(ajaxData).done(function (data) {
            if ($("input[name='enable_puretrack']:checked").is(":checked")) {
                ToastNotification.make(I18n.t("website.configuration.index.puretrack.enabled"));
            } else {
                ToastNotification.make(I18n.t("website.configuration.index.puretrack.disabled"));
            }
            
        }).fail(function (err) {
            ToastNotification.make(err.responseJSON.errors.join("<br>"));
        });        
    }
}