import CesiumManagerWrapper from "./CesiumManagerWrapper";
import Abstract3DMapHandler from "./Abstract3DMapHandler";
import PointsConverter from "./PointsConverter"

export default class SceneMap3DHandler extends Abstract3DMapHandler {

    constructor(first_appear_ad = 100, frequency_ad = 100) {
        super(first_appear_ad, frequency_ad);

        this.scene_id = null;
        this.owner_id = null;

        this.track_ids = [];
        this.loaded_ids = [];

        this.track_count = 0;

        this.scene_type = "other";
        // this.start_timer();
    }

    start_timer() {
        super.start_timer("replay_map");
    }

    async init(data) {
        this.tracks = data.tracks;
        this.points = data.points;
        this.owner_id = data.owner_id;
        this.scene_id = data.scene_id;
        this.track_ids = data.track_ids;
        this.colors = data.colors;
        this.activity_icon = data.activity_icon;
        this.time_zone = data.time_zone;
        this.scene_type = data.scene_type;
        this.path_length = data.path_length;
        this.host = data.host;

        if (this.scene_id == null) {
            var userData = [];
            for (var i = 0; i < this.tracks.length; i++) {
                userData.push(
                    {
                        userId: this.tracks[i].user.id,
                        userName: this.tracks[i].user.user_name,
                        track: {
                            id: this.tracks[i].id,
                            color: this.tracks[i].user.color,
                            started_at: this.tracks[i].started_at,
                            finished_at: this.tracks[i].finished_at,
                            created_at: this.tracks[i].created_at,
                            activity_icon: this.activity_icon,
                            time_zone: this.time_zone,
                            maxAltitude: this.tracks[i].max_altitude,                            
                            points: this.points[i]
                        }
                    }
                );
            }

            await CesiumManagerWrapper.initScene("map3D", {
                users: userData,
                checkContestType: "scene",
                hostInUse: this.host,
                pathsLength: this.path_length || 1230,
                sceneId: this.scene_id,
                staticLabels: []
            });
        }
        else {
            this.tracks = [];
            this.points = [];
            this.track_count = this.track_ids.length;

            $("#loading_scene_modal").modal({
                backdrop: 'static',
                keyboard: false
            });
          await  this.loadTracks();
        }
    }

    async loadTracks() {
        this.displayLoadingProgress();
        const loading = this.track_ids.map((x,i)=> this.getTrack(x,i));
        await Promise.all(loading);
       
        await this.getAround();
        await this.startCesium();
    }
    
    displayLoadingProgress() {
        $("#scene_loading_progress_text").html(I18n.t("website.scene.show.progress", { user_loaded: this.loaded_ids.length, total_users: this.track_count }));
        $("#scene_loading_progress").css("width", Math.round(((this.loaded_ids.length / this.track_count) * 100)) + "%");
    }   

    async startCesium() {
        this.displayLoadingProgress();
        var userData = [];
        for (var i = 0; i < this.tracks.length; i++) {
            userData.push(
                {
                    userId: this.tracks[i].user.id,
                    userName: this.tracks[i].user.user_name,
                    track: {
                        id: this.tracks[i].id,
                        color: this.colors[i],
                        activity_icon: this.activity_icon,
                        started_at: this.tracks[i].started_at,
                        finished_at: this.tracks[i].finished_at,
                        created_at: this.tracks[i].created_at,
                        time_zone: this.time_zone,
                        maxAltitude: this.tracks[i].max_altitude,                        
                        raw_points_data: this.points[i]
                    }
                }
            );
        }
        
        await CesiumManagerWrapper.initScene("map3D", {
            users: userData,
            sceneType: this.scene_type,
            hostInUse: this.host,
            pathsLength: this.path_length || 1230,
            sceneId: this.scene_id,
            windBearing: this.wind_bearing,
            staticLabels: this.localities_around
        });

        setTimeout(function () {
            $("#loading_scene_modal").modal("hide");
        }, 500)
    }

    getTrack(id,index) {
        return new Promise((resolve, reject) => {
            const ajaxData = {
                url: window.application.getAPIUrl() + "/v1/scene/" + this.scene_id + "/" + id,
                method: "GET",
                beforeSend: this.beforeSendHandler,
            };

            $.ajax(ajaxData).done((data) => {
                this.raw = data;

                const json = data.track == null ? decode(data) : data;

                this.tracks[index]= json.track;                
                // self.points[index] = PointsConverter.convert_points(json.points,json.points_header, { wind_data: json.wind_data });
                this.points[index] = { points: json.points, points_header: json.points_header, other_data: { wind_data: json.wind_data }};
                this.loaded_ids.push(id);
                this.displayLoadingProgress();
                resolve();
            }).fail((err) => reject(err));
        });
    }   

    toUTF8Array(str) {
        let utf8 = [];
        for (let i = 0; i < str.length; i++) {
            let charcode = str.charCodeAt(i);
            if (charcode < 0x80) utf8.push(charcode);
            else if (charcode < 0x800) {
                utf8.push(0xc0 | (charcode >> 6),
                    0x80 | (charcode & 0x3f));
            }
            else if (charcode < 0xd800 || charcode >= 0xe000) {
                utf8.push(0xe0 | (charcode >> 12),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
            // surrogate pair
            else {
                i++;
                // UTF-16 encodes 0x10000-0x10FFFF by
                // subtracting 0x10000 and splitting the
                // 20 bits of 0x0-0xFFFFF into two halves
                charcode = 0x10000 + (((charcode & 0x3ff) << 10)
                    | (str.charCodeAt(i) & 0x3ff));
                utf8.push(0xf0 | (charcode >> 18),
                    0x80 | ((charcode >> 12) & 0x3f),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
        }
        return utf8;
    }
}