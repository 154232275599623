import ToastNotification from "../utils/ToastNotification";

export default class DeviseUser {
  constructor(isReset) {
    $.validator.addMethod(
      "alphanumeric_no_white",
      function (value, element) {
        return (
          this.optional(element) || /^([\p{L}0-9_\-]{4,127}$)/u.test(value)
        );
      },
      I18n.t("admins.registrations.new.username_invalid")
    );
    $.validator.addMethod(
      "uniqueUserName",
      function (value, element) {
        let response = $.ajax({
          type: "GET",
          url: window.application.getAPIUrl() + "/v1/users/check_user_name",
          data: { username: value },
          async: false,
          beforeSend: function (request) {
            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
          },
        });

        return response.responseJSON.exists !== true;
      },
      I18n.t("admins.registrations.new.username_taken")
    );
    $.validator.addMethod(
      "validPhoneNumber",
      function (value, element) {
        let phoneInput = window.intlTelInputGlobals.getInstance(element);
        if (!phoneInput) {
          console.error("intlTelInput not initialized on element", element);
          return false;
        }
        let phoneNumber = phoneInput.getNumber(); // Get E.164 format number

        const hideCodeValidationButton = () => {
          $(element).data("isValidPhoneNumber", false);
          $("#send_code_section").hide();
          $("#verification_code_section").hide();
        }

        const showCodeValidationButton = () => {
          $(element).data("isValidPhoneNumber", true);
          $("#send_code_section").show();
          $("#send_code_section").addClass("blink_helper");
        }

        if (!phoneInput.isValidNumber()) {
          hideCodeValidationButton();
          return false;
        }

        let response = $.ajax({
          type: "POST",
          url:
            window.application.getAPIUrl() +
            "/v1/verifications/phone_number_validation",
          data: { phone: phoneNumber },
          async: false,
          beforeSend: function (request) {
            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
          },
        });
        let isValid = response.responseJSON.success;
        if (isValid) {
          showCodeValidationButton();
        } else {
          hideCodeValidationButton();
        }
        return isValid;
      },
      I18n.t("admins.registrations.new.phone_number_invalid")
    );
    $.validator.addMethod(
      "uniqueEmail",
      function (value, element, params) {
        let response = $.ajax({
          type: "GET",
          url: window.application.getAPIUrl() + "/v1/users/check_email",
          data: {
            email: value,
          },
          async: false,
          beforeSend: function (request) {
            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
          },
        });

        return response.responseJSON.exists !== true;
      },
      function (params, element) {
        return I18n.t("admins.registrations.new.email_taken", {
          email: $(element).val(),
        });
      }
    );
    if (isReset) {
      this.validation_options = {
        rules: {
          "user[email]": {
            required: true,
          },
        },
      };
    } else {
      this.validation_options = {
        rules: {
          "user[email]": {
            required: true,
            uniqueEmail: true,
          },
          "user[user_name]": {
            required: true,
            minlength: 6,
            alphanumeric_no_white: true,
            uniqueUserName: true,
          },
          "user[password]": {
            minlength: 8,
            required: true,
          },
          "user[password_confirmation]": {
            required: true,
            equalTo: "#user_password",
          },
          "user[phone_number]": {
            required: true,
            minlength: 5,
            validPhoneNumber: true,
          },
          verification_code: {
            minlength: 4,
            required: true,
          },
        },
        errorPlacement: function (error, element) {
          if (element.attr("name") === "user[phone_number]") {
            error.insertAfter(element.closest(".phone_validation"));
          } else {
            error.insertAfter(element);
          }
        },
      };
    }

    if (I18n.locale !== "en") {
      let localeDepPath = "jquery-validation/dist/localization/messages_" + I18n.locale
      this.validation_options.messages = require(localeDepPath)
    }
    this.validation = $("form").validate(this.validation_options);
    this.bindEvents();
  }

  bindEvents() {
    const phoneInputFields = document.querySelectorAll(".phone");
    const new_phone = phoneInputFields[phoneInputFields.length - 1];
    window.intlTelInput(new_phone, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
      initialCountry: "auto",
      geoIpLookup: function (callback) {
        $.get("https://ipinfo.io", function () { }, "jsonp").always(function (
          resp
        ) {
          var countryCode = resp && resp.country ? resp.country : "us";
          callback(countryCode);
        });
      },
      preferredCountries: ["fr", "us", "gb", "es", "de", "ja"],
    });

    $("#send_verification_code_button").on("click", function () {
      let phoneInput = window.intlTelInputGlobals.getInstance(
        $("#user_phone_number")[0]
      );
      let phoneNumber = phoneInput.getNumber();
      let originValue = $("input[name='origin']").val();

      $.ajax({
        type: "POST",
        url:
          window.application.getAPIUrl() +
          "/v1/verifications/send_verification_code",
        data: {
          phone: phoneNumber,
          origin: originValue
        },
        beforeSend: function (request) {
          request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
        },
        success: function (response) {
          $("#send_code_section").remove();
          $("#send_verification_code_button").prop("disabled", true);
          $("#user_phone_number").prop("readonly", true);
          $("#verification_code_section").show();
          $("#confirm_verification_code_section").show();
          $("#verification_code_section").addClass("blink_helper");
          // Send notification to user
          ToastNotification.make(
            I18n.t(
              "admins.registrations.new.code_confirmation_sent_to_your_phone",
              {
                phone: phoneNumber,
              }
            )
          );
        },
        error: function (xhr, status, error) { },
      });
    });

    $("form").on("submit", async function (e) {
      e.preventDefault();

      if ($(this).valid()) {
        const response = await window.executeRecaptchaForRegistrationsAsync();
        console.log(`Recaptcha's response was: ${response}`);

        const hiddenInput = document.getElementById('g-recaptcha-response-data-registrations');
        hiddenInput.value = response;

        this.submit();
      } else {
        ToastNotification.make(I18n.t("admins.registrations.new.error_form"));
      }
    });

    $("#confirm_verification_code").on("click", () => {
      let phoneInput = window.intlTelInputGlobals.getInstance(
        $("#user_phone_number")[0]
      );
      let originValue = $("input[name='origin']").val();
      let phoneNumber = phoneInput.getNumber();
      let codeValid = $("#verification_code_field").valid();
      if (codeValid) {
        let codeSet = $("#verification_code_field").val();
        // Verify code
        $.ajax({
          type: "POST",
          url:
            window.application.getAPIUrl() +
            "/v1/verifications/confirm_verification_code",
          data: {
            code: codeSet,
            phone: phoneNumber,
            origin: originValue
          },
          beforeSend: function (request) {
            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
          },
          success: function (response) {
            if (response.success) {
              $("#verification_code_section").hide();
              $("#confirm_verification_code_section").hide();
              $("#phone_number_is_valid").show();
              // Update number with E.164 format number
              $("#user_phone_number").val(phoneNumber);
              // Validation code is valid, we can show submit button
              $("#submit_button").prop("disabled", false);
            } else {
              $("#verification_code_field").addClass("bg-danger");
              $("#verification_code_section_error_text")
                .text(
                  I18n.t(
                    "admins.registrations.new.code_confirmation_incorrect",
                    {
                      code: codeSet,
                    }
                  )
                )
                .show();
            }
          },
        });
      }
    });
  }

  onDataChanged(data) { }

  onDestroy() {
    this.validation.destroy();
  }
}
