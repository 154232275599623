import ListUsers from "../utils/ListUsers";

export default class ListFollowing {

    constructor() {
        this.page = 1;
        this.user_id = null;
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
        this.user_id = data.user_id;
        this.loadFollowing();
        this.bindEvents();
    }

    onDestroy() {
        $("#following").empty();
        $(window).unbind('scroll', this.onScroll);
    }

    onScroll(){
        if ($(window).scrollTop() >= $('#following').offset().top + $('#following').outerHeight() - 300 - window.innerHeight) {
            window.application.current_handler.listFollowing.loadFollowing();
        }
    }

    loadFollowing() {
        let self = this;
        $(window).unbind('scroll', self.onScroll);

        $.ajax({
            url: "/user/ajax_following",
            type: "get",
            data: {
                user_id: this.user_id,
                page: this.page
            },
            success: function(data) {
                let before = $(".following-item").length;
                ListUsers.buildList('following',data.users);
                let after = $(".following-item").length;
                window.application.current_handler.onUpdate();

                if ((before !== after) && ($(".following-item").length%2 === 0)){
                    self.page++;
                    $(window).on('scroll', self.onScroll);
                }else{
                    $(window).unbind('scroll', self.onScroll);
                }
            },
            error: function(data) {

            }
        });
    }
}