export default class WorldTable {

  constructor(liveWorldmap) {
    this.live_worldmap = liveWorldmap;

    this.players = this.live_worldmap.players;

    $("#world_track_list").height(600);

    this.item_template = $("#track_item_template").clone();
    $("#track_item_template").remove();
  }

  refreshDisplay() {
    var self = this;
    Object.keys(this.players).forEach(function (key) { 
      var player = self.players[key].player;
      var node = player.item;
      if(node!=null) {
        node.find(".track-item-user-name").html(player.user_name);
        node.find(".track-item-category").html(self.live_worldmap.categoryHash[player.category_id]!=null ? I18n.t("track_categories."+self.live_worldmap.categoryHash[player.category_id].name)  : "");
        node.find(".track-item-started-at").html(moment(player.started_at).format("H:m"));
        node.find(".track-item-duration").html(moment(player.started_at).fromNow(true));
        node.find(".track-item-distance").html((player.total_distance/1000.0).toFixed(2)+"km");
      }
    });
  }

  onLiveDataChanged(data) {
    var self = this;
    for(var i = 0; i<data.users.length; i++) {
      var player = data.users[i];
      var node = null;
      if(this.players[player.user_name]==null) {
        this.players[player.user_name] = { player: player };
      }

      if(this.players[player.user_name].item==null){
        node = this.item_template.clone();
        
        this.players[player.user_name].item = node;
        this.players[player.user_name].following = false;
        $("#world_track_list").append(node);


        node.find(".track-item-show-live").unbind("click").click(function() {
          Turbolinks.visit("/live/"+player.track_id);
        });

        node.find(".track-item-follow-live").data("user_name", player.user_name).unbind("click").click(function() {
          var player = self.players[$(this).data("user_name")].player;
          if(!self.players[player.user_name].following) {
            self.players[player.user_name].following = true;
            self.live_worldmap.follow(self.players[player.user_name]);
            $(this).removeClass("btn-secondary").addClass("btn-warning").html("Arrêter le suivi");
          }
          else {
           self.live_worldmap.unfollow(self.players[player.user_name]); 
           self.players[player.user_name].following = false;
           $(this).addClass("btn-secondary").removeClass("btn-warning").html("Suivre <i class=\"mdi mdi-record-rec\">");
          }
        });

      }
      else {
        node = this.players[player.user_name].item;
      }

      node.attr("id", "track-item-"+i);
      node.find(".track-item-user-name").html(player.user_name);
      node.find(".track-item-category").html(self.live_worldmap.categoryHash[player.category_id]!=null ? I18n.t("track_categories."+self.live_worldmap.categoryHash[player.category_id].name)  : "");
      node.find(".track-item-started-at").html(moment(player.started_at).format("H:m"));
      node.find(".track-item-duration").html(moment(player.started_at).fromNow(true));
      node.find(".track-item-distance").html((player.total_distance/1000.0).toFixed(2)+"km");
      
    }

  }
}