import AllLocalityTracks from "../views/AllLocalityTracks";
import LocalityBestScores from "../views/LocalityBestScores";
import ChangeActivity from "../utils/ChangeActivity";
import SubscribeLocation from "../utils/SubscribeLocation";
import SpotsMap from "../views/SpotsMap";
import LocalityBestTracksStandings from "../views/LocalityBestTracksStandings";

export default class LocalitiesIndex {

    constructor() {
        this.allTracks = new AllLocalityTracks();
        this.localityBestsScores = new LocalityBestScores();
        this.changeActivity = new ChangeActivity();
        this.subscribeLocation = new SubscribeLocation();
        this.spotsMap = new SpotsMap();
        this.localityBestTracksStandings = new LocalityBestTracksStandings(this.localityBestsScores);
        window.application.setOnDataChangeListener(this);

        this.bindEvents();
    }


    bindEvents() {
        $("a[href*='#']:not([href='#'])").unbind("click").click(function(e) {
            e.preventDefault();
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if ( anchor.length ) {
                    $("html, body").animate( { scrollTop: anchor.offset().top-70 }, 500);
                }
            }
        });
    }

    onDataChanged(data) {
        this.allTracks.onDataChanged(data);
        this.localityBestsScores.onDataChanged(data);
        this.changeActivity.onDataChanged(data);
        this.subscribeLocation.onDataChanged(data);
        this.spotsMap.onDataChanged(data);
        this.localityBestTracksStandings.onDataChanged(data);
    }

    onDestroy(){
        $("a[href*='#']:not([href='#'])").unbind("click");
        this.allTracks.onDestroy();
        this.localityBestsScores.onDestroy();
        this.changeActivity.onDestroy();
        this.subscribeLocation.onDestroy();
        this.spotsMap.onDestroy();
        this.localityBestTracksStandings.onDestroy();
    }
}
