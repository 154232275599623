import AskPremiumModal from "../utils/AskPremiumModal";
import MeasureConversion from "../utils/MeasureConversion";

export default class HomeLiveEvents {
  constructor() {
    this.sw_lat = 0;
    this.sw_lng = 0;
    this.ne_lat = 0;
    this.ne_lng = 0;
    this.category_id = 0;
    this.tracks_from = cookies.get("live_from")
      ? parseInt(cookies.get("live_from"))
      : 0;
    this.first_appear_ad = 100;
    this.frequency_ad = 100;
    this.fatherClass = null;
    this.selected_events = [];
    this.current_events = [];
    this.interval = null;
    this.measureConversion = null;

    this.bindEvents();
    // this.start_timer();
    let self = this;
  }

  bindEvents() {}

  onDataChanged(data) {
    this.measureConversion = new MeasureConversion(data.user_unit_system);
    this.first_appear_ad = data.first_appear_ad;
    this.category_id = data.category_id;
    this.frequency_ad = data.frequency_ad;
  }

  onDestroy() {
    $("#list_live_items").empty();
    $("#list_selected_live_items").empty();
    this.current_events = [];
    this.selected_events = [];

    window.clearInterval(this.interval);
    AskPremiumModal.destroy_all();
  }

  loadEventsOnBounds(bounds) {
    let self = this;

    this.sw_lat = bounds._southWest.lat;
    this.sw_lng = bounds._southWest.lng;
    this.ne_lat = bounds._northEast.lat;
    this.ne_lng = bounds._northEast.lng;

    let ajaxData = {
      url: window.application.getAPIUrl() + "/v1/home/bounded_events",
      method: "GET",
      data: {
        category_id: this.category_id,
        tracks_from: this.tracks_from,
        except: this.selected_events,
        sw_lat: self.sw_lat,
        sw_lng: self.sw_lng,
        ne_lat: self.ne_lat,
        ne_lng: self.ne_lng,
      },
    };

    $.ajax(ajaxData)
      .done(function (datas) {
        $("#home-live-results-number").text(
          datas.result_number + self.selected_events.length
        );
        if (datas !== undefined) {
          self.updateEventsListing(datas.events);
          self.fatherClass.live_btn_listener();
        }
      })
      .fail(function (err) {});
  }

  loadLiveEventsOnBounds() {
    let self = this;

    let ajaxData = {
      url: window.application.getAPIUrl() + "/v1/home/bounded_live_events",
      method: "GET",
      data: {
        category_id: this.category_id,
        tracks_from: this.tracks_from,
        except: this.selected_events,
        sw_lat: self.sw_lat,
        sw_lng: self.sw_lng,
        ne_lat: self.ne_lat,
        ne_lng: self.ne_lng,
      },
    };

    $.ajax(ajaxData)
      .done(function (datas) {
        self.update_unselected_lives(datas.events);
      })
      .fail(function (err) {});
  }

  updateEventsListing(events) {
    let self = this;
    let event_ids = events.map((event_item) => {
      return event_item.event_id;
    });
    let old_events = self.current_events.filter((x) => !event_ids.includes(x));
    old_events.forEach(function (item) {
      $("#list_live_items #live_track_" + item).remove();
      self.current_events.splice(self.current_events.indexOf(item), 1);
    });

    if ($("#list_live_items .live-item").length === 0) {
      events.forEach(function (item) {
        if (
          !self.selected_events.includes(item.event_id) &&
          !self.current_events.includes(item.event_id)
        ) {
          self.current_events.push(item.event_id);
          $("#list_live_items").append(self.buildLiveItem(item));
        }
      });
    } else {
      events.forEach(function (item) {
        if (
          !self.selected_events.includes(item.event_id) &&
          !self.current_events.includes(item.event_id)
        ) {
          let cant_be_placed = true;
          $("#list_live_items .live-item").each(function () {
            if ($(this).data("started-at") < item.started_at_i) {
              self.current_events.push(item.event_id);
              $(self.buildLiveItem(item)).insertBefore($(this));
              cant_be_placed = false;
              return false;
            }
          });

          if (cant_be_placed) {
            self.current_events.push(item.event_id);
            $("#list_live_items").append(self.buildLiveItem(item));
          }
        }
      });
    }
  }

  buildLiveItem(item) {
    let liveItem =
      '<div id="live_track_' +
      item.event_id +
      '" data-started-at="' +
      item.started_at_i +
      '" class="col-12 mb-1 center_live px-35 live-item ' +
      (item.live_event ? "live" : "") +
      '" data-uid="' +
      item.user_id +
      '" data-tid="' +
      item.event_id +
      '" data-live="' +
      item.live_event +
      '">\n' +
      '                    <div class="row bg-white rounded live-border cursor-pointer py-1 pl-1">\n' +
      '                      <div class="col-4 px-1">\n' +
      '                        <div class="home-live-user-col">\n' +
      '                          <div class="media">\n' +
      '                            <img height="25" class="rounded-circle home-live-profile-img" style="margin-bottom: 4px;" src="' +
      item.user_profile_image +
      '">\n' +
      '                            <span class="dot-sm-25 bg-' +
      (item.class_name === "TrackerActivity" ? "warning" : "secondary") +
      ' home-live-activity"><i class="fs-16 lh-5' +
      (item.class_name === "TrackerActivity" ? "" : " text-white") +
      ' icon icon-' +
      (item.class_name === "TrackerActivity" ? "car" : item.category_icon) +
      '"></i> </span>\n' +
      '                            <div class="media-body lh-3 pt-1">\n' +
      '                              <span class="fs-13 fw-600">' +
      item.user_name +
      "</span>" +
      "                            </div>\n" +
      "                          </div>" +
      "                          \n" +
      "                        </div>\n" +
      '                        <div class="fs-12 mt-0">\n' +
      '                          <span class="flag-icon-' +
      item.locality_country_code +
      ' flag-icon rounded"></span>\n' +
      '                          <span class="text-light-gray">' +
      item.locality_name +
      ", " +
      item.started_day_at +
      "</span>\n" +
      "                        </div>\n" +
      "                      </div>\n" +
      '                      <div class="col-2 py-1 px-0 bg-transparent-dark rounded d-flex align-items-center">\n' +
      '                       <div class="mx-auto">' +
      '                        <div class="fs-14 mx-auto live-danger ' +
      item.event_id +
      '-duration">' +
      item.duration +
      "</div>\n" +
      '                        <div class="fs-12 text-center text-light-gray">' +
      item.started_at +
      "</div>\n" +
      "                       </div>" +
      "                      </div>\n" +
      '                      <div class="col-2 py-1 px-0 rounded d-flex align-items-center">\n' +
      '                       <div class="mx-auto">' +      
      '                        <div class="fs-14 mx-auto live-danger"><span class="' +
      item.event_id +
      '-distance">' +
      (item.class_name === "TrackerActivity" ? "" : I18n.toNumber(item.total_distance, { precision: 1 })) +
      ' </span> <span class="fs-12 text-light-gray">' +
      (item.class_name === "TrackerActivity" ? "" : this.measureConversion.getDistanceUnit()) +
      "</span></div>\n";
    if (item.live_event) {
        liveItem +=
      '                        <div class="fs-12 text-center text-light-gray"><span class="' +
      item.event_id +
      '-speed">' +
      item.track_current_speed +
      '</span> <span class="fs-12 text-light-gray">' +
      (item.class_name === "TrackerActivity" ? "" : this.measureConversion.getSpeedUnit()) +
      "</span></div>\n";
    }
    liveItem +=
      "                       </div>" +
      "                      </div>\n" +
      '                      <div class="col-2 py-1 px-0 bg-transparent-dark rounded d-flex align-items-center">\n';
    if (!item.live_event) {
      liveItem +=
        '                       <div class="fs-12 mx-auto d-no-live">-' +
        item.track_finished_since +
        "</div>";
    } else {
      if (item.class_name === "Track") {
        liveItem +=
          '                       <div class="fs-12 mx-auto d-live">' +
          '                           <span id="stable-live-' +
          item.event_id +
          '" class="badge-pill bg-danger border border-danger fs-12 text-white px-1 py-0 d-no-ended ' +
          (item.last_update_dif > 35 ? "d-none" : "") +
          '"><i class="icon-Lives fs-11"></i> Live</span>' +
          '                           <span id="unstable-live-' +
          item.event_id +
          '" class="badge-pill border border-danger fs-12 text-danger px-2 py-0 d-no-ended ' +
          (item.last_update_dif > 35 ? "" : "d-none") +
          '">! Live</span>' +
          '                           <div class="live-timer-' +
          item.event_id +
          ' mt-1 fs-12 text-center d-no-ended" data-track-id="' +
          item.event_id +
          '" data-last-point-timer="' +
          item.last_update_dif +
          '">0:00</div>';
      } else if (item.class_name === "TrackerActivity") {
        liveItem +=
          '                       <div class="fs-12 mx-auto d-live">' +
          '                           <span id="stable-live-' +
          item.event_id +
          '" class="badge-pill bg-warning border border-warning fs-12 px-1 py-0 d-no-ended ' +
          (item.last_update_dif > 300 ? "d-none" : "") +
          '"><i class="icon-Lives fs-11"></i> Live</span>' +
          '                           <span id="unstable-live-' +
          item.event_id +
          '" class="badge-pill border border-warning fs-12 text-danger px-2 py-0 d-no-ended ' +
          (item.last_update_dif > 300 ? "" : "d-none") +
          '">! Live</span>' +
          '                           <div class="live-timer-' +
          item.event_id +
          ' mt-1 fs-12 text-center d-no-ended" data-track-id="' +
          item.event_id +
          '" data-last-point-timer="' +
          item.last_update_dif +
          '">0:00</div>';
      }
      liveItem +=
        '<div class="d-ended"><div class="text-center fs-24 text-primary" data-toggle="tooltip" data-placement="right" title="' +
        I18n.t("website.home.index.live_map.live_ended") +
        '"><span class="mdi mdi-flag-variant btn-outline-secondary"></span></div></div>' +
        "                       </div>";
    }

    liveItem +=
      "                       </div>\n" +
      '                      <div class="col-2 ' +
      (item.live_event ? "toggle_live" : "") +
      ' py-1 px-0 rounded d-flex align-items-center" data-uid="' +
      item.user_id +
      '" data-tid="' +
      item.event_id +
      '" data-live="' +
      item.live_event +
      '">\n' +
      '                       <div class="mx-auto fs-24 text-secondary d-no-live"><i class="mdi mdi-target"></i></div>' +
      '                        <div class="mx-auto d-live">' +
      '                           <label class="mb-0">' +
      '                           <div class="material-switch pull-right d-inline ml-2">\n' +
      '                               <input id="' +
      item.event_id +
      '-switch" class="" type="checkbox">\n' +
      '                               <label class="bg-' + (item.class_name === "Track" ? "danger" : "warning" ) + ' mb-1"></label>\n' +
      "                           </div>" +
      "                           </label>" +
      "                       </div>" +
      "                      </div>\n" +
      "                    </div>\n" +
      "                  </div>";

    return liveItem;
  }

  updateCategory(category_id) {
    $("#list_live_items").empty();
    //$("#list_selected_live_items").empty();
    let selected_ids = this.selected_events.map((selected_item) => {
      return selected_item.event_id;
    });
    this.current_events = this.current_events.filter((x) =>
      selected_ids.includes(x)
    );
    //this.selected_events = [];
    this.category_id = category_id;
    this.loadEventsOnBounds(this.fatherClass.homeLiveMap.map.getBounds());
  }

  updateEventsFrom(from) {
    $("#list_live_items").empty();
    //$("#list_selected_live_items").empty();
    let selected_ids = this.selected_events.map((selected_item) => {
      return selected_item.event_id;
    });
    this.current_events = this.current_events.filter((x) =>
      selected_ids.includes(x)
    );
    //this.selected_events = [];
    this.tracks_from = from;
    cookies.create("live_from", from);
    this.loadEventsOnBounds(this.fatherClass.homeLiveMap.map.getBounds());
  }

  setFatherClass(father) {
    this.fatherClass = father;
  }

  disable_track_live(tid, selected_events) {
    let self = this;
    let element_id = "#live_track_" + tid;
    $("#" + tid + "-switch").prop("checked", false);
    $("." + tid + "-switch-popup").prop("checked", false);
    $(element_id).animate(
      {
        right: $(element_id).width() + "px",
      },
      {
        done: function () {
          if (!$(element_id).hasClass("ended")) {
            $(this).detach().prependTo("#list_live_items");
            $(this).animate(
              {
                right: "0px",
              },
              {
                done: function () {
                  self.current_events.push(tid);
                },
              }
            );
          } else {
            $(element_id).remove();
          }
          self.selected_events = selected_events;
        },
      }
    );
  }

  enable_track_live(tid, selected_events) {
    let self = this;
    this.selected_events = selected_events;
    let element_id = "#live_track_" + tid;

    if ($(element_id).length) {
      $("#" + tid + "-switch").prop("checked", true);
      $(element_id).animate(
        {
          right: $(element_id).width() + "px",
        },
        function () {
          $(this).detach().appendTo("#list_selected_live_items");
          $(this).animate({
            right: "0px",
          });
        }
      );
    } else {
      let ajaxData = {
        url: window.application.getAPIUrl() + "/v1/home/get_track",
        method: "GET",
        data: {
          activity_id: tid,
        },
      };

      $.ajax(ajaxData)
        .done(function (item) {
          $(self.buildLiveItem(item)).appendTo($("#list_selected_live_items"));
        })
        .fail(function (err) {});
    }
  }

  update_live_events(data) {
    if (data.e != "tracker-update" && data.dist > 0) {
      $("." + data.tid + "-distance").text(
        I18n.toNumber(
          Math.round(
            this.measureConversion.convertDistance(data.dist / 1000) * 10
          ) / 10,
          { 
            precision: 1 
          }
        )
      );
      $("." + data.tid + "-speed").text(
        I18n.toNumber(
          (
            Math.round(this.measureConversion.convertSpeed(data.speed * 3.6) * 10) / 10
          ).toFixed(1), 
          {
            precision: 1,
          }
        )
      );
    }
    $("." + data.tid + "-duration").text(data.duration);
    $(".live-timer-" + data.tid).data("last-point-timer", 0);
    $(".live-timer-" + data.tid).text("0:00");
  }

  live_ended(tid) {
    //$("#live_track_"+tid).removeClass("live")
    $("#live_track_" + tid).addClass("ended");
  }

  start_timer() {
    let self = this;
    let time = 0;
    let readable_time_in_minutes = 0;
    this.interval = window.setInterval(function () {
      $("div[class^=live-timer]").each(function () {
        let sec = $(this).data("last-point-timer") + 1;
        $(this).data("last-point-timer", sec);

        if (sec > 60) {
          $("#stable-live-" + $(this).data("track-id")).addClass("d-none");
          $("#unstable-live-" + $(this).data("track-id")).removeClass("d-none");
          $(this).addClass("text-danger");
          $(this).text("(-" + self.toM(sec) + "min)");
        } else {
          $("#stable-live-" + $(this).data("track-id")).removeClass("d-none");
          $("#unstable-live-" + $(this).data("track-id")).addClass("d-none");
          $(this).removeClass("text-danger");
          $(this).text(self.toMS(sec));
        }
      });

      time++;

      if (
        !window.application.isPremium() &&
        (time === self.first_appear_ad ||
          (time > self.first_appear_ad &&
            (time - self.first_appear_ad) % self.frequency_ad === 0))
      ) {
        self.disableMap();
        readable_time_in_minutes = (
          Math.round((time / 60) * Math.pow(10, 1) + Number.EPSILON) /
          Math.pow(10, 1)
        )
          .toString()
          .replace(".", ",");
        AskPremiumModal.destroy_all();
        AskPremiumModal.make(
          I18n.t("website.premium.home_live_map", {
            timer: readable_time_in_minutes,
          }),
          "#home-live-map"
        );
        AskPremiumModal.onDiscard(self.enableMap, "#home-live-map", self);
      }
    }, 1000);
  }

  disableMap() {
    let map = this.fatherClass.homeLiveMap.map;
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
    document.getElementById("home-live-map").style.cursor = "default";
  }

  enableMap(e, ctx) {
    let map = ctx.fatherClass.homeLiveMap.map;
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();
    document.getElementById("home-live-map").style.cursor = "grab";
  }

  toMS(sec) {
    let seconds = sec % 60;
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return this.toM(sec) + ":" + seconds;
  }

  toM(sec) {
    return parseInt(sec / 60);
  }

  update_unselected_lives(events) {
    events.forEach(function (item) {
      $("." + item.event_id + "-duration").text(item.track_duration);
      if (item.class_name === "Track" && item.track_distance > 0) {
        $("." + item.event_id + "-distance").text(
          I18n.toNumber(item.track_distance, { precision: 1 })
        );
      }
      $(".live-timer-" + item.event_id).data(
        "last-point-timer",
        item.track_last_point_dif
      );
    });
  }
}
