import CustomCalendar from "../views/CustomCalendar.js";
import ListTrack from "../views/ListTrack.js";
import SocialActions from "../utils/SocialActions";

export default class UserShow {

    constructor() {
        this.calendar = new CustomCalendar();
        this.listTrack = new ListTrack();
        this.socialActions = new SocialActions();    
        this.listTrack.setFatherClass(this);
        window.application.setOnDataChangeListener(this);
    }

    onDataChanged(data) {
        this.listTrack.onDataChanged(data);
        this.calendar.onDataChanged(data);
    }

    onUpdate(){
        this.socialActions.onUpdate();
    }

    onDestroy(){
        this.calendar.onDestroy();
        this.listTrack.onDestroy();
        this.socialActions.onDestroy();
    }
}