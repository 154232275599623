import UploadFileTemplates from './templates/UploadFileTemplates';
import ToastNotification from "../utils/ToastNotification";

export default class UploadFilesController {

    init(
        endpoints = { 
            uploadAPI,
            deleteAPI
        },
        mainActionSelector,
        selectorPrefix,
        filters = {
            mimeFileTypes, 
            fileExt, 
            size
        }
    ) {
        this.maxFilesAmount = 4;
        this.selectors = {
            dropAreaSelector: `#${selectorPrefix}_drop_hexagon`,
            fileInputSelector: `#${selectorPrefix}_file_data`,
            fileCounterContainerSelector: `#${selectorPrefix}_counter_element`,
            fileCounterSelector: `#${selectorPrefix}_counter`,
            fileMaxLimitSelector: `#${selectorPrefix}_count_limit`,
            fileListSelector: `#${selectorPrefix}_file_list`,
            deleteFileTemplate: `#${selectorPrefix}_file_delete_`,
            progressUploadTemplate: `#${selectorPrefix}_progress_`,
            indeterminateProgressUploadTemplate: `#${selectorPrefix}_indeterminate_progress_`,
        };
        this.endpoints = endpoints;
        this.mainActionSelector = mainActionSelector;
        this.filters = filters;
        this.dropArea = $(this.selectors.dropAreaSelector)[0];

        this.available_mime_file_types = this.filters.mimeFileTypes;
        this.available_file_ext = this.filters.fileExt;

        this.uploadingFiles = [];

        this.counter_draggover = 0;
        this.file_count = 0;

        if (this.dropArea != null) {
            this.bindEvents();
        }

        $(this.selectors.fileMaxLimitSelector).text(this.maxFilesAmount);
    }
    
    bindEvents() {
        let self = this;

        $(this.dropArea).bind({
            dragenter: function(ev) {
                ev.preventDefault(); // needed for IE
                self.counter_draggover++;
                $(this).addClass('drag_in');
            },

            dragleave: function() {
                self.counter_draggover--;
                if (self.counter_draggover === 0) {
                    $(this).removeClass('drag_in');
                }
            }
        });

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            self.dropArea.addEventListener(eventName, self.preventDefaults, false)
        });

        this.dropArea.addEventListener('drop', function (event) {
            let el = this;
            $(this).removeClass("drag_in");
            $(this).addClass('dropped');
            setTimeout(function() {
                $(el).removeClass('dropped');
            }, 1500);
            self.handleDrop(event);
        }, false);

        $(this.selectors.fileInputSelector).val("");

        $(this.selectors.fileInputSelector).unbind("change").on('change', function () {
            self.handleFiles(this.files);
        });
    }

    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e) {
        let dt = e.dataTransfer;
        let files = dt.files;
        this.handleFiles(files);
    }

    async handleFiles(files) {
        let self = this;
        $(this.mainActionSelector).prop("disabled", true);
        $(this.mainActionSelector).removeClass("btn-secondary");
        $(this.mainActionSelector).addClass("btn-outline-secondary");

        try {
            let response = await $.ajax({
                url: window.application.getAPIUrl() + "/v1/config/get_max_upload_size/",
                method: "get"
            });

            let picture_limit_upload_size = response.picture_limit_upload_size;

            [...files].forEach((file) => {
                if (self.uploadingFiles.length < self.maxFilesAmount
                    && file.size < picture_limit_upload_size
                    && (self.available_mime_file_types.includes(file.type) || self.available_file_ext.includes(file.name.split('.')[file.name.split('.').length - 1]))
                ) {
                    let code_name = self.makeId(5);
                    self.previewFile(file, code_name);
                    self.uploadFile(file, code_name);
                    self.file_count++;
                    self.updateCounterFileUIElements();
                } else if (self.uploadingFiles.length < self.maxFilesAmount &&
                    file.size > picture_limit_upload_size) {
                    ToastNotification.make(I18n.t("website.layout.modals.add_activity.file_too_big", { 
                        max_file_size: response.picture_limit_upload_to_human_size
                    }));
                }
            });
            $(this.selectors.fileInputSelector).val("");
        } catch (error) {
            console.error("Error fetching max upload size:", error);
        }
    }

    makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    updateCounterFileUIElements() {
        if (this.file_count > 0) {
            $(this.selectors.fileCounterContainerSelector).removeClass("d-none");
            $(this.selectors.fileCounterSelector).text(this.file_count);
            if (this.file_count >= this.maxFilesAmount) {
                $(this.selectors.fileCounterContainerSelector).addClass("text-danger");
            } else {
                $(this.selectors.fileCounterContainerSelector).removeClass("text-danger");
            }
        } else {
            $(this.selectors.fileCounterContainerSelector).addClass("d-none");
        }
    }

    previewFile(file, code_name) {
        const isValid = true;
        let self = this;
        $(this.selectors.fileListSelector).append(UploadFileTemplates.new_track_file(file.name, code_name, this.selectors));
        $(this.selectors.deleteFileTemplate + code_name).on("click", function () {
            self.destroy_track(this, code_name, isValid);
        })
    }

    destroy_track(elm) {
        let self = this;
        let fileId = $(elm).data("file-id");
        let elementId = $(elm).data("element-id");

        let fileInProgress = this.uploadingFiles.find(upF => upF.code_name === elementId)

        if (fileInProgress) {
            fileInProgress.ajax_call.abort();

            $("#" + elementId).remove();
            self.file_count--;
            self.updateCounterFileUIElements();

            this.uploadingFiles = this.uploadingFiles.filter(upF => upF.code_name !== elementId);
        } else {
            const data = { file_id: fileId };
            this.endpoints.deleteAPI.initData && this.endpoints.deleteAPI.initData(data);

            $.ajax({
                url: this.endpoints.deleteAPI.url,
                type: "delete",
                data: data,
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                success: function (data) {
                    $("#" + elementId).remove();

                    self.file_count--;
                    self.updateCounterFileUIElements();
                },
                error: function (data) {
                    $("#" + elementId).addClass("delete_error");
                }
            })

        }
    }

    stopAllUploading() {
        this.uploadingFiles && this.uploadingFiles.forEach(file => {
            file.ajax_call.abort();
        })
    }

    uploadFile(file, code_name) {
        let self = this;
        let url = this.endpoints.uploadAPI.url;
        let formData = new FormData();
        let uploadTime = new Date();
        formData.append('file', file);
        formData.append('code_name', code_name);
        formData.append('uploadDate',uploadTime.toISOString())
        this.endpoints.uploadAPI.initData && this.endpoints.uploadAPI.initData(formData);

        this.uploadingFiles.push({
            code_name: code_name, ajax_call:
                $.ajax({
                    url: url,
                    type: "put",
                    data: formData,
                    cache: false,
                    processData: false,
                    contentType: false,
                    enctype: 'multipart/form-data',
                    xhr: function () {  // custom xhr
                        let myXhr = $.ajaxSettings.xhr();
                        if (myXhr.upload) { // check if upload property exists
                            myXhr.upload.addEventListener('progress', function (e) {
                                self.updateProgress(e, code_name);
                            }, false); // for handling the progress of the upload
                        }
                        return myXhr;
                    },
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                    success: function (data) {
                        if (data && data['file_id']) {
                            $("#" + code_name).addClass("can_delete");
                            $(self.selectors.deleteFileTemplate + code_name).data("file-id", data['file_id']);
                        } else {
                            $("#" + code_name).remove();
                        }
                        self.uploadingFiles = self.uploadingFiles.filter(upF => upF.code_name !== code_name);

                        if (self.uploadingFiles.length === 0) {
                            $(self.mainActionSelector).prop("disabled", false);
                            $(self.mainActionSelector).addClass("btn-secondary");
                            $(self.mainActionSelector).removeClass("btn-outline-secondary");
                        }
                    },
                    error: function (data) {

                    }
                })
        })
    }

    updateProgress(evt, code_name) {
        if (evt.lengthComputable) {
            let percentComplete = evt.loaded / evt.total;
            $(this.selectors.indeterminateProgressUploadTemplate + code_name).addClass("d-none");
            $(this.selectors.progressUploadTemplate + code_name).removeClass("d-none")
            $(this.selectors.progressUploadTemplate + code_name + " div").css("width", percentComplete * 100 + '%')
        } else {
            $(this.selectors.progressUploadTemplate + code_name).addClass("d-none")
            $(this.selectors.indeterminateProgressUploadTemplate + code_name).removeClass("d-none");
        }
    }
}