import I18n from "i18n-js";
import TemplateHelper from "../template-helper";

export default class LiveChatTemplates {
    static getContainer(activityId, activityType, isVisible) {
        const data = { activityId, activityType, isVisibleClass: (isVisible ? '' : 'd-none') };
        return TemplateHelper.getTemplate("chat-container", data);
    }

    static getMessengerHeader({
        userName,
        totalMessages
    }) {
        const data = { userName, totalMessages, totalMessages };
        return TemplateHelper.getTemplate("chat-messenger-header", data);
    }

    static getMessagesContainer() {
        return TemplateHelper.getTemplate("chat-messages-container");
    }

    static getAnswerField(id) {
        const data = { id };
        return TemplateHelper.getTemplate("chat-answer-field", data);
    }

    static getUserItem({ userUrl, name, icon }) {
        const data = { userUrl, name, icon };
        return TemplateHelper.getTemplate("chat-user-item", data);
    }

    static getMessageItem({ 
        id,
        text,
        user,
        likeCount,
        userWasLiked,
        updatedAt,
        updatedAtDate,
        activityTime,
        activityTimeToString,
        targetUserId,
        createdAt
    }) {
        const data = { 
            id,
            text,
            user_name: user.user_name,
            avatar_url: user.avatar_url,
            userWasLikedClass: (userWasLiked ? "liked-by-user" : ""),
            likeCount,
            userWasLiked,
            updatedAt,
            updatedAtDate,
            activityTime,
            activityTimeToString,
            targetUserId,
            createdAt
        };
        return TemplateHelper.getTemplate("chat-message-item", data);
    }

    static getAnswerItem({ 
        id,
        text,
        user,
        likeCount,
        userWasLiked,
        createdAt,
        updatedAt,
        updatedAtDate 
    }) {
        const data = {
            id,
            text,
            likeCount,
            userWasLiked,
            createdAt,
            updatedAt,
            updatedAtDate,
            user_name: user.user_name,
            avatar_url: user.avatar_url,
            userWasLikedClass: (userWasLiked ? "liked-by-user" : "")
        };
        return TemplateHelper.getTemplate("chat-answer-item", data);
    }

    static getMessageAnswersHeader({ answersLength }) {

        const data = { answersLength: answersLength + " " + I18n.t("website.track.comment.answers", { count: answersLength }) };
        return TemplateHelper.getTemplate("chat-message-answers-header", data);
    }

    static getMessageEditForm(defaultText) {
        return TemplateHelper.getTemplate("chat-message-edit-form", { defaultText });
    }

    static getEditDeleteBtns() {
        return TemplateHelper.getTemplate("chat-edit-delete-btns");
    }
}