import TemplateHelper from "../template-helper";

export default class TimelineCommentsTemplates {
    static getContainer() {
        return TemplateHelper.getTemplate("timeline-comments-container")
    }

    static getComment({ id, message, user, activity_time }) {
        const name = (!user.first_name && !user.last_name) ? 
            user.user_name : (user.first_name + " " + (user.last_name ?? ''));

        const data = {
            id,
            name,
            message,
            user,
            activity_time,
        }

        return TemplateHelper.getTemplate("timeline-comment-item", data);
    }
}