import { HomeLiveMapMarkerManager } from './HomeLiveMapMarkerManager';
import { PopupTemplates } from './templates/livemap-popups/popup-templates';

export class TrackMarkerManager extends HomeLiveMapMarkerManager {

  fetchData(bounds, category_id, tracks_from) {
    const ajaxData = {
      url: window.application.getAPIUrl() + '/v1/home/map_tracks',
      method: 'GET',
      data: {
        category_id: category_id,
        tracks_from: tracks_from,
        sw_lat: bounds.sw_lat,
        sw_lng: bounds.sw_lng,
        ne_lat: bounds.ne_lat,
        ne_lng: bounds.ne_lng,
      },
    };
    $.ajax(ajaxData)
      .done(({ tracks }) => {
        if (tracks) {
          this.clearMarkers(tracks);
          this.generateMarkers(tracks);
        }
      })
      .fail((reason) => console.error(reason));
  }

  generateMarkers(tracks) {
    const current_ids = this.markers.map(({ id }) => id);
    const new_tracks = tracks.filter((x) => !current_ids.includes(x.track_id));

    new_tracks.forEach((item) => {
      const isLiveTrack = item.track_is_live;
      const id = item.track_id;

      if (isLiveTrack) {
        this.setLiveMarkers(item);
      }

      let icon_url = '';
      let icon_class = 'no_live';
      if (isLiveTrack) {
        icon_url = this.markerPointerLive;
        icon_class = 'is_live';
      } else {
        icon_url = this.markerPointer;
      }

      const marker_icon = () => {
        return L.divIcon({
          html: this.markerGenerator(icon_url, item),
          shadowUrl: this.markerPointerShadow,
          className: icon_class,
          iconSize: [47, 59],
          shadowSize: [59, 33],
          shadowAnchor: [0, 30],
        });
      };

      let leafletMarker = L.marker([item.locality_latitude, item.locality_longitude], {
        icon: marker_icon(),
      });

      leafletMarker.addOneTimeEventListener('click', () => {
        if (!leafletMarker.getPopup()) {
          this.generatePopups(id);
        }
      });

      const markerObject = { id, marker: leafletMarker, isLive: isLiveTrack };
      this.markers.push(markerObject);
      this.markerClusterGroup.addLayer(leafletMarker);
    });
  }

  generatePopups(markerId, openLive = false) {
    const markerModel = this.markers.find((value) => value.id === markerId);
    const marker = markerModel.marker;
    const isLiveTrack = markerModel.isLive;
    
    const popupOptions = isLiveTrack ? {
      direction: 'top',
      offset: [0, -50],
      autoClose: false,
      closeOnClick: true,
      closeOnEscapeKey: false,
      closeButton: false,
      autoPan: false,
    } : {
      direction: 'top',
      offset: [0, -50],
    };
    
    const popupPlaceholder = PopupTemplates.getPlaceholderPopup();

    const popup = L.popup(popupOptions);
    marker.bindPopup(popup);

    let livePopup;
    let liveMarker;
    if (isLiveTrack) {
      liveMarker = this.liveMarkers.find((value) => value.id === markerId).marker;
      livePopup = L.popup({
        direction: 'top',
        offset: [0, -50],
        autoClose: false,
        closeOnClick: true,
        closeOnEscapeKey: false,
        closeButton: false,
        autoPan: false,
      });
      liveMarker.bindPopup(livePopup);
    }

    if (openLive && isLiveTrack) {
      livePopup.setContent(popupPlaceholder);
      liveMarker.openPopup();
    } else {
      popup.setContent(popupPlaceholder);
      marker.openPopup();
    }

    this.updatePopup(markerId, popup, livePopup);
  }

  clearMarkers(tracks) {
    super.clearMarkers(tracks, 'track_id');
  }

  setLiveMarkers(item) {
    const id = item.track_id;

    const icon_class = 'is_live';
    const icon_url = this.markerPointerLive;

    const marker_icon = () => {
      return L.divIcon({
        html:
          '<img src="' +
          icon_url +
          '" style="margin-top:-28px;height: 59px;width: 47px;"><div class="position-absolute w-100 text-center text-secondary fw-600 fs-16" style="top:-20px;left: 0;"><img class="rounded-circle" height="31" src="' +
          item.user_profile_image +
          '"></div>',
        shadowUrl: this.markerPointerShadow,
        className: icon_class,
        iconSize: [47, 59], // size of the icon 78*114
        shadowSize: [59, 33], // size of the shadow
        shadowAnchor: [0, 30], // the same for the shadow
      });
    };

    const leafletLiveMarker = L.marker([item.locality_latitude, item.locality_longitude], {
      icon: marker_icon(),
    });

    const markerObject = {
      id: id,
      marker: leafletLiveMarker,
      polyline: null,
      border: null,
    };

    this.liveMarkers.push(markerObject);
  }

  enableLive(tid, uid) {
    const new_selected_marker = this.markers.find((x) => tid === x.id);
    const live_marker = this.liveMarkers.find((x) => tid === x.id)?.marker;

    if (!new_selected_marker || !live_marker) return;

    this.markerClusterGroup.removeLayer(new_selected_marker.marker);
    this.map.addLayer(live_marker);

    if (live_marker.getPopup()) {
      live_marker.openPopup();
    } else {
      this.generatePopups(tid, true);
    }

    const ajaxData = {
      url: window.application.getAPIUrl() + '/v1/users/' + uid + '/track/' + tid,
      method: 'GET',
      data: {},
      beforeSend: (request) => {
        request.setRequestHeader('X-STL-Token', cookies.get('STL-Token'));
      },
    };

    $.ajax(ajaxData)
      .done((track_points) => {
        let border = new L.polyline(
          track_points.track.points.map((item) => {
            return { lat: item.latitude, lon: item.longitude };
          }),
          { color: 'white', weight: 3 }
        );

        let polyline = new L.polyline(
          track_points.track.points.map((item) => {
            return { lat: item.latitude, lon: item.longitude };
          }),
          { color: track_points.track.track_color.hexa_color, weight: 2 }
        );

        this.liveMarkers = this.liveMarkers.filter((x) => track_points.id !== x.id);

        this.liveMarkers.push({
          id: new_selected_marker.id,
          marker: live_marker,
          polyline: polyline,
          border: border,
        });
        this.selectedMarkers.push({
          id: new_selected_marker.id,
          marker: live_marker,
          polyline: polyline,
          border: border,
        });
        border.addTo(this.map);
        polyline.addTo(this.map);

        let buffer_index = this.liveBufferPoints.findIndex((obj) => obj.tid === track_points.id);

        if (!(buffer_index >= 0)) {
          const updated_marker = this.liveMarkers.find((x) => new_selected_marker.id === x.id).marker;

          const points = track_points.track.points.map((item) => ({ lat: item.latitude, lon: item.longitude }));

          const last_point = points[points.length - 1];

          updated_marker.setLatLng([last_point.lat, last_point.lon]);
        }
      })
      .fail((err) => console.error(err.responseText));
  }

  disableLive(tid, selectedTracks) {
    const selectedMarker = this.selectedMarkers.find((x) => tid === x.id);
    if (!selectedMarker) return;

    const newDeselectedMarker = this.markers.find((x) => tid === x.id).marker;

    const newDeselectedLiveMarker = selectedMarker.marker;
    const newDeselectedLivePolyline = selectedMarker.polyline;
    const newDeselectedLiveBorder = selectedMarker.border;

    this.selectedMarkers = this.liveMarkers.filter((x) => selectedTracks.includes(x.id));

    let pos = this.parent.last_selected_markers.indexOf(tid);
    if (pos >= 0) {
      this.parent.last_selected_markers.splice(pos, 1);
    }

    newDeselectedLiveMarker.closePopup();

    this.map.removeLayer(newDeselectedLiveMarker);
    this.map.removeLayer(newDeselectedLivePolyline);
    this.map.removeLayer(newDeselectedLiveBorder);

    if (newDeselectedMarker !== null && newDeselectedMarker !== undefined) {
      this.markerClusterGroup.addLayer(newDeselectedMarker);
    }
  }

  updateLiveMarker(data) {
    const tid = data.tid;
    const updated_marker = this.liveMarkers.find((x) => tid === x.id)?.marker;

    if (!updated_marker) return;

    updated_marker.setLatLng([data.lat, data.lon]);

    const selectedMarkerModel = this.selectedMarkers.find((x) => tid === x.id);

    let livePolyline = selectedMarkerModel.polyline;
    let liveBorder = selectedMarkerModel.border;

    let bufferIndex = this.liveBufferPoints.findIndex((obj) => obj.tid === tid);

    if (livePolyline !== undefined && liveBorder !== undefined) {
      if (bufferIndex >= 0) {
        this.liveBufferPoints[bufferIndex].points.forEach(function (item) {
          livePolyline.addLatLng(item);
          liveBorder.addLatLng(item);
        });
        this.liveBufferPoints.splice(bufferIndex, 1);
      }
      livePolyline.addLatLng([data.lat, data.lon]);
      liveBorder.addLatLng([data.lat, data.lon]);
    } else {
      if (bufferIndex >= 0) {
        this.liveBufferPoints[bufferIndex].points.push([data.lat, data.lon]);
      } else {
        this.liveBufferPoints.push({ tid: tid, points: [[data.lat, data.lon]] });
      }
    }

    $('#' + tid + '-current-speed').text(
      I18n.toNumber((Math.round(this.deps.measureConversion.convertSpeed(data.speed * 3.6) * 10) / 10).toFixed(1), {
        precision: 1,
      })
    );
    $('#' + tid + '-current-altitude').text(
      I18n.toNumber(Math.round(this.deps.measureConversion.convertAlternateDistance(data.alt)), { precision: 0 })
    );

    let pos = this.parent.last_selected_markers.indexOf(tid);
    if (pos >= 0 && this.selectedMarkers.length > 0) {
      let selected_markers = this.selectedMarkers.map(function (selected_m) {
        return selected_m.marker;
      });

      console.log('let', selected_markers);
      let group_m = new L.featureGroup(selected_markers);
      let zoom = this.map.getZoom();

      if (zoom < 11) {
        zoom = 11;
      }

      this.parent.map.flyToBounds(group_m.getBounds(), {
        duration: 0.5,
        padding: [50, 50],
        maxZoom: zoom,
      });

      this.parent.last_selected_markers.splice(pos, 1);
    }
  }

  getPopup(item) {
    let google_url = `https://maps.google.com/?q=${item.known_latitude},${item.known_longitude}`;

    return PopupTemplates.getPopup({ ...item, google_url });
  }

  updatePopup(trackId, popup, livePopup = null) {
    const ajaxData = {
      url: window.application.getAPIUrl() + '/v1/home/get_track',
      method: 'GET',
      data: {
        activity_id: trackId,
      },
    };

    $.ajax(ajaxData)
      .done((track) => {
        popup.setContent(this.getPopup(track));
        livePopup && livePopup.setContent(this.getLivePopup(track));
      });
  }

  getLivePopup(item) {
    return PopupTemplates
      .getLivePopup(item, this.deps.measureConversion.getSpeedUnit(), this.deps.measureConversion.getAlternateDistanceUnit());
  }
}
