import _ from "lodash"
export default class CesiumManagerWrapper {
    static maxAttempts = 4;
    static async initScene(elementId, {
        users,
        sceneType,
        hostInUse,
        windBearing,
        staticLabels,
        chartType,
        activityIcon,
        isTracksAlive,
        sceneId
    }) {
        let attempts = 0;
        let needRun = true;
        do {
            const isRun = await CesiumManagerWrapper._attemptInitPlayer(elementId, {
                users,
                sceneType,
                hostInUse,
                windBearing,
                staticLabels,
                chartType,
                activityIcon,
                isTracksAlive,
                sceneId
            });
            needRun = attempts < this.maxAttempts && isRun == false;

            if (needRun) {
                attempts++;
                await sleep(1000);
            }
        } while (needRun);

    }
    static async _attemptInitPlayer(elementId, {
        users,
        sceneType,
        hostInUse,
        windBearing,
        staticLabels,
        chartType,
        activityIcon,
        isTracksAlive,
        sceneId
    }) {
        if (typeof CesiumManager !== 'undefined') {

            await CesiumManager.initScene(elementId, {
                users: users,
                sceneType: sceneType,
                windBearing: windBearing,
                isTracksAlive: isTracksAlive,
                staticLabels: staticLabels,
                pathsLength: window.data.path_length || 2000,
                isFpvModeByDefault: window.data.start_in_fpv_mode === "true" ? true : null,
                hostInUse: hostInUse,
                endTrackOffset: window.data.end_track_offset || 10,
                offlineSetAfterSeconds: window.data.offline_set_after || 30,
                chartType: chartType || window.data.chart_type || null,
                activityIcon: activityIcon || window.data.activity_icon || null,
                showWindIndicator: window.data.show_wind_indicator || window.data.session.show_wind_indicator || null,
                groundOffset: window.data.ground_offset || 40,
                activityIcon: window.data.activity_icon || null,
                contest: sceneId !== undefined ? await this.getSceneContest(sceneId) : await this.getTrackContest(_.get(users, '[0].track.id'), parseInt(_.get(users, '[0].userId')))
            });
            return true;
        }
        return false;
    }
    static async getTrackContest(trackId, userId) {
        if (!trackId) {
            return null;
        }
        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/users/" + userId + "/track/" + trackId + "/contest",
            method: "GET",
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        let data;
        try {
            data = (await $.ajax(ajaxData));
        } catch (e) {
            data = null;
        }
        return data;
    }

    static async getSceneContest(sceneId) {
        if (!sceneId) {
            return null;
        }
        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/scene/" + sceneId + "/contest",
            method: "GET",
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        let data;
        try {
            data = (await $.ajax(ajaxData));
        } catch (e) {
            data = null;
        }
        return data;
    }
    static destroyWidget() {
        CesiumManager.destroy();
    }
}
const sleep = (timeInMS) => new Promise(resolve => setTimeout(resolve, timeInMS));