export default class MeasureConversion {

    MILE_IN_KILOMETER = 1.60934;
    NAUTIC_MILE_IN_KILOMETER = 1.852;
    METER_IN_FEET = 3.28084;

    constructor(user_unit_system) {
        this.distanceConversion = 1;
        this.speedConversion = 1;
        this.alternateDistanceConversion = 1;
        this.user_unit_system = user_unit_system
        this.updateUnitConversion();
    }

    updateUnitConversion() {
        if (this.user_unit_system === "measure_imperial") {
            this.distanceConversion = 1.0 / this.MILE_IN_KILOMETER;
            this.alternateDistanceConversion = this.METER_IN_FEET;
            this.speedConversion = 1.0 / this.MILE_IN_KILOMETER;
        } else if (this.user_unit_system === "measure_nautic") {
            this.distanceConversion = 1.0 / this.NAUTIC_MILE_IN_KILOMETER;
            this.alternateDistanceConversion = this.METER_IN_FEET;
            this.speedConversion = 1.0 / this.NAUTIC_MILE_IN_KILOMETER;
        }
    }

    convertDistance(value){
        return value * this.distanceConversion;
    }

    convertAlternateDistance(value){
        return value * this.alternateDistanceConversion;
    }

    convertSpeed(value){
        return value * this.speedConversion;
    }

    convertHrDistance(value){
        return value * this.distanceConversion + this.getDistanceUnit();
    }

    convertHrAlternateDistance(value){
        return value * this.alternateDistanceConversion + this.getAlternateDistanceUnit();
    }

    convertHrSpeed(value){
        return value * this.speedConversion + this.getSpeedUnit();
    }

    getDistanceUnit() {
        if(this.user_unit_system === "measure_imperial") {
            return "mi";
        }
        else if(this.user_unit_system === "measure_nautic") {
            return "nmi";
        }
        else {
            return "km";
        }
    }

    getAlternateDistanceUnit() {
        if(this.user_unit_system === "measure_imperial") {
            return "ft";
        }
        else if(this.user_unit_system === "measure_nautic") {
            return I18n.t("website.units.knots");
        }
        else {
            return "m";
        }
    }

    getSpeedUnit() {
        if(this.user_unit_system === "measure_imperial") {
            return "mi/h";
        }
        else if(this.user_unit_system === "measure_nautic") {
            return I18n.t("website.units.knots");
        }
        else {
            return "km/h";
        }
    }
}