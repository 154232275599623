export default class CheckoutFastStripe {
    constructor() {
        console.log("Fast Checkout Started");
        const stripe = Stripe(window.data.stripe_public_key);

        let elements;

        initialize();
        checkStatus();

        document
        .querySelector("#payment-form")
        .addEventListener("submit", handleSubmit);

        document.querySelector("#submit").disabled = true;

        let emailAddress = '';
        // Fetches a payment intent and captures the client secret
        async function initialize() {   
            const response = await fetch(window.data.create_payment_intent_url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-STL-Token': cookies.get("STL-Token"),
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                body: JSON.stringify({
                    product_id: window.data.product_id,
                    price_id: window.data.price_id,
                    currency: cookies.get("currency"),
                    return_url: window.parent.location.href
                })
            });

            const {clientSecret, user_email} = await response.json();
            const appearance = {
                theme: 'stripe',
            };

            elements = stripe.elements({ appearance, clientSecret }); 

            const linkAuthenticationElement = elements.create("linkAuthentication", {
                defaultValues: {
                    email: user_email
                }
            });

            linkAuthenticationElement.mount("#link-authentication-element");

            linkAuthenticationElement.on('change', (event) => {
                emailAddress = event.value.email;
            });

            const paymentElementOptions = {
                layout: "tabs",
            };

            const paymentElement = elements.create("payment", paymentElementOptions);
            paymentElement.mount("#payment-element");
            

            paymentElement.on("ready", () => {
                document.querySelector("#submit").disabled = false;
            });
        }

        async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.data.checkout_ok_url,
                receipt_email: emailAddress,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            showMessage(error.message);
        } else {
            showMessage("An unexpected error occurred.");
        }

        setLoading(false);
        }

        // Fetches the payment intent status after payment submission
        async function checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
            showMessage("Payment succeeded!");
            break;
            case "processing":
            showMessage("Your payment is processing.");
            break;
            case "requires_payment_method":
            showMessage("Your payment was not successful, please try again.");
            break;
            default:
            showMessage("Something went wrong.");
            break;
        }
        }

        // ------- UI helpers -------

        function showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add("hidden");
            messageText.textContent = "";
        }, 4000);
        }

        // Show a spinner on payment submission
        function setLoading(isLoading) {
            if (isLoading) {
                // Disable the button and show a spinner
                document.querySelector("#submit").disabled = true;
                document.querySelector("#spinner").classList.remove("hidden");
                document.querySelector("#button-text").classList.add("hidden");
            } else {
                document.querySelector("#submit").disabled = false;
                document.querySelector("#spinner").classList.add("hidden");
                document.querySelector("#button-text").classList.remove("hidden");
            }
        }
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

}