import ConfirmationModal from "../../common/ConfirmationModal";
import ToastNotification from "../utils/ToastNotification";
import CardManager from "../utils/CardManager";

export default class ConfigurationPremium {

    constructor() {
        this.confirmationModal = new ConfirmationModal();
        this.cardManager = new CardManager();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        $("#js-stop-subscription").unbind("click").click(function(e){
            e.preventDefault();
            let element = this;
            self.confirmationModal.displayConfirm(I18n.t("website.configuration.delete_subscription.title"),
                I18n.t("website.configuration.delete_subscription.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.configuration.delete_subscription.positive_btn"),
                        callback: function() {
                            self.deleteSubscription();
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.configuration.delete_subscription.negative_btn")
                    }
                });
        });
    }

    onDataChanged(data) {
        this.cardManager.onDataChanged(data);
    }

    onDestroy() {
    }

    deleteSubscription() {
        $("#js-stop-subscription").unbind("click");
        let self = this;
        let ajaxData = {
            url: '/checkout/cancel_subscription',
            data: {},
            method: "put",
        };
        $.ajax(ajaxData).done(function(data) {
        }).fail(function(err) {
            ToastNotification.make("Unsubscribe Error");
        });
    }
}