export default class NavigationHistoryHandler {

  constructor() {
    this.history = [];
    this.load();
  }

  load() {
    try {
      if(window.localStorage.history!=null) {
        this.history = JSON.parse(window.localStorage.history);
      }
    }
    catch(e) {}
  }

  store() {
    try {
      window.localStorage.history = JSON.stringify(this.history);
    }
    catch(e) {}
  }

  pageChanged() {
    this.updateDisplay();

    var pageType = "";
    var id = "";
    var title = null;

    if(window.application.controller_name === "user" && window.application.action_name === "show" && $("meta[property=\"stl:user_full_name\"]").attr("content") !== undefined ) {
      pageType = "user_profile";
      id = $("meta[property=\"stl:id\"]").attr("content");
      title = I18n.t("website.menu.history.locality_title", { location: $("meta[property=\"stl:user_full_name\"]").attr("content") });
    }
    else if(window.application.controller_name === "track") {
      if(window.application.action_name === "show" || window.application.action_name === "show_map") {
        pageType = "track_detail";
        id = $("meta[property=\"stl:id\"]").attr("content");
        let userFname = $("meta[property=\"stl:user_full_name\"]").attr("content");
        let category = I18n.t("track_categories."+$("meta[property=\"stl:category_name\"]").attr("content"));
        let location = $("meta[property=\"stl:location\"]").attr("content");

        title = I18n.t("website.menu.history.track_entry_title", { category: category, location: location, user_full_name: userFname });
      }
    }
    else if(window.application.controller_name === "scene") {
      if(window.application.action_name === "show") {
        pageType = "scene_detail";
        id = $("meta[property=\"stl:id\"]").attr("content");
        let userFname = $("meta[property=\"stl:user_full_name\"]").attr("content");
        let category = I18n.t("track_categories."+$("meta[property=\"stl:category_name\"]").attr("content"));
        let location = $("meta[property=\"stl:location\"]").attr("content");
        let sceneName = $("meta[property=\"stl:scene_name\"]").attr("content");

        title = I18n.t("website.menu.history.scene_entry_title", { category: category, location: location, user_full_name: userFname, scene_name: sceneName });
      }
    }
    else if(window.application.controller_name === "localities") {
      if(window.application.action_name === "index") {
        pageType = "locality";
        id = $("meta[property=\"stl:id\"]").attr("content");
        title = I18n.t("website.menu.history.user_title", { user_full_name: $("meta[property=\"stl:location\"]").attr("content") });
      }
    }

    if(pageType.length>0) {

      var entry = {
        date: (new Date()).toISOString(),
        title: title,
        type: pageType,
        url: window.location.href,
        id: id
      }

      this.history = this.history.filter(h => ((h.id !== entry.id) && (h.type === entry.type)) || (h.type !== entry.type))

      this.history.unshift(entry);
    }

    if(this.history.length>5) {
      this.history.pop();
    }
    this.store();
    this.updateDisplay();
  }

  updateDisplay() {
    $("#navigation-history-list").empty();

    if (this.history.length === 0) {
      $("#navigation-history-list").html('<div class="pt-2 pl-3 pb-2 w-100"><span>' + I18n.t("website.layout.menu.history_empty") + '</span></div>');
    } else {
      $("#navigation-history-list").html('<div class="pt-2 pl-3 pb-2 w-100"><span class="font-weight-bold">' + I18n.t("website.layout.menu.history") + '</span></div><hr class="pb-0 pt-1 mb-0 mt-1" />');
      for(var i = 0; i < this.history.length; i++) {
        var entry = this.history[i];
        $("<a class='mt-0 mb-0 text-primary fs-12 ff-lato'>").addClass("dropdown-item").attr("href", entry.url).text(entry.title).appendTo("#navigation-history-list");
      }
    }
  }

}