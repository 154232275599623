import 'bootstrap';
import ConfirmationModal from "../../common/ConfirmationModal";

export default class SocialActions {
    
    constructor() {
        this.bindEvents();
        this.confirmationModal = new ConfirmationModal();    
    }

    bindEvents() {
        const self = this;

        if (navigator.share) {
            $(".share").next().remove();
            $(".share").off("click").on("click", function (e) {
                navigator.share({
                    title: document.title,
                    url: document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href
                });
            });
        }

        $(".js-suscribe-user").off("click").on("click", function (e) {
            e.preventDefault();
            if(cookies.get("cs") !== null){
                self.suscribe($(this).data("user-id"));
            }else{
                window.location.replace("/users/sign_in");
            }
        });

        $(".js-unsuscribe-user").off("click").on("click", function (e) {
            e.preventDefault();
            if(cookies.get("cs") !== null){
                const element = this;
                self.confirmationModal.displayConfirm(I18n.t("website.user.unsubscribe.title"),
                    I18n.t("website.user.unsubscribe.message"),
                    {
                        positive_btn: {
                            type: "danger",
                            label: I18n.t("website.user.unsubscribe.positive_btn"),
                            callback: function() {
                                self.unsuscribe($(element).data("user-id"));
                            }
                        },
                        negative_btn: {
                            label: I18n.t("website.user.unsubscribe.negative_btn")
                        }
                    });
            }else{
                window.location.replace("/users/sign_in");
            }
        });
    }

    suscribe(user_id){
        $("#suscribe").off("click");
        const ajaxData = {
            url: window.application.getAPIUrl()+"/v1/users/"+user_id+"/follow",
            data: {user_id: user_id, type: "track"},
            method: "POST",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done((data) => {
            if (data !== undefined){
                $(".js-unsuscribe-user[data-user-id="+data.id+"]").removeClass("d-none");
                $(".js-suscribe-user[data-user-id="+data.id+"]").addClass("d-none");
                $(".js-suscribe-user-notification[data-user-id="+data.id+"]").addClass("d-none");
                $(".js-suscribe-user-notification[data-user-id="+data.id+"]").removeClass("d-flex");
                const count = $(".js-followers[data-user-id="+data.id+"]").data("followers") + 1;
                $(".js-followers[data-user-id="+data.id+"]").data("followers", count);
                $(".js-followers[data-user-id="+data.id+"]").text(count);
            }
            this.onUpdate();
        }).fail((err) => {
            this.onUpdate();
        });
    }

    unsuscribe(user_id){
        $("#unsuscribe").off("click");
        const ajaxData = {
            url: window.application.getAPIUrl()+"/v1/users/"+user_id+"/unfollow",
            data: {user_id: user_id, type: "track"},
            method: "DELETE",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done((data) => {
            if (data !== undefined){
                $(".js-unsuscribe-user[data-user-id="+data.id+"]").addClass("d-none");
                $(".js-suscribe-user[data-user-id="+data.id+"]").removeClass("d-none");
                $(".js-suscribe-user-notification[data-user-id="+data.id+"]").removeClass("d-none");
                $(".js-suscribe-user-notification[data-user-id="+data.id+"]").addClass("d-flex");
                
                const count = $(".js-followers[data-user-id="+data.id+"]").data("followers") - 1;
                $(".js-followers[data-user-id="+data.id+"]").data("followers", count);
                $(".js-followers[data-user-id="+data.id+"]").text(count);
            }
            this.onUpdate();
        }).fail((err) => {
            this.onUpdate();
        });
    }

    askLogin(){}

    onUpdate(){
        this.onDestroy();
        this.bindEvents();       
    }

    onDestroy(){
        $(".share").off("click");
    }

}