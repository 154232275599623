import LiveChatTemplates from "./templates/live-chat/live-chat-templates";
import { ITimelineLiveCommentsManagerAPI, TimelineLiveCommentsManager } from "./TimelineLiveCommentsManager";
import ConfirmationModal from "../../common/ConfirmationModal";
import "../../css/website/live-chat.scss";
import I18n from "i18n-js";

const CHANNEL_NAME = "LiveChatChannel";
const MESSAGE_BLINK_DURATION_MS = 2500;
const MESSAGE_BLINK_DELAY_MS = 500;

export default class LiveChatWidget {
    $container = null;
    initSelector = null;
    activityId = null;
    activityType = null;
    isChatPanelVisible = null;
    chatMessageLimit = null;

    data = null;
    metaInfo = null;
    dataPromise = null;
    metaInfoPromise = null;

    timelineManager = new ITimelineLiveCommentsManagerAPI();

    _subscriptions = [];
    _channelOptions = null;
    _currentTick = null;
    _isOpened = false;

    set isOpened(value) {
        this._isOpened = value;
        this.timelineManager.toggleTimelineCommentsVisibility(this._isOpened);
    }

    get isOpened() {
        return this._isOpened;
    }

    constructor() {
        this.confirmModal = new ConfirmationModal();

        $(() => {
            this.init().then(() => {
                if (window.location.hash === '#open_comment') {
                    this.toggle();
                }
            });
        });
    }

    show() {
        this.$container.addClass("opened");
        this.isOpened = true;
    }

    hide() {
        this.$container.removeClass("opened");
        this.isOpened = false;
        this.setCesiumPlayerRightOffset();
    }

    toggle() {
        this.isOpened ? this.hide() : this.show();
    }

    refresh() {
        this.isOpened ? this.show() : this.hide();
    }

    setCurrentTick(currentTick) {
        this._currentTick = currentTick;
        this.timelineManager.setCurrentTick(currentTick);
    };

    async initContent() {
        if (!this.isCanInit()) {            
            return;
        }

        this.metaInfoPromise = this._requestToMetaInfo();
        this.dataPromise = this._requestToComments({ order_type: 0 });

        await this.initHeader(false);
        this.initMessagesContainer();

        await this.fillMessages();
        this.subscribeToChannel();
    }
    isCanInit() {
        return this.activityId && this.activityType;
    }
    async init(activityId = null) {
        this.$container = this.$container || $(this.initSelector);

        if (!this.$container || !this.$container.length) {
            this.initChatContainer(activityId);
        } else {
            await this.initChatContainer(activityId);
        }
    }

    async initTimelineComments() {
        if (typeof CesiumManager !== "undefined" && this.isCanInit()) {
            this.setCesiumPlayerRightOffset();

            this.timelineManager = new TimelineLiveCommentsManager();

            const onClick = (event) => {
                const id = event.currentTarget.dataset.id;

                if (id && this.isChatPanelVisible) {
                    this.blinkMessage(id);
                    this.show();
                }
            };

            this.timelineManager.setCallbackOnAvatarClick(onClick.bind(this));
            this.timelineManager.setCallbackOnMessageLabelClick(onClick.bind(this))

            const data = await this.dataPromise;

            this.timelineManager.init(data);
        }
    }

    disableTimelineComments() {
        if (this.timelineManager) {
            this.timelineManager.onDestroy();
            this.timelineManager = new ITimelineLiveCommentsManagerAPI();
        }
    }

    subscribeToPlayerData() {
        if (typeof CesiumManager !== 'undefined' && this.activityType == "Track" && this.isCanInit()) {
            this._subscriptions.push(CesiumManager.selectedTrackId.subscribe((trackId) => {

                const isInitedTimeline = this.timelineManager.isImplementation();
                this.destroy(true);
                this.init(trackId);
                isInitedTimeline && this.initTimelineComments();
            }));
        }
    }

    async initChatContainer(activityId = null) {
        this.activityId = activityId || $("meta[name=activity_id]").attr("content");
        this.activityType = $("meta[name=activity_type]").attr("content");
        this.isChatPanelVisible = $("meta[name=chat_panel_visibility]").attr("content") == "true";
        this.chatMessageLimit = $("meta[name=chat_message_symbol_limit]").attr("content");

        if (!this.isCanInit()) {
            return;
        }

        this.$container = $(LiveChatTemplates.getContainer(this.activityId, this.activityType, this.isChatPanelVisible));
        this.$container.appendTo("body");
        
        document.addEventListener('fullscreenchange', () => {
            document.fullscreenElement ?
                this.$container.addClass("fullscreen")
                : this.$container.removeClass("fullscreen");
        });

        this.refresh();

        this.initToggleZone();

        const sendMessageForm = $("#send-form", this.$container)
        const input = $("#main-message-input", this.$container);

        input.on("keydown", (event) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                sendMessageForm.trigger("submit");
            }
        });
        sendMessageForm.on("submit", (event) => {
            event.preventDefault();

            const text = input.val();
            input.val("");

            this.createComment(text);
        });

        this.setCharCounterValidator(input, sendMessageForm);

        await this.initContent();
    }

    setCharCounterValidator(input, form) {
        $(".character-counter .max-value", form).text(this.chatMessageLimit);
        $(".character-counter .current-value", form).text(input.val().length);
        input.on("input", () => {
            const length = String(input.val()).length;

            $(".character-counter .current-value", form)
                .text(length <= this.chatMessageLimit ? length : this.chatMessageLimit + "+");

            if (length > this.chatMessageLimit) {
                input.addClass("is-invalid");
                input.get(0).setCustomValidity("Invalid");
            } else {
                input.removeClass("is-invalid");
                input.get(0).setCustomValidity("");
            }
        });
    }

    initToggleZone() {
        $(".toggle-zone", this.$container).on("click", (e) => {
            this.toggle();
        });
        
        this.$container.on("transitionend", () => {
            if (this.isOpened) {
                this.setCesiumPlayerRightOffset();
            }
        });
    }

    removeToggleZone() {
        $(".toggle-zone", this.$container).off("click");
    }

    setCesiumPlayerRightOffset() {
        if (typeof CesiumManager === "undefined") {
            return;
        }
        
        let chatPanelOffset = 0;
        if (this.isChatPanelVisible) {
            const chatWidth = this.$container.width();
            chatPanelOffset += this.isOpened ? chatWidth : $("#toggle-zone").width();
        }
        CesiumManager.setPlayerRightOffset(chatPanelOffset);
    }

    subscribeToChannel() {
        this._channelOptions = Object.freeze({
            channel: CHANNEL_NAME,
            activity_id: this.activityId,
            activity_type: this.activityType,
        });

        window.application.socket_handler.off(CHANNEL_NAME);
        window.application.socket_handler.subscribe(this._channelOptions);
        window.application.socket_handler.on(CHANNEL_NAME, (e, data) => {
            const message = data.message;
            switch (data.event) {
                case "error":
                    console.error(data.body);
                    return;
                case "new-comment":
                    this.addNewMessage(message);
                    this.timelineManager.processAddingComment(message);
                    break;
                case "new-answer":
                    this.addAnswers(data.parent_id, [message]);
                    break;
                case "like-count":
                    this.setLikeCounter(data.comment_id, data.count);
                    break;
                case "deleting-comment":
                    this.removeMessage(data.id);
                    break;
                case "updating-comment":
                    this.updateMessage(data.id, message);
                    break;
            }
        });
    }

    async initHeader(isNeedUpdateRequest = true) {
        if (isNeedUpdateRequest) {
            this.metaInfoPromise = this._requestToMetaInfo();
        }
        this.metaInfo = await this.metaInfoPromise;

        if (!this.metaInfo) {
            throw Error("meta data is not found");
        }

        const userName = this.metaInfo.user_name;
        const header = LiveChatTemplates.getMessengerHeader(
            {
                userName,
                totalMessages: 0,
            }
        );

        // Update color
        this._getMessageHeaderContainer().append(header);
        $("#widget-head-color").css("background-color", this.metaInfo.color);
    }

    initMessagesContainer() {
        const messagesContent = LiveChatTemplates.getMessagesContainer({});
        this._getMessageListContainer().append(messagesContent);

        this._getFilterSelect().on("change", (event) => {
            const value = $(event.currentTarget).val();
            this.fillMessages({ order_type: value });
        });
    }

    async fillMessages(options) {
        this._getMessagesContainer().empty();

        if (options) {
            this.dataPromise = this._requestToComments(options);
        }
        this.data = await this.dataPromise;
 
        if (!this.data) {
            throw Error("data is not found");
        }

        this.setTotalMessagesCount(this.data.length);
        this.data.forEach(comment => {
            this.insertMessage(comment);

            if (comment.answers_count > 0) {
                this._requestToAnswers(comment.id)
                    .then((answers) => {
                        this.addAnswers(comment.id, answers);
                    });
            }

        });
    }

    processLikeClick(e) {
        const answerParent = $(e.target).closest(".answer-item-container");
        const answerId = answerParent.attr("answer-data");

        const messageParent = $(e.target).closest(".message-item-container");
        const messageId = messageParent.attr("message-data");

        const targetCommentId = answerId || messageId;

        this._requestToToggleLike(targetCommentId)
            .then(({ liked_by_user }) => {
                this.setLikeState(targetCommentId, liked_by_user);
            })
            .catch((error) => {
                console.log(error.status);
                if (error.status === 401) {
                    document.location.href = "/users/sign_in";
                } else {
                    console.error(error);
                }
            });
    }

    processCommentClick(e) {
        const parent = $(e.target).closest(".message-item-container");
        const id = parent.attr("message-data");

        const openedAnswerForm = this._getCurrentAnswerForm();
        const openId = openedAnswerForm.attr("message-data");
        if (openId != id) {
            openedAnswerForm.remove();

            const answerFieldTemplate = LiveChatTemplates.getAnswerField(id);
            const answerField = $(answerFieldTemplate).appendTo(`[message-data='${id}'] .card-container`, this.$container);

            const input = $(`[message-data='${id}'] .message-input`);
            const sendAnswerForm = $(`[message-data='${id}'].send-answer-container`);

            input.on("keydown", (event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    sendAnswerForm.trigger("submit");
                }
            });

            sendAnswerForm.on("submit", (event) => {
                event.preventDefault();

                const message = input.val();
                this.createComment(message, id);
                answerField.remove();
                $(".answers", $(`[message-data='${id}']`)).show();
            });

            this.setCharCounterValidator(input, sendAnswerForm);
        }
    }

    processEditClick(e) {
        this.closeEditorForm();

        const parent = $(e.target).closest(".message-item-container, .answer-item-container");
        const id = parent.attr("message-data") || parent.attr("answer-data");

        $(`.card-actions[data-message-id="${id}"]`, parent).hide();
        $(".answers-container", parent).hide();
        $(".card-header", parent).hide();

        const textContentElement = $(`[data-message-id="${id}"] .text-content`, parent);
        const lastMainText = $(".main-text", textContentElement).html();

        textContentElement.hide();

        const editFormElement = this.getEditForm({
            submitEventCb: (event) => {
                event.preventDefault();
                const target = event.target;

                const input = $("#message-input", target);
                const message = input.val();

                const sendBtn = $(".send-btn", editFormElement); 
                sendBtn.attr("disabled", "true");

                this._requestToUpdateComment(id, message)
                    .then(() => {
                        this.closeEditorForm();
                    })
                    .catch((reason) => { 
                        console.error(reason);
                        sendBtn.removeAttr("disabled");
                    });
            },
            cancelEventCb: (event) => {
                event.preventDefault();
                this.closeEditorForm();
            },
            defaultText: lastMainText.replaceAll("<br>", "\n")
        });

        const messageBody = $(`#message-body[data-message-id="${id}"]`, parent);
        messageBody.append(editFormElement);

        const textareaElement = $("#message-input", messageBody)[0]
        textareaElement.focus();
        const caretPosition = textareaElement.value?.length ?? 0;
        textareaElement.selectionStart = caretPosition;
        textareaElement.selectionEnd = caretPosition;
    }

    getEditForm({ defaultText = "", submitEventCb = (e) => {}, cancelEventCb = (e) => {} }) {
        const form = $(LiveChatTemplates.getMessageEditForm(defaultText));
        
        const input = $("#message-input", form);

        const tipBtn = $(".cancel-btn", form);

        input.on("keydown", (event) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                form.trigger("submit");
            }
            if (["Escape", "Esc"].includes(event.key)) {
                this.closeEditorForm();
            }
        });
        form.on("submit", submitEventCb);
        tipBtn.on("click", cancelEventCb);

        this.setCharCounterValidator(input, form);

        return form;
    }

    closeEditorForm() {
        $("#message-edit-form").remove();
        $(".text-content").show();
        
        $(".card-header").show();
        $(`.card-actions[data-message-id]`).show();
        $(".answers-container").show();
    }

    processDeleteClick(e) {
        const id = $(e.target).parent().data("message-id");

        const scope = "website.track.comment.modal_deleting.";

        this.confirmModal.displayConfirm(
            `<span class="text-white">${I18n.t(scope + "title")}</span>`,
            I18n.t(scope + "message"),
            {
                positive_btn: {
                    type: "success",
                    label: I18n.t(scope + "positive_btn"),
                    callback: () => {
                        this._requestToDeleteComment(id).then(() => {
                            // this.removeMessage(id);
                        }).catch((reason) => {
                            console.error(reason);
                        });
                    }
                },
                negative_btn: {
                    label: I18n.t(scope + "negative_btn")
                }
            }
        );
    }

    removeMessage(id) {
        $(`.message-item-container[message-data="${id}"], .answer-item-container[answer-data="${id}"]`)
                .remove();
        this.timelineManager.processRemovingComment(id);        
    }

    updateMessage(id, messageData) {
        const messageItem = $(`.message-item-container[message-data="${id}"], .answer-item-container[answer-data="${id}"]`);

        $(`#message-body[data-message-id="${id}"] .main-text`, messageItem)
            .html(messageData.message);
        
        $(`.updated-at[data-message-id="${id}"]`, messageItem).text(messageData.updated_at_in_words);
        $(`.updated-at-date[data-message-id="${id}"]`, messageItem).text(messageData.updated_at_date);

        this.timelineManager.processUpdatingComment(id, messageData);
    }

    insertMessage(message, isPrepend = false) {
        const messageTemplate = LiveChatTemplates.getMessageItem(
            {
                id: message.id,
                text: message.message,
                user: message.user,
                likeCount: message.activity_comment_likes?.length ?? 0,
                userWasLiked: message.user_was_liked,
                createdAt: message.created_at_str,
                updatedAt: message.updated_at_in_words,
                updatedAtDate: message.updated_at_date,
                activityTime: message.activity_time,
                activityTimeToString: message.activity_time_to_string,
                targetUserId: this.metaInfo.user_id
            }
        );
        const element = $(messageTemplate);

        const messagesContainer = this._getMessagesContainer();
        isPrepend ?
            element.prependTo(messagesContainer) :
            element.appendTo(messagesContainer);
        $(".widget-target-user-"+ this.metaInfo.user_id + "-color").css("background-color", this.metaInfo.color);
        $(".comment-btn", element).on("click", (e) => this.processCommentClick(e));
        $(".card-container > .card-actions > .like-btn", element).on("click", (e) => this.processLikeClick(e));
        
        message.user_is_owner && this.appendEditDeleteButtons(message.id, element);
    }

    appendEditDeleteButtons(id, commentElement) {
        const cardActionsElement = $(`.card-actions[data-message-id="${id}"]`, commentElement);

        cardActionsElement.append($(LiveChatTemplates.getEditDeleteBtns()));
        $(".edit-btn", cardActionsElement).on("click", (e) => this.processEditClick(e));
        $(".delete-btn", cardActionsElement).on("click", (e) => this.processDeleteClick(e));
    }

    addNewMessage(message) {
        this.incrementTotalMessagesCounter();

        const isPrepend = this._getFilterSelect().val() === "0";
        this.insertMessage(message, isPrepend);
        const messagesContainer = this._getMessagesContainer();
        messagesContainer.animate({ scrollTop: isPrepend ? 0 : messagesContainer.prop("scrollHeight") }, "fast");
    }

    addAnswers(parentId, answers) {
        const parentNode = $(`[message-data='${parentId}']`);

        if (!$(".answers-container", parentNode).has('.answers-header').length) {
            const answersHeader = LiveChatTemplates.getMessageAnswersHeader({ answersLength: answers.length })

            $(".answers-container", parentNode).prepend(answersHeader);
            $(".answers-header a", parentNode).off("click").on("click", () => {
                $(".answers", parentNode).toggle();
            });
        }

        answers.forEach((answer, i) => {
            const answerTemplate = LiveChatTemplates.getAnswerItem({
                id: answer.id,
                text: answer.message,
                user: answer.user,
                likeCount: answer.activity_comment_likes.length,
                userWasLiked: answer.user_was_liked,
                createdAt: answer.created_at_str,
                updatedAt: answer.updated_at_in_words,
                updatedAtDate: answer.updated_at_date
            });
            const element = $(answerTemplate);
            element.appendTo($(`[message-data='${parentId}'] .answers`));

            $(`[answer-data='${answer.id}'] .like-btn`).on("click", (e) => this.processLikeClick(e));

            answer.user_is_owner && this.appendEditDeleteButtons(answer.id, element);

            this.incrementAnswerCount(parentNode);
        });
    }

    blinkMessage(id) {
        const messageElement = $(`[message-data='${id}']`);
        const cardElement = $(`.card-container > .card`, messageElement);

        $(".answers", messageElement).show();

        const messagesContainer = this._getMessagesContainer();

        const scrollPosition = messageElement.offset().top - messagesContainer.offset().top + messagesContainer.scrollTop();
        messagesContainer.animate({ scrollTop: scrollPosition }, "slow");

        cardElement
            .css("transition", "0s")
            .addClass("blink_helper");
        setTimeout(() => {
            cardElement
                .css("transition", MESSAGE_BLINK_DURATION_MS + "ms")
                .addClass("blink_me");
            setTimeout(() => {
                cardElement
                    .css("transition", "0s")
                    .removeClass("blink_me")
                    .removeClass("blink_helper");
            }, MESSAGE_BLINK_DURATION_MS);
        }, MESSAGE_BLINK_DELAY_MS);
    }

    setLikeCounter(commentId, count) {
        const likeCountElement = $(`.like-btn[like-for="${commentId}"] .like-count`); 
        likeCountElement.text(count);
    }

    setLikeState(commentId, state) {
        const likeBtn = $(`.like-btn[like-for="${commentId}"]`); 
        state ? likeBtn.addClass("liked-by-user") : likeBtn.removeClass("liked-by-user");
    }

    incrementAnswerCount(messageNode) {
        const answerCounter = $(".answers-container .answers-header .answer-count", messageNode);
        const answerCount = 1 + (answerCounter.data("answer-count") ?? 0);
        answerCounter.data("answer-count", answerCount);
        answerCounter.text(`${answerCount} ${I18n.t("website.track.comment.answers",{count:answerCount})}`);
    }

    incrementTotalMessagesCounter() {
        const totalMessagesCount = $(".total-messages", this._getMessageHeaderContainer()).data("total-messages") ?? 0;
        this.setTotalMessagesCount(1 + totalMessagesCount);
    }

    setTotalMessagesCount(count) {
        const totalMessagesCounter = $(".total-messages", this._getMessageHeaderContainer());
        totalMessagesCounter.data("total-messages", count);
        totalMessagesCounter.text(count);
    }

    createComment(message, parentId = undefined) {
        if (!message || this._currentTick === null) return;
        const currentTime = Math.trunc(this._currentTick);
        this._requestToCreateComment(message, currentTime, parentId).then()
            .catch((error) => {
                console.log(error.status);
                if (error.status === 401) {
                    document.location.href = "/users/sign_in";
                } else {
                    console.error(error);
                }
            });
    }

    destroy(isSoft = false) {
        if (this.$container) {
            $(".close", this.$container).off("click");

            $(".answers-header a", this.$container).off("click");
            $(".comment-btn", this.$container).off("click");
            $(".like-btn", this.$container).off("click");
            $(".edit-btn", this.$container).off("click");
            $(".destroy-btn", this.$container).off("click");

            this.removeToggleZone()
            // * off for all handlers (but there can be several open chats) 
            // window.application.socket_handler.off(CHANNEL_NAME);
            window.application.socket_handler.unsubscribe(this._channelOptions);
            if (!isSoft) {
                this._subscriptions.forEach(x => x.unsubscribe());
            }

            this.disableTimelineComments();
            this.$container.empty();
            this.$container.remove();
        }
    }

    _requestToAPI({ data, method, urlPostfix = "" }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: window.application.getAPIUrl() + "/v1/activity_comments" + urlPostfix,
                method,
                headers: {
                    "Accept-Language": I18n.locale
                },
                data,
                beforeSend: (request) => {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            }).done(resolve).fail(reject);
        });
    }

    _requestToMetaInfo() {
        return this._requestToAPI({
            urlPostfix: "/meta",
            data: { activity_id: this.activityId, activity_type: this.activityType },
            method: "GET",
        });
    }

    _requestToComments(options) {
        return this._requestToAPI({
            data: { activity_id: this.activityId, activity_type: this.activityType, ...options },
            method: "GET",
        });
    }

    _requestToAnswers(parent_id) {
        return this._requestToAPI({
            data: {
                activity_id: this.activityId,
                activity_type: this.activityType,
                parent_id
            },
            method: "GET",
        });
    }

    _requestToToggleLike(activity_comment_id) {
        return this._requestToAPI({
            urlPostfix: "/like",
            data: { activity_comment_id },
            method: "POST",
        });
    }

    _requestToCreateComment(message, activity_time, parentId) {
        return this._requestToAPI({
            data: {
                activity_id: this.activityId,
                activity_type: this.activityType,
                message,
                activity_time,
                parent_id: parentId
            },
            method: "POST",
        });
    }

    _requestToUpdateComment(id, message) {
        return this._requestToAPI({
            data: {
                id,
                message
            },
            method: "PUT"
        });
    }

    _requestToDeleteComment(id) {
        return this._requestToAPI({
            data: {
                id
            },
            method: "DELETE"
        });
    }

    _getMainSendBtn() {
        return $("#main-send-btn", this.$container);
    }

    _getMainTextArea() {
        return $("#main-message-input", this.$container);
    }

    _getFilterSelect() {
        return $(".filter-select", this.$container);
    }

    _getMessageHeaderContainer() {
        return $(".messenger-header-container", this.$container);
    }

    _getMessageListContainer() {
        return $(".message-list-container", this.$container);
    }

    _getMessagesContainer() {
        return $(".messages", this.$container);
    }

    _getCurrentAnswerForm() {
        return $(".send-answer-container", this.$container);
    }
}
