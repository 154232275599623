import { globalState, fnSwapList } from "./Context";

class Particle {
	
	constructor() {
		this.fX = 0.0;
		this.fY = 0.0;
		this.fZ = 0.0;
		this.fVX = 0.0;
		this.fVY = 0.0;
		this.fVZ = 0.0;
		this.fAX = 0.0;
		this.fAY = 0.0;
		this.fAZ = 0.0;
		this.fProjX = 0.0;
		this.fProjY = 0.0;
		this.fRotX = 0.0;
		this.fRotZ = 0.0;
		this.pPrev = null;
		this.pNext = null;

		this.fAngle = 0.0;
		this.fForce = 0.0;

		this.fGrowDuration = 0.0;
		this.fWaitDuration = 0.0;
		this.fShrinkDuration = 0.0;

		this.fRadiusCurrent = 0.0;
		this.iFramesAlive = 0;
		this.bIsDead = false;
		// Utility functions to manipulate angles and velocity
		const fnRnd = () => Math.random();

	}

	fnInit() {
		const fnRnd = () => Math.random();
		const fnRnd2 = () => 2.0 * fnRnd() - 1.0;
		const fnSin = Math.sin;
		const fnACos = Math.acos;
		const iRadiusSphere = globalState.iRadiusSphere;
		const fStartVX = 0.001;
		const fStartVY = 0.001;
		const fStartVZ = 0.001;

		this.fAngle = fnRnd() * Math.PI * 2;
		this.fForce = fnACos(fnRnd2());
		this.fAlpha = 0;
		this.bIsDead = false;
		this.iFramesAlive = 0;
		this.fX = iRadiusSphere * fnSin(this.fForce) * Math.cos(this.fAngle);
		this.fY = iRadiusSphere * fnSin(this.fForce) * fnSin(this.fAngle);
		this.fZ = iRadiusSphere * Math.cos(this.fForce);
		this.fVX = fStartVX * this.fX;
		this.fVY = fStartVY * this.fY;
		this.fVZ = fStartVZ * this.fZ;
		this.fGrowDuration = globalState.fGrowDuration + fnRnd2() * (globalState.fGrowDuration / 4.0);
		this.fWaitDuration = globalState.fWaitDuration + fnRnd2() * (globalState.fWaitDuration / 4.0);
		this.fShrinkDuration = globalState.fShrinkDuration + fnRnd2() * (globalState.fShrinkDuration / 4.0);
		this.fAX = 0.0;
		this.fAY = 0.0;
		this.fAZ = 0.0;
	}

	fnUpdate() {
		// Update acceleration and velocity
		if (this.iFramesAlive > this.fGrowDuration + this.fWaitDuration) {
			const fnRnd2 = () => 2.0 * Math.random() - 1.0;
			const fMaxAX = 0.1;
			const fMaxAY = 0.1;
			const fMaxAZ = 0.1;

			this.fVX += this.fAX + fMaxAX * fnRnd2();
			this.fVY += this.fAY + fMaxAY * fnRnd2();
			this.fVZ += this.fAZ + fMaxAZ * fnRnd2();
			this.fX += this.fVX;
			this.fY += this.fVY;
			this.fZ += this.fVZ;
		}

		// Update rotation
		const fSinAngle = Math.sin(globalState.fAngle);
		const fCosAngle = Math.cos(globalState.fAngle);

		this.fRotX = fCosAngle * this.fX + fSinAngle * this.fZ;
		this.fRotZ = -fSinAngle * this.fX + fCosAngle * this.fZ;
		this.fRadiusCurrent = Math.max(0.01, globalState.iPerspective / (globalState.iPerspective - this.fRotZ));
		this.fProjX = this.fRotX * this.fRadiusCurrent + globalState.iProjSphereX;
		this.fProjY = this.fY * this.fRadiusCurrent + globalState.iProjSphereY;

		// Update frame lifetime and alpha
		this.iFramesAlive += 1;

		if (this.iFramesAlive < this.fGrowDuration) {
			this.fAlpha = this.iFramesAlive / this.fGrowDuration;
		} else if (this.iFramesAlive < this.fGrowDuration + this.fWaitDuration) {
			this.fAlpha = 1.0;
		} else if (this.iFramesAlive < this.fGrowDuration + this.fWaitDuration + this.fShrinkDuration) {
			this.fAlpha = (this.fGrowDuration + this.fWaitDuration + this.fShrinkDuration - this.iFramesAlive) / this.fShrinkDuration;
		} else {
			this.bIsDead = true;
		}

		// Check if the particle is dead and perform a list swap if necessary
		if (this.bIsDead) {
			fnSwapList(this, globalState.oRender, globalState.oBuffer);
		}

		this.fAlpha *= Math.min(1.0, Math.max(0.5, this.fRotZ / globalState.iRadiusSphere));
		this.fAlpha = Math.min(1.0, Math.max(0.0, this.fAlpha));
	}
}

export default Particle;
