export default class CookiesIndex {

    constructor() {
        this.bindEvents();

        if(cookies.get("gdpr") !== null){
            if(cookies.get("gdpr").includes("third_party")){
                $("#third-switch").prop("checked",true);
            }
            if(cookies.get("gdpr").includes("tracking")){
                $("#tracking-switch").prop("checked",true);
            }
        }
    }


    bindEvents() {
        let self = this;
        $(".cookie_switcher").unbind("click").click(function () {
            self.cookie_toggle($(this).val())
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $(".cookie_switcher").unbind("click");
    }

    cookie_toggle(cookie_type) {
        if(cookies.get("gdpr") !== null && cookies.get("gdpr").includes(cookie_type)){
            let arr_cookies = cookies.get("gdpr").split(",");
            arr_cookies.splice(arr_cookies.indexOf(cookie_type),1);
            cookies.create("gdpr", arr_cookies, 5*365);
        }else{
            if(cookies.get("gdpr") !== null){
                cookies.create("gdpr", cookies.get("gdpr") + ',' + cookie_type, 5*365);
            }else{
                cookies.create("gdpr", cookie_type, 5*365);
            }
        }
    }
}