import LiveMap3D from "../utils/LiveMap3D.js";
import LiveMap2D from "../utils/LiveMap2D.js";
import LiveChatWidget from "../utils/LiveChatWidget";

export default class UserLive {
	constructor() {

		this.mode = null;
		this.tracks = [];
		this.handler = null;

		this.user_ids = [];

		this.socket_initialized = false;

		this.bindEvents();

        this.liveChat = new LiveChatWidget();
	}

	bindEvents() {
		window.application.setOnDataChangeListener(this);
	}

	bindSocket() {
		var self = this;
		$(document).off('socket-LiveChannel').on('socket-LiveChannel', function (e, data) {
      		self.onSocketMessageReceived(data);
      	});

		$(document).off('socket-UserChannel').on('socket-UserChannel', function (e, data) {
      		self.onSocketMessageReceived(data);
      	});

		//window.application.socket_handler.subscribe({ channel: "LiveChannel", id: this.session.tid })

		window.application.socket_handler.subscribe({ channel: "UserChannel", id: this.tracks[0].user.id });
		$.each(this.tracks, function(idx, track) {
			if(!self.user_ids.includes(track.user.id)) {
				self.user_ids.push(track.user.id);
				window.application.socket_handler.subscribe({ channel: "LiveChannel", id: track.user.id })
			}
		});
	}

	async onDataChanged(data) {
		this.mode = data.mode;
		this.session = data.session;
		this.tracks = data.session.tracks;


		if(this.mode=="2D") {
			this.handler = new LiveMap2D();
			this.handler.init(this.session);
		}
		else {
			this.handler = new LiveMap3D(data.first_appear_ad, data.frequency_ad);
			await this.handler.init(this.session);
			await this.liveChat.initTimelineComments();

            this.handler.onTickChanges((currentTime) => {
                this.currentTime = currentTime;
                this.liveChat.setCurrentTick(currentTime);
            });
            this.liveChat.subscribeToPlayerData();
		}

		if(this.socket_initialized==false) {
			this.bindSocket();
			this.socket_initialized = true;
		}
	}

	onSocketMessageReceived(data) {
		this.liveChat.timelineManager && this.liveChat.timelineManager.recalculatePositions();
		if(data.e=="point-add" || data.e=="track_end") {
			this.handler.onPointReceived(data);
		}
		else if(data.e=="user-around") {
			if(!this.user_ids.includes(data.uid)) {
				this.user_ids.push(data.uid);
				window.application.socket_handler.subscribe({ channel: "LiveChannel", id: data.uid });
			}
		}
	}
}