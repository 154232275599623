export default class SearchBar {

    constructor() {
        this.inputId = "search_input";
        this.inputEl = document.getElementById(this.inputId);
        this.inputVal = "";
        this.dropdownId = "select_type";
        this.dropdownEl = document.getElementById(this.dropdownId);
        this.dropdownVal = 0;
        this.dropdownItemsClass = "js-search-type";
        this.spinnerEl = null;
        this.currentFocus = -1;
        this.timer = 0;
        this.count = 0;
        this.items = [];
        this.activities = [];
        if(this.inputEl!=null) {
            this.bindEvents();
            this.setFinder();
        }
    }

    setFinder(){
        this.dropdownVal = document.getElementById("search_type").value;

        if(this.dropdownVal == 1){
            this.loadActivities();
        }
    }

    bindEvents(){
        let self = this;

        this.inputEl.addEventListener("input", function(e) {
            self.inputVal = this.value.trim();

            if(self.timer){
                clearTimeout (self.timer);
            }
            self.timer = setTimeout(function(){self.makeSearch();}, 500);
        });

        this.inputEl.addEventListener("keydown", function(e) {
            self.moveToList(e);
        });

        let elements = document.getElementsByClassName(this.dropdownItemsClass);
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', function (e) {
                e.preventDefault();
                self.selectDropdownItem(this);
            });
        }


        document.addEventListener("click", function (e) {
            self.closeAllLists(e.target);
        });

    }

    addActive(x) {
        if (!x) return false;
        this.removeActive(x);
        if (this.currentFocus >= x.length) this.currentFocus = 0;
        if (this.currentFocus < 0) this.currentFocus = (x.length - 1);
        x[this.currentFocus].classList.add("autocomplete-active");
    }

    removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    closeAllLists(elmnt) {
        this.currentFocus = -1;
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== this.inputEl) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    setElements(list, items){

        switch (this.dropdownVal) {
            case 0: //Display User result
                this.listUsers(list, items);
                break;
            case 1:
                this.noResults();
                break;
            case 2:
                this.noResults();
                break;
            case 3:
                this.noResults();
                break;
            default:
                this.noResults();
                break;
        }
    }

    noResults(){
        this.spinnerEl.innerHTML = '<div class="my-3">'+ I18n.t("website.layout.search_bar.no_results") +'</div>';
    }

    moveToList(e) {
        var x = document.getElementById(this.inputId + "autocomplete-list");
        var key = e.key || e.keyCode;

        if (x) x = x.getElementsByClassName("js-list-item");
        if (key === 'ArrowDown' || key === 40) {//down
            this.currentFocus++;
            this.addActive(x);
        } else if (key === 'ArrowUp' || key === 38) { //up
            this.currentFocus--;
            this.addActive(x);
        } else if (key === 'Enter' || key === 13) {//enter
            e.preventDefault();
            if (this.currentFocus > -1) {
                if (x) x[this.currentFocus].click();
            }else{
                if(this.dropdownVal != 1 && this.inputVal.trim().length > 1){
                    $("#main_search_form").submit();
                }
            }
        }
    }

    makeSearch() {

        this.closeAllLists();
        if (!this.inputVal) { return false;}
        switch (parseInt(this.dropdownVal)) {
            case 0: //Display User result
                this.searchUser();
                break;
            case 1:
                this.searchActivity();
                break;
            case 2:
                this.searchLocality();
                break;
            case 3:
                this.noResults();
                break;
            default:
                this.noResults();
                break;
        }
    }

    /*--- USER ---*/
    searchUser(){
        let self = this;
        let list = document.createElement("DIV");
        list.setAttribute("id", this.inputId + "autocomplete-list");
        list.setAttribute("class", "autocomplete-items");
        list.classList.add("position-absolute");
        let typeSelect = document.getElementById("select_type");
        list.innerHTML = '<div id="search_spinner" class="text-center"><div class="spinner-grow my-3 " role="status">\n' +
            '  <span class="sr-only">Loading...</span>\n' +
            '</div></div>';
        this.inputEl.parentNode.appendChild(list);
        this.spinnerEl = document.getElementById("search_spinner");

        let ajaxData = {
            url: window.application.getAPIUrl()+"/v1/search",
            data: {
                search_type: self.dropdownVal,
                search_input: self.inputVal,
                search_mode: 'autocomplete'
            },
            method: "GET",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                self.listUsers(list, data);
            }
        }).fail(function(err) {
            self.noResults();
        });
    }

    listUsers(list, items){

        let following = items.following;
        let otherUsers = items.other_users;
        if(following.length > 0 || otherUsers.length > 0){
            let self = this;

            this.count = 0;

            if (following.length > 0){
                list.appendChild(this.createHeader(I18n.t("website.layout.search_bar.users_you_follow")));
                this.listItemsUsers(list,following);
            }

            if (otherUsers.length > 0){
                list.appendChild(this.createHeader(I18n.t("website.layout.search_bar.look_for_athletes")));
                this.listItemsUsers(list,otherUsers);
            }
            this.spinnerEl.innerHTML = '';
            window.application.reloadLazyLoad();
        }else{
            this.noResults();
        }
    }

    listItemsUsers(list, items){
        let self = this;
        for (let i = 0; i < items.length; i++) {

            //if (items[i].substr(0, this.inputVal.length).toUpperCase() === this.inputVal.toUpperCase()) {
            let listItem = document.createElement("DIV");
            listItem.classList.add("media");
            listItem.classList.add("js-list-item");

            listItem.dataset.focusint = this.count;
            this.count++;
            let img = document.createElement("IMG");
            img.src = window.application.getMeta("loader_svg");
            img.setAttribute("data-src", items[i].image);
            img.height = 35;
            // img.classList.add("rounded-circle");
            img.classList.add("lazy");
            listItem.appendChild(img);

            let divContent = document.createElement("DIV");
            divContent.classList.add("pr-0");
            divContent.classList.add("pl-2");
            divContent.classList.add("py-2");
            divContent.innerHTML = '<div class="mt-0 fs-14">'+items[i].name+'</div>';

            listItem.appendChild(divContent);
            listItem.innerHTML += "<input type='hidden' value='"+items[i].name+ "'>";
            listItem.addEventListener("click", function(e) {
                self.inputEl.value = this.getElementsByTagName("input")[0].value;
                self.closeAllLists();
                window.location.href = items[i].url;
            });

            listItem.addEventListener("mouseenter", function( event ) {
                self.currentFocus = this.dataset.focusint;
                var x = document.getElementById("search_inputautocomplete-list");
                if (x) x = x.getElementsByClassName("js-list-item");
                self.addActive(x);
            }, false);

            list.appendChild(listItem);
            //}
        }
    }

    /*--- Activity ---*/

    searchActivity(){

        let list = document.createElement("DIV");
        list.setAttribute("id", this.inputId + "autocomplete-list");
        list.setAttribute("class", "autocomplete-items");
        list.classList.add("position-absolute");
        let typeSelect = document.getElementById("select_type");
        list.innerHTML = '<div id="search_spinner" class="text-center"><div class="spinner-grow my-3 " role="status">\n' +
            '  <span class="sr-only">Loading...</span>\n' +
            '</div></div>';
        this.inputEl.parentNode.appendChild(list);
        this.spinnerEl = document.getElementById("search_spinner");

        this.listActivities(list, this.activities);

    }

    listActivities(list, items){
        if(items.length > 0){
            this.spinnerEl.innerHTML = '';
            this.count = 0;
            this.listItemsActivities(list,items);
        }else{
            this.noResults();
        }
    }

    listItemsActivities(list, items){
        let self = this;
        for (let i = 0; i < items.length; i++) {
            let name = items[i].hr_name;

            if ((name.substr(0, this.inputVal.length).toUpperCase() === this.inputVal.toUpperCase()) || (items[i].name.substr(0, this.inputVal.length).toUpperCase() === this.inputVal.toUpperCase())) {
                let listItem = document.createElement("DIV");
                listItem.classList.add("media");
                listItem.classList.add("js-list-item");


                listItem.dataset.focusint = this.count;
                this.count++;

                let span = document.createElement("span");
                span.classList.add("bg-secondary");
                span.classList.add("dot-md");

                let img = document.createElement("i");
                img.classList.add("icon-"+items[i].icon_class);
                img.classList.add("icon");
                img.classList.add("fs-20");
                img.classList.add("lh-5");
                img.classList.add("text-white");
                span.appendChild(img);
                listItem.appendChild(span);

                let divContent = document.createElement("DIV");
                divContent.classList.add("pr-0");
                divContent.classList.add("pl-2");
                divContent.classList.add("py-2");
                divContent.innerHTML = '<div class="mt-0 fs-16">'+name+'</div>';

                listItem.appendChild(divContent);
                listItem.innerHTML += "<input type='hidden' value='"+name+ "'>";
                listItem.addEventListener("click", function(e) {
                    self.inputEl.value = this.getElementsByTagName("input")[0].value;
                    self.closeAllLists();
                    window.location.href = items[i].url;
                });

                listItem.addEventListener("mouseenter", function( event ) {
                    self.currentFocus = this.dataset.focusint;
                    var x = document.getElementById("search_inputautocomplete-list");
                    if (x) x = x.getElementsByClassName("js-list-item");
                    self.addActive(x);
                }, false);

                list.appendChild(listItem);
            }
        }
        if(this.count == 0){
            this.noResults();
        }
    }



    createHeader(text){
        let listHeader = document.createElement("DIV");
        listHeader.classList.add("px-2");
        listHeader.classList.add("py-2");
        listHeader.classList.add("fs-14");
        listHeader.innerHTML = text;
        return listHeader;
    }

    selectDropdownItem(item) {
        this.dropdownVal = parseInt(item.dataset.type);
        $("#search_type").val(this.dropdownVal);
        $("#search_input").focus();
        $("#search_input").val("");
        this.currentFocus = -1;
        this.dropdownEl.innerHTML = '\n' +
            '              <span class="d-inline-block lh-3 dropdown-selector-search">'+item.dataset.text+'</span>\n' +
            '              <i class="ml-2 fas fa-chevron-down fs-12"></i>';

        if(this.dropdownVal === 1 && this.activities.length === 0){
            this.loadActivities();
        }
    }

    loadActivities(){
        let self = this;
        let ajaxData = {
            url: window.application.getAPIUrl()+"/v1/search",
            data: {
                search_type: this.dropdownVal,
                lang: I18n.locale
            },
            method: "GET",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                self.activities = data.activities;
            }
        }).fail(function(err) {
        });
    }


    /*--- LOCALITY ---*/
    searchLocality() {
        let self = this;
        let list = document.createElement("DIV");
        list.setAttribute("id", this.inputId + "autocomplete-list");
        list.setAttribute("class", "autocomplete-items");
        list.classList.add("position-absolute");
        let typeSelect = document.getElementById("select_type");
        list.innerHTML = '<div id="search_spinner" class="text-center"><div class="spinner-grow my-3 " role="status">\n' +
            '  <span class="sr-only">Loading...</span>\n' +
            '</div></div>';
        this.inputEl.parentNode.appendChild(list);
        this.spinnerEl = document.getElementById("search_spinner");

        let ajaxData = {
            url: window.application.getAPIUrl()+"/v1/search",
            data: {
                search_type: self.dropdownVal,
                search_input: self.inputVal,
                search_mode: 'autocomplete'
            },
            method: "GET",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                self.listLocalities(list, data);
            }
        }).fail(function(err) {
            self.noResults();
        });
    }

    listLocalities(list, items){

        let localities = items.localities;
        if(localities.length > 0){
            this.spinnerEl.innerHTML = '';

            this.count = 0;
            this.listItemsLocalities(list,localities);

        }else{
            this.noResults();
        }
    }

    listItemsLocalities(list, items){
        let self = this;
        for (let i = 0; i < items.length; i++) {

            let listItem = document.createElement("DIV");
            listItem.classList.add("js-list-item");

            listItem.dataset.focusint = this.count;
            this.count++;

            let divContent = document.createElement("DIV");
            divContent.classList.add("pr-0");
            divContent.classList.add("pl-2");
            divContent.classList.add("py-2");
            divContent.innerHTML = '<div class="mt-0 fs-16"><span class="flag-icon flag-icon-'+items[i].country_code+' rounded mr-2"></span>'+items[i].name+'</div>';

            listItem.appendChild(divContent);
            listItem.innerHTML += "<input type='hidden' value='"+items[i].name+ "'>";
            listItem.addEventListener("click", function(e) {
                self.inputEl.value = this.getElementsByTagName("input")[0].value;
                self.closeAllLists();
                window.location.href = items[i].url;
            });

            listItem.addEventListener("mouseenter", function( event ) {
                self.currentFocus = this.dataset.focusint;
                var x = document.getElementById("search_inputautocomplete-list");
                if (x) x = x.getElementsByClassName("js-list-item");
                self.addActive(x);
            }, false);

            list.appendChild(listItem);
        }
    }
}