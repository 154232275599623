require('p5');

const isElementInViewPort = (id) => {
    const element = document.getElementById(id);
    if (element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    } else {
        return false;
    }
};

const sketch = (p) => {
    let rgb1, rgb2, rgb3, r, g, b, a, cnvs, cols, rows;
    let scl = 20;
    let w = window.innerWidth;
    let h = window.innerHeight;
    let flying = 0;
    let terrain = [];

    let terrainValues = {
        speed: 0.1,
        rotation: 62,
        mountainHeight: 173,
        bgColor: '#1C1E23',
        lineColor: [32, 212, 74],
        lineWidth: 0.9,
        terrainColor: [132, 0, 0],
        terrainOpacity: 0.16,
        randomizeColors() { randColor() }
    };

    const randNum = (from, to) => Math.floor(Math.random() * (to - from + 1) + from);

    const randColor = () => {
        let rrr = randNum(0, 255);
        let ggg = randNum(0, 255);
        let bbb = randNum(0, 255);
        
        go(rrr, ggg, bbb);
        
        r = terrainValues.terrainColor[0];
        g = terrainValues.terrainColor[1];
        b = terrainValues.terrainColor[2];
        a = terrainValues.terrainOpacity;
    }

    const corrected = (x) => {
        let val = (x * 1);
        return val > 255 ? 255 : val < 0 ? 0 : Math.round(val);
    };

    const complimentary = (x) => 255 - Math.abs((x - 127.5));

    const go = (r1, g1, b1) => {
        let rr = Math.round(complimentary(r1));
        let gg = Math.round(complimentary(g1));
        let bb = Math.round(complimentary(b1));

        rgb1 = `rgb(${r1},${g1},${b1})`;
        rgb2 = `rgb(${rr},${gg},${bb})`;
        rgb3 = `rgb(${corrected(rr - 100)},${corrected(gg - 100)},${corrected(bb - 100)})`;
        terrainValues.bgColor = rgb1;
        terrainValues.terrainColor[0] = rr;
        terrainValues.terrainColor[1] = gg;
        terrainValues.terrainColor[2] = bb;
        terrainValues.terrainColor = rgb2;
        terrainValues.lineColor = rgb3;
        terrainValues.terrainOpacity = randNum(0, 10) * 0.1;
    };

    // Initialize the canvas
    const initializeCanvas = () => {
        cnvs = p.createCanvas(w, h, p.WEBGL);
        // Usage of style and parent function of p5(canvas)
        cnvs.style('width', '100%');
        cnvs.style('position', 'absolute');
        cnvs.style('bottom', '0');
        cnvs.parent('ws-section-2');
        cols = 2 * w / scl;
        rows = 2 * h / scl;
        for (let x = 0; x < cols; x++) {
            terrain[x] = [];
            for (let y = 0; y < rows; y++) {
                terrain[x][y] = 0;
            }
        }
    };

    const removeCanvas = () => {
        if (cnvs) {
            cnvs.remove();
            cnvs = null;
        }
        terrain = [];
    };

	const updateCanvas = () => {
        if (isElementInViewPort("ws-section-2")) {
            if (!cnvs) {
                initializeCanvas();
            }
        } else {
            removeCanvas();
        }
    };

    p.setup = () => {
        initializeCanvas();
        // updateCanvas();

    };

    p.draw = () => {
        if (!cnvs) return;

        flying -= terrainValues.speed;
        let yoff = flying;
        for (let y = 0; y < rows; y++) {
            var xoff = 0;
            for (let x = 0; x < cols; x++) {
                terrain[x][y] = p.map(p.noise(xoff, yoff), 0, 1, -terrainValues.mountainHeight, terrainValues.mountainHeight);
                xoff += 0.1;
            }
            yoff += 0.1;
        }
        p.background(terrainValues.bgColor);
        p.stroke(terrainValues.lineColor);
        p.strokeWeight(terrainValues.lineWidth);
        p.angleMode(p.DEGREES);
        p.rotateX(terrainValues.rotation);
        p.translate(-w, -h);
        p.fill(terrainValues.terrainColor[0] * 1, terrainValues.terrainColor[1] * 1, terrainValues.terrainColor[2] * 1, (terrainValues.terrainOpacity * 255));
        for (let y = 0; y < rows - 1; y++) {
            p.beginShape(p.TRIANGLE_STRIP);
            for (let x = 0; x < cols; x++) {
                p.vertex(x * scl, y * scl, terrain[x][y]);
                p.vertex(x * scl, (y + 1) * scl, terrain[x][y + 1]);
            }
            p.endShape();
        }
    };

    p.windowResized = () => {
        cnvs = p.createCanvas(w, h, p.WEBGL);
		// updateCanvas();
    };
};

let pSketch = null;

let forceStop = false;

export const initAnimatedTerrain = () => {

    const isInViewport = (elementId) => {
        const element = document.getElementById(elementId);
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
          rect.bottom > 0 &&
          rect.right > 0 &&
          rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
          rect.left < (window.innerWidth || document.documentElement.clientWidth)
        );
      };
      
      function onScroll() {

        const elementId = 'ws-section-2';
        if (isInViewport(elementId)) {
          if(!pSketch){
            if(forceStop) return;
            pSketch = new p5(sketch);
            pSketch.loop();
          }
        } else {
          if(pSketch){
            pSketch.remove();
            pSketch = null;
          }
        }
      }
      
      window.addEventListener('scroll', onScroll);
      
	return true;
}

export const stopAnimatedTerrain = () => {
    forceStop = true;
    if(pSketch){
        pSketch.remove();
        pSketch = null;
    }
}

export const startAnimatedTerrain = () => {
    forceStop = false;
    if(pSketch){
        pSketch = new p5(sketch);
        pSketch.loop();
    }
    return true;
}