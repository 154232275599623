import TimelineCommentsTemplates from "./templates/live-chat/timeline-comments-templates";
import "../../css/website/timeline-live-messages.scss";

const DURATION_OF_COMMENT_DISPLAY = 5; // * in seconds
const DURATION_OF_COMMENT_VISIBILITY = 4000; // * in milliseconds
const CONTAINER_SELECTOR = "#timeline-content-container";
const OFFSET_FROM_SCREEN_BOUND = "1.5em";
const TIMELINE_MESSAGE_LABEL_OFFSET_PX = 5;
const MAX_TIMELINE_MESSAGE_WIDTH = "414px";

const getTimelineMessageMaxWidth = (additionOffset = 0) => {
    return `min(${MAX_TIMELINE_MESSAGE_WIDTH}, calc(100vw - ((${OFFSET_FROM_SCREEN_BOUND} * 2) + ${additionOffset}px)))`;
}

/** 
 * An instance of the class which should be used by default if the timeline is not initialized in the chat widget 
 */
export class ITimelineLiveCommentsManagerAPI {
    isImplementation() { return false; }
    setCurrentTick() { }
    toggleTimelineCommentsVisibility() { }
    processAddingComment() { }
    processRemovingComment() { }
    processUpdatingComment() { }
    recalculatePositions() { }
    setCallbackOnAvatarClick(callback) {}
    onDestroy() { }
}

export class TimelineLiveCommentsManager extends ITimelineLiveCommentsManagerAPI {
    container = null;
    comments = [];
    resizeContainerObserver = null;
    containerWidth = null;

    currentTimerTick = null;
    cleanVisibilityTimeout = null;
    hoveredMessageId = null;

    isImplementation() { return true; }

    setCallbackOnAvatarClick(callback) {
        this.onAvatarClick = callback;
    }

    onAvatarClick = () => { };
    
    setCallbackOnMessageLabelClick(callback) {
        this.onMessageLabelClick = callback;
    }
    
    onMessageLabelClick = () => { };

    setCurrentTick(currentTick) {
        this._onTickChanges(currentTick)
    }

    toggleTimelineCommentsVisibility(value) {
        if (typeof value === "undefined") {
            CesiumManager.toggleTimelineContent();
        } else {
            value ?
                CesiumManager.openTimelineContent() :
                CesiumManager.closeTimelineContent();
        }

        this.setMessageLabelsBottomOffset();
    }

    setMessageLabelsBottomOffset(ids = []) {
        const timelineHeight = CesiumManager.getTimelineHeight() + TIMELINE_MESSAGE_LABEL_OFFSET_PX;

        let selector = ".user-message-wrapper";
        if (Array.isArray(ids) && ids.length) {
            selector = ids.reduce((acc, current, i) => acc.concat([`#timeline-comment-${current} ${selector}`]), []).join(",");
        }

        const messageWrappers = $(selector);

        messageWrappers.css("bottom", timelineHeight + "px");
    }

    init(comments) {
        $(CONTAINER_SELECTOR).empty();
        this.initTimelineCommentsContainer();
        this.fillTimelineComments(comments);
        this.setMessageLabelsBottomOffset();
    }

    initTimelineCommentsContainer() {
        const timelineContentContainer = $(CONTAINER_SELECTOR);
        if (!timelineContentContainer) {
            throw Error("timeline-content-container not found");
        }
        this.container = $(TimelineCommentsTemplates.getContainer());
        this.container.appendTo(timelineContentContainer);

        this.observeContainerResize();
    }

    fillTimelineComments(comments) {
        comments
            .sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
            .forEach((comment) => {
                this.insertComment(comment);
            });
    }

    processAddingComment(comment) {
        this.insertComment(comment);
        this.recalculatePosition(comment);
        this.setMessageLabelsBottomOffset([comment.id]);
    }

    processRemovingComment(id) {
        $(`#timeline-comment-${id}`).remove();

        const indexOfComment = this._getIndexOfCommentData(id);

        if (indexOfComment !== -1) {
            this.comments.splice(indexOfComment, 1);
        }
    }

    processUpdatingComment(id, messageData) {
        const commentElement = $(`#timeline-comment-${id}`);

        const indexOfComment = this._getIndexOfCommentData(id);

        if (indexOfComment !== -1) {
            this.comments[indexOfComment] = messageData;
            $(".message", commentElement).html(messageData.message);
        }
    }

    insertComment(commentData) {
        this.comments.push(commentData);

        const id = commentData.id;
        const element = $(
            TimelineCommentsTemplates.getComment({
                id,
                message: commentData.message,
                user: commentData.user,
                activity_time: moment.utc(commentData.activity_time * 1000).format("HH:mm:ss"),
            })
        );
        this.container.append(element);

        const userMessageLabel = $(`#timeline-comment-${id} .user-message`);

        $(`#timeline-comment-${id} .user-avatar`)
            .on("load", () => {
                // this.recalculatePosition(commentData);
            })
            .on("click", (event) => {
                this.onAvatarClick(event);
                CesiumManager.setTimelinePositionBySeconds(commentData.activity_time);
            });

        this._getCommentElement(id)
            .on("mouseover", () => {
                this.checkForMessageBehindScreen(id);
                this.hoveredMessageId = id;
                
                this.checkMultilineCommentLabel(userMessageLabel);
            })
            .on("mouseout", () => {
                $(`#timeline-comment-${id}:not(.active) .user-message-wrapper`).css("left", "0");
                this.hoveredMessageId = null;
            });

        userMessageLabel
            .on("click", (event) => {
                this.onMessageLabelClick(event);
                userMessageLabel.parent().toggleClass("expanded");
            });
    }

    checkMultilineCommentLabel(messageLabel) {
        if (messageLabel.attr("multiline") === undefined) {
            const labelInnerHeight = messageLabel.innerHeight();
            const contentHeight = $(".message", messageLabel).height();

            messageLabel.attr("multiline", contentHeight > labelInnerHeight);
        }
    }

    recalculatePosition(comment) {
        const element = this._getCommentElement(comment.id);
        let offsetX =  0;

        try {
            offsetX = CesiumManager.getTimelineOffsetBySeconds(comment.activity_time);
        } catch(e) {}

        const currentOffset = element.data("offset");
        if (offsetX != currentOffset) {
            element.data("offset", offsetX);
            element.css("left", offsetX + "px");
            this.checkForAlign(comment.id);
        }
        
        this.checkForMessageBehindScreen(comment.id);
    }

    checkForAlign(id) {
        const element = this._getCommentElement(id);

        if (element) {
            const avalaibleWidth = element.parent().width();
            const avatarWidth = element.width();
            const offsetPx = element.data("offset");
            const difference = avalaibleWidth - (offsetPx + avatarWidth);
            const translateX = difference < 0 ? `${difference}px` : 0;

            element.css("transform", `translateX(${translateX})`);
        }
    }

    recalculatePositions() {
        this.comments.forEach((comment) => {
            this.recalculatePosition(comment);
        });
    }

    observeContainerResize() {
        const observer = new ResizeObserver(() => {
            const currentWidth = $(CONTAINER_SELECTOR).width();
            if (this.containerWidth != currentWidth) {
                this.recalculatePositions();
                this.containerWidth = currentWidth;
            }
        });
        this.resizeContainerObserver = observer;
        observer.observe($(CONTAINER_SELECTOR)[0]);
    }

    _onTickChanges(currentTime) {

        if (this.hoveredMessageId) {
            return;
        }
        let latestComment = null;
        let latestCommentId = null;

        let startTickRange = null;
        let endTickRange = null;
        if (this.currentTimerTick) {
            startTickRange = Math.min(this.currentTimerTick, currentTime);
            endTickRange = Math.max(this.currentTimerTick, currentTime);
        }
        const order = this.currentTimerTick < currentTime ? 'desc' : 'asc';
        const comments = _.orderBy(this.comments, ['activity_time'], [order])
        let activeComment = comments.find(({ activity_time }) => {
            const offset = currentTime - activity_time;
            return offset > 0 && offset < DURATION_OF_COMMENT_DISPLAY;
        });
        activeComment = activeComment || comments.find(({ activity_time }) => this.isCommentInTickRange(activity_time, startTickRange, endTickRange));
        if (activeComment) {
            latestComment = this._getCommentElement(activeComment.id);
            latestCommentId = activeComment.id;
        }
        this.currentTimerTick = currentTime;

        if (latestComment && latestCommentId) {
            this.cancelActiveCommentVisibilityTimeout();

            this.cleanCommentsActivity();

            latestComment.addClass("active");
            this.checkForMessageBehindScreen(latestCommentId);
            this.checkMultilineCommentLabel($(".user-message", latestComment));

            this.setActiveCommentVisibilityTimeout();
        }
    }

    cancelActiveCommentVisibilityTimeout() {
        if (this.cleanVisibilityTimeout) {
            clearTimeout(this.cleanVisibilityTimeout)
            this.cleanVisibilityTimeout = null;
        }
    }
    setActiveCommentVisibilityTimeout() {
        if (!this.cleanVisibilityTimeout) {
            this.cleanVisibilityTimeout = setTimeout(() => {
                this.cleanCommentsActivity();
            }, DURATION_OF_COMMENT_VISIBILITY)
        }
    }

    cleanCommentsActivity() {
        $('.user-comment', $('.timeline-comments-wrapper')).removeClass("active");
        $(`.user-comment:not(:hover) .user-message-wrapper`, $('.timeline-comments-wrapper'))
            .css("left", "0")
            .removeClass("expanded");
    }

    isCommentInTickRange(activity_time, startTickRange, endTickRange) {
        return startTickRange && endTickRange && (activity_time >= startTickRange && activity_time <= endTickRange);
    }

    onDestroy() {
        this.resizeContainerObserver && 
            this.resizeContainerObserver.unobserve($(CONTAINER_SELECTOR)[0]);
        $(CONTAINER_SELECTOR).empty();
    }

    checkForMessageBehindScreen(id) {
        const messageWrapper = $(`#timeline-comment-${id}:not(.active:hover) .user-message-wrapper`);
        if (!messageWrapper || !messageWrapper.length) {
            return;
        }
        const { width, left: leftOffset } = messageWrapper[0].getBoundingClientRect();

        if (leftOffset <= 0) {
            messageWrapper.css("left", `calc(${-leftOffset}px + ${OFFSET_FROM_SCREEN_BOUND})`);

            return;
        }

        const avalaibleWidth = $(".timeline-comments-wrapper").width();
        if ((leftOffset + width) > avalaibleWidth) {
            messageWrapper.css("left", `calc(-${leftOffset + width - avalaibleWidth}px - ${OFFSET_FROM_SCREEN_BOUND}`);
        } else {
            messageWrapper.css("left", "0");
        }

        const liveChatWidgetWidth = (CesiumManager.isOpenedTimelineContent() ? 
            $(".live-chat-wrapper") : 
            $(".live-chat-wrapper .toggle-zone")
        ).width();

        $(".user-message", messageWrapper)
            .css("max-width", getTimelineMessageMaxWidth(liveChatWidgetWidth));
    }

    _getCommentElement(id) {
        return $(`#timeline-comment-${id}`);
    }

    _getIndexOfCommentData(id) {
        let indexOfComment = -1;

        for (let i = 0; i < this.comments.length; i++) {
            if (this.comments[i].id === id) {
                indexOfComment = i;
                break;
            }
        }

        return indexOfComment;
    }
}
