import ListUsers from "../utils/ListUsers";
import ListLocalitites from "../utils/ListLocalitites";

export default class ListLocalities {

    constructor() {
        this.page = 1;
        this.user_id = null;
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
        this.user_id = data.user_id;
        this.loadLocalities();
        this.bindEvents();
    }

    onDestroy() {
        $("#localities").empty();
        $(window).unbind('scroll', this.onScroll);
    }

    onScroll(){
        if ($(window).scrollTop() >= $('#localities').offset().top + $('#localities').outerHeight() - 300 - window.innerHeight) {
            window.application.current_handler.listLocalities.loadLocalities();
        }
    }

    loadLocalities() {
        let self = this;
        $(window).unbind('scroll', self.onScroll);

        $.ajax({
            url: "/user/ajax_localities",
            type: "get",
            data: {
                user_id: this.user_id,
                page: this.page
            },
            success: function(data) {
                let before = $(".localities-item").length;
                ListLocalitites.buildList('localities',data.localities);
                let after = $(".localities-item").length;
                window.application.current_handler.onUpdate();

                if ((before !== after) && ($(".localities-item").length%2 === 0)){
                    self.page++;
                    $(window).on('scroll', self.onScroll);
                }else{
                    $(window).unbind('scroll', self.onScroll);
                }
            },
            error: function(data) {

            }
        });
    }

}