import ListUsers from "../utils/ListUsers";

export default class ListFollowers {

    constructor() {
        this.page = 1;
        this.user_id = null;
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
        this.user_id = data.user_id;
        this.loadFollowers();
        this.bindEvents();
    }

    onDestroy() {
        $("#followers").empty();
        $(window).unbind('scroll', this.onScroll);
    }

    onScroll(){
        if ($(window).scrollTop() >= $('#followers').offset().top + $('#followers').outerHeight() - 300 - window.innerHeight) {
            window.application.current_handler.listFollowers.loadFollowers();
        }
    }

    loadFollowers() {
        let self = this;
        $(window).unbind('scroll', self.onScroll);

        $.ajax({
            url: "/user/ajax_followers",
            type: "get",
            data: {
                user_id: this.user_id,
                page: this.page
            },
            success: function(data) {
                let before = $(".followers-item").length;
                ListUsers.buildList('followers',data.users);
                let after = $(".followers-item").length;
                window.application.current_handler.onUpdate();

                if ((before !== after) && ($(".followers-item").length%2 === 0)){
                    self.page++;
                    $(window).on('scroll', self.onScroll);
                }else{
                    $(window).unbind('scroll', self.onScroll);
                }
            },
            error: function(data) {

            }
        });
    }
}