export default class UploadFileTemplates {
     static new_track_file(name, code_name, selectors) {
        let parametarized = code_name;
       
        const html = [
                '<div id="'+parametarized+'" class="col-12 mb-2">',
                    '<div class="bg-light px-3 py-2 rounded">',
                        '<div class="row">',
                            '<div class="col-5">',
                                '<span class="fs-12 font-weight-bolder">'+name+'</span>',
                            '</div>',
                            '<div class="col-6">',
                            '<div id="'+ selectors.indeterminateProgressUploadTemplate.substring(1)+ parametarized+'" class="progress-indeterminate cant_delete">',
                                '<div class="indeterminate bg-warning"></div>',
                            '</div>',
                            '<div id="'+ selectors.progressUploadTemplate.substring(1)+ parametarized+'" class="progress d-none cant_delete" style="height: 4px;margin: 0.6rem 0 0.5rem 0;">',
                                '<div class="progress-bar bg-secondary" role="progressbar"></div>',
                            '</div>',
                            '<div class="progress can_be_deleted bg-success " style="height: 4px;margin: 0.6rem 0 0.5rem 0;">',
                                '<div class="progress-bar" role="progressbar"></div>',
                            '</div>',
                            '</div>',                          
                            '<div class="col-1">' +
                                '<div class="text-right">' +
                                    '<a href="javascript:void(0)" id="'+selectors.deleteFileTemplate.substring(1)+parametarized+'" data-element-id="'+parametarized+'" data-track-id="" data-name="'+name+'" class="text-danger cursor-pointer"><i class="mdi mdi-close"></i> </a>' +
                                '</div>' +                                
                            '</div>',
                        '</div>',
                    '</div>',
                '</div>'
            ].join("\n");
       

        return html;
    }
}