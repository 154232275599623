export class PopupTemplates {
    static getPopup(data) {
        const knownLocalityNameTitle = data.track_is_live ? I18n.t('website.home.index.live_map.known_location') : I18n.t('website.home.index.live_map.ended');

        let html =
            `<div class="marker-popup-js container-fluid ff-lato" style="min-width: 250px;" data-id="${data.track_id}">` +
            '   <div class="row">' +
            '       <div class="col-12 px-1">' +
            '           <span class="dot bg-secondary mr-2">' +
            `               <i class="fs-18 lh-5 text-white icon icon-${data.category_icon}"></i>` +
            '           </span>' +
            `           <a href="${data.user_url}" target="_blank" class="text-secondary fs-14">${data.user_name}</a>` +
            '       </div>' +
            '   </div>' +
            '   <div class="row mt-1">' +
            '   <div class="col-8 pl-2 pr-0">' +
            `       <span>${I18n.t('website.home.index.live_map.start')}:</span>` +
            `       <a href="${data.locality_url}" target="_blank" class="pl-1 text-secondary fs-14">${data.locality_name}</a>`;

        if (data.known_locality_name) {
            html +=                
                '       <br/ >' +
                `       <span>${knownLocalityNameTitle}:</span>` +
                `       <a href="${data.google_url}" target="_blank" class="pl-1 text-secondary fs-14">${data.known_locality_name}</a>`;
        }

        html += '   </div>';

        if (data.track_is_live) {
            html +=
                '   <div class="col-2 px-0 d-flex align-datas-center">' +
                `       <label class="mb-0 toggle_live" data-uid="${data.user_id}" data-tid="${data.track_id}" data-live="${data.track_is_live}">` +
                '           <div class="material-switch pull-right d-inline ml-2">\n' +
                `               <input class="${data.track_id}-switch-tooltip" type="checkbox">\n` +
                '               <label class="bg-secondary mb-1"></label>\n' +
                '           </div>' +
                '       </label>' +                
                '   </div>' +
                '   <div class="col-2 px-0">' +
                `       <a href="${data.track_url}" target="_blank" class="px-1 py-2 lh-2 btn btn-sm btn-danger text-white pull-right">3D</a>` +
                '   </div>' +
                '</div>';
        } else {
            html +=
                '   <div class="col-4 px-0 text-right">' +
                `       <a href="${data.track_url}" target="_blank" class="px-1 lh-2 btn btn-sm btn-outline-secondary mr-1"><i class="icon-Distance fs-22"></i></a>` +
                `       <a href="${data.google_url}" target="_blank" class="px-1 lh-2 btn btn-sm btn-outline-secondary mr-1"><i class="icon-navigation-map fs-22"></i></a></div>` +
                '   </div>' +
                '</div>';
        }

        return html;
    }

    static getPlaceholderPopup() {
        return (
            '<div  id="home-standings-loader" class="d-flex justify-content-center align-items-center" style="width: 250px">' +
            '   <div class="spinner-grow" style="width: 4rem; height: 4rem;" role="status">' +
            '       <span class="sr-only">Loading...</span>' +
            '   </div>' +
            '</div>');
    }

    static getLivePopup(data, speedUnit, alternateDistanceUnit) {
        return (
            '<div class="container-fluid" style="min-width: 250px;">' +
            '   <div class="row">' +
            '       <div class="col-12 px-1">' +
            `           <span class="dot bg-secondary mr-2"><i class="fs-18 lh-5 text-white icon icon-${data.category_icon}"></i></span>` +
            `           <a href="${data.user_url}" target="_blank" class="text-secondary fs-14">${data.user_name}</a>` +
            '       </div>' +
            '   </div>' +
            '   <div class="row mt-1">' +
            '       <div class="col-8 pl-2 pr-0">' +
            `           <span class="mr-1 flag-icon flag-icon-${data.locality_country_code}"></span>` +
            `           <a href="${data.locality_url}" target="_blank" class="text-secondary fs-14">${data.locality_name}</a>` +
            '       </div>' +
            '   <div class="col-4 px-0 d-flex align-items-center">' +
            `       <label class="mb-0 toggle_live" data-uid="${data.user_id}" data-tid="${data.track_id}" data-live="${data.track_is_live}">` +
            '           <div class="material-switch pull-right d-inline ml-2">\n' +
            `               <input class="${data.track_id}-switch-tooltip" type="checkbox" checked>\n` +
            '               <label class="bg-secondary mb-1"></label>\n' +
            '           </div>' +
            '       </label>' +
            `       <a href="${data.track_url}" id="tooltip_live_2d_${data.track_id}" target="_blank" class="px-1 py-2 lh-2 btn btn-sm btn-danger text-white">3D</a>` +
            `       <div id="tooltip_live_finish_${data.track_id}" class="d-none text-center fs-24 text-primary" 
                        data-toggle="tooltip" data-placement="right" title="${I18n.t('website.home.index.live_map.live_ended')}">` +
            '           <span class="mdi mdi-flag-variant btn-outline-secondary"></span>' +
            '       </div>' +
            '   </div>' +
            '</div>' +
            '<div class="row mt-0">' +
            `<div class="col-6 pl-0 border-right border-lighter-gray" data-toggle="tooltip" data-placement="bottom" title="${I18n.t('website.home.index.live_map.speed')}">` +
            '   <i class="icon-vitesse fs-30 text-secondary mr-2"></i>' +
            '   <span class="h-100 d-inline-block align-middle fs-14">' +
            `       <span id="${data.track_id}-current-speed">${I18n.toNumber(0, { precision: 1 })}</span>` +
            `       <span class="fs-12">${speedUnit}</span>` +
            '   </span>' +
            '</div>' +
            `<div class="col-6 pr-0" data-toggle="tooltip" data-placement="bottom" title="${I18n.t('website.home.index.live_map.height')}">` +
            '   <span class="icon icon-altitude fs-30 text-secondary mr-1"></span>' +
            '   <span class="h-100 d-inline-block align-middle fs-14">' +
            `       <span id="${data.track_id}-current-altitude">0</span>` +
            `       <span class="fs-12">${alternateDistanceUnit}</span>` +
            '   </span>' +
            '</div>' +
            '</div>' +
            '</div>'
        );
    }

    static getTrackerPopup(data, isLive) {
        return (
            '<div class="container-fluid ff-lato" style="min-width: 250px;">' +
            '   <div class="row">' +
            '       <div class="col-12 px-1">' +
            `           <a href="${data.user_url}" target="_blank" class="text-secondary fs-14">${data.user_name}</a>` +
            '       </div>' +
            '   </div>' +
            '   <div class="row mt-1">' +
            '       <div class="col-8 pl-2 pr-0 d-flex align-items-center">' +
            `           <span>${I18n.t('website.home.index.live_map.known_location')}:</span>` +
            `           <a href="${data.google_url}" target="_blank" class="pl-1 text-secondary fs-14">${data.known_locality_name}</a>` +
            '       </div>' +
            '   <div class="col-4 px-0 d-flex align-items-center">' +
            `       <label class="mb-0 toggle_live" data-uid="${data.user_id}" data-tid="${data.tracker_activity_id}" data-live="true">` +
            '           <div class="material-switch pull-right d-inline ml-2">' +
            `               <input class="${data.tracker_activity_id}-switch-tooltip" type="checkbox" ${(isLive ? " checked" : "")}>` +
            `               <label class="bg-${(data.class_name === "Track" ? "danger" : "warning")} mb-1"></label>` +
            "           </div>" +
            "       </label>" +
            `       <a href="${data.google_url}" target="_blank" class="px-1 lh-2 btn btn-sm btn-outline-secondary mr-1">` +
            '           <i class="icon-navigation-map fs-22"></i>' +
            '       </a>' +
            "     </div>" +
            "   </div>" +
            " </div>" +
            "</div>"
        );
    }
}