export default class CustomCalendar {

    constructor() {
        var self = this;
        this.today = new Date();
        this.tbl = document.getElementById("calendar-body");
        this.monthAndYear = document.getElementById("monthAndYear");
        this.eventsBlock = document.getElementById("events_block");
        this.eventDaysAjaxRoute = document.getElementById("event_days_ajax_route").value;
        this.eventsAjaxRoute = document.getElementById("events_ajax_route").value;
        this.userId = document.getElementById("calendar_user_id").value;
        this.currentMonth = this.today.getMonth();
        this.currentYear = this.today.getFullYear();
        this.oldElement = null;
        this.selectYear = document.getElementById("year");
        this.selectMonth = document.getElementById("month");
        $(this.selectMonth).on("change", function() {
            self.jump();
        });
        this.btn_previous = document.getElementById("previous");
        $(this.btn_previous).on("click", function() {
            self.previous();
        });
        this.btn_next = document.getElementById("next");
        $(this.btn_next).on("click", function() {
            self.next();
        });
        this.months = I18n.t("date.abbr_month_names").slice(1, 13) ;

        this.bindEvents();
    }

    bindEvents(){
        let self = this;

        $(".js-calendar-year").unbind("click").on("click", function (e) {
            e.preventDefault();
            if($("#year").val() !== $(this).data("year")){
                $("#year").val($(this).data("year"));
                $("#dropdownYearSelect").text($(this).data("year"));
                self.jump();
            }

        })

    }

    onDataChanged(data){
        this.buildCalendar(this.currentMonth, this.currentYear);
    }

    next() {
        let now = new Date();
        var self = this;
        if(this.currentYear <= now.getFullYear() && !(this.currentMonth === now.getMonth() && this.currentYear === now.getFullYear())){
            $("#events_block").empty();
            this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
            this.currentMonth = (this.currentMonth + 1) % 12;
            this.buildCalendar(this.currentMonth, this.currentYear);
            self.showEvents(); 
        }
    }

    previous() {
        var self = this;
        if(this.currentYear >= 2007 && !(this.currentMonth === 0 && this.currentYear === 2007)){
            $("#events_block").empty();
            this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
            this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
            this.buildCalendar(this.currentMonth, this.currentYear);
            self.showEvents();
        }
    }


    jump(day = null) {
        this.selectedDay = day;
        $("#events_block").empty();
        this.currentYear = parseInt(this.selectYear.value);
        this.currentMonth = parseInt(this.selectMonth.value);
        this.buildCalendar(this.currentMonth, this.currentYear);
    }


    buildCalendar(month, year){
        let self = this;
        $(this.tbl).empty();
        $(this.tbl).append('<tr><td class="text-center" style="height: 200px; vertical-align: middle" colspan="7"><img height="50" src="'+window.application.getMeta('loader_svg')+'"></td></tr>');

        let requestData = {
            user_id: this.userId,
            month: month + 1,
            year: year
        };
    
        if (application.host_used == "birdtracking") {
            requestData.filter_scientific = true;
        }

        $.ajax({
            url:  this.eventDaysAjaxRoute,
            type: "get",
            data: requestData,
            success: function(data) {
                self.showCalendar(month, year, data);
            },
            error: function(data) {
                self.showCalendar(month, year, []);
            }
        });
        self.showEvents();
    }


    showCalendar(month, year, ajaxResponse) {
        var self = this;

        let firstDay = (new Date(year, month)).getDay()-1;
        if (firstDay === -1){
            firstDay = 6;
        }
        let lastYear = year;
        let lastMonth = month-1;
        if (lastMonth === -1){
            lastMonth = 12;
            lastYear = year-1;
        }

        let daysInLastMonth = 32 - new Date(lastYear, lastMonth, 32).getDate();
        let daysInMonth = 32 - new Date(year, month, 32).getDate();

        // clearing all previous cells
        this.tbl.innerHTML = "";

        // filing data about month and in the page via DOM.
        this.monthAndYear.innerHTML = this.months[month];
        this.selectYear.value = year;
        $("#dropdownYearSelect").text(year);
        this.selectMonth.value = month;

        // creating all cells
        let date = 1;
        let nextdays = 1;
        let lastDate = daysInLastMonth - firstDay+1;

        for (let i = 0; i < 6; i++) {
            // creates a table row
            let row = document.createElement("tr");

            //creating individual cells, filing them up with data.
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDay) {
                    let cell = document.createElement("td");
                    let cellContainer = document.createElement("div");
                    let cellText = document.createTextNode(lastDate);
                    cell.classList.add("text-center");
                    cell.classList.add("text-lighter-gray");
                    cellContainer.appendChild(cellText);
                    cell.appendChild(cellContainer);
                    row.appendChild(cell);
                    lastDate++;
                } else if (date > daysInMonth) {
                    let cell = document.createElement("td");
                    let cellContainer = document.createElement("div");
                    let cellText = document.createTextNode(nextdays);
                    cell.classList.add("text-center");
                    cell.classList.add("text-lighter-gray");
                    cellContainer.appendChild(cellText);
                    cell.appendChild(cellContainer);
                    row.appendChild(cell);
                    nextdays++;
                } else {
                    let cell = document.createElement("td");
                    let cellContainer = document.createElement("div");
                    let cellText = document.createTextNode(date);

                    if (date === this.today.getDate() && year === this.today.getFullYear() && month === this.today.getMonth()) {
                        cellContainer.classList.add("bg-light");
                        cell.dataset.today = "true"
                    }else if(date > this.today.getDate() && year === this.today.getFullYear() && month === this.today.getMonth()) {
                        cellContainer.classList.add("text-light-gray");
                        cell.dataset.future = "true"
                    }else{
                        cell.dataset.today = "false"
                    }

                    if (ajaxResponse.length > 0){
                        if (ajaxResponse[0] === date){
                            cellContainer.classList.add("active-dot");
                            ajaxResponse.splice(0, 1);
                        }
                    }

                    cell.classList.add("text-center");
                    $(cell).attr("id","calendar-day-"+date)
                    if(cell.dataset.future !== "true") {
                        cellContainer.classList.add("rounded-circle");
                    }
                    cellContainer.appendChild(cellText);
                    cell.appendChild(cellContainer);
                    row.appendChild(cell);
                    if(cell.dataset.future !== "true"){
                        $(cell).on("click", function() {
                            self.showEvents(this);
                        });
                    }
                    date++;
                }


            }

            this.tbl.appendChild(row); // appending each row into calendar body.

            if (date > daysInMonth) {
                break;
            }
        }

        if(this.selectedDay !== null){
            $("#calendar-day-"+this.selectedDay).click();
        }
    }


    showEvents(element = null){
        let self = this;

        if (this.oldElement !== null) {
            $(this.oldElement).find("div")
                .removeClass("bg-secondary").removeClass("text-white");
            if ($(this.oldElement).data("today") === true){
                $(this.oldElement).find("div")
                    .addClass("bg-light");
            }
        }

        this.oldElement = element;

        $("#events_block").empty();
        $("#events_block").append('<div class="col-12 text-center fs-18 pt-2"><img height="50" src="'+window.application.getMeta('loader_svg')+'"></div>');

        $(element).find("div")
            .removeClass("bg-light")
            .addClass("bg-secondary").addClass("text-white");

        let requestData = {
            user_id: this.userId,
            day: element !== null ? $(element).find("div").text() : null,
            month: this.currentMonth+1,
            year: this.currentYear
        };
    
        if (application.host_used == "birdtracking") {
            requestData.filter_scientific = true;
        }
    
        $.ajax({
            url:  this.eventsAjaxRoute,
            type: "GET",
            data: requestData,
            success: function(data) {
                $("#events_block").empty();
                if(data.length > 0){
                    $("#events_block").append('<div class="col-12"><span class="btn btn-light d-block text-center p-0 text-gray fs-14">' + data.length + ' ' + I18n.t("website.user.follow.activities").toLowerCase()    + '</span></div>');
                    data.forEach(function (item) {
                        $("#events_block").append(self.generateEvent(item));
                    });
                }else{
                    $("#events_block").append('<div class="col-12 text-center fs-16 pt-2">'+ I18n.t("website.user.show.side_bar.no_activity") +'</div>');
                }
            },
            error: function(data) {
                $("#events_block").empty();
                $("#events_block").append('<div class="col-12 text-center fs-16 pt-2">'+ I18n.t("website.user.show.side_bar.response_error") +'</div>');
            }
        });
    }

    generateEvent(item) {
        var html = [
            '<div class="col-12 mt-2">',
                '<a href="'+ item.url +'" class="btn btn-light d-block text-left p-0">',
                    '<div class="row">',
                        '<div class="col-6"><span class="fs-11 p-2">' + item.date + '</span></div>',
                        '<div class="col-6 text-right"><span class="fs-11 text-light-gray pt-2 pr-1">'+ item.duration +'</span></div>',
                    '</div>',                
                    '<div class="media pl-1 pb-1">',
                        '<span class="bg-danger dot-md mt-2">',
                            '<span class="icon text-white icon-'+ item.icon +' lh-5 fs-18"></span>',
                        '</span>',
                        '<div class="media-body">',
                            '<h5 class="mt-1 py-1 pl-2 ff-lato fs-14">',
                            '<div class="lh-3 pt-2 media"><span class="media-body text-gray fs-14 pr-1 lh-5">'+ item.spot +'</span></div>', 
                            '</h5>',
                        '</div>',
                    '</div>',
                    '<div class="row">',
                        '<div class="col-12 text-right"><span class="fs-11 pr-1">' + item.distance + '</span></div>',
                    '</div>', 
                '</a>',
            '</div>'
        ].join("\n");

        return html;
    }

    onDestroy(){
        $(this.selectYear).off("change");
        $(this.selectMonth).off("change");
        $(this.btn_previous).off("click");
        $(this.btn_next).off("click");
        $(".js-calendar-year").unbind("click");
    }
}