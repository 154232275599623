import SceneMap3DHandler from "../utils/SceneMap3DHandler.js"
import LiveChatWidget from "../utils/LiveChatWidget"
export default class SceneShow {

  constructor() {
    this.first_appear_ad = 100;
    this.frequency_ad = 100;
    this.bindEvents();
    this.liveChat = new LiveChatWidget();
  }

  bindEvents() {
    window.application.setOnDataChangeListener(this);
  }

 async  onDataChanged(data) {
    if (data !== undefined){
      this.mode = data.mode;

      this.first_appear_ad = data.first_appear_ad;
      this.frequency_ad = data.frequency_ad;

      // this.scene_id = data.scene_id;

      // this.track = data.track;
      // this.points = data.points;

      if(this.mode=="2D") {
        //TODO
      }
      else {
        if(data.track_ids !== null && data.track_ids !== undefined){
          this.handler = new SceneMap3DHandler(this.first_appear_ad,this.frequency_ad);
          await this.handler.init(data);  
          await this.liveChat.initTimelineComments();

            this.handler.onTickChanges((currentTime) => {
                this.currentTime = currentTime;
                this.liveChat.setCurrentTick(currentTime);
            });
            this.liveChat.subscribeToPlayerData();      
        }
      }
    }

  }

  onDestroy() {
    this.liveChat.destroy();
  }
}