import CardManager from "../utils/CardManager";
import ToastNotification from "../utils/ToastNotification";

export default class InvoiceShow {

    constructor() {
        this.cardManager = new CardManager();
        this.clientSecret = "";
        this.stripe = null;

        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        $("#invoice_pay_btn").unbind("click").click(function (e) {
            e.preventDefault();
            self.checkPayment();
        })
    }

    onDataChanged(data) {
        this.cardManager.onDataChanged(data);
        this.stripe = Stripe(data.stripe_public_key);
        this.clientSecret = data.clientSecret;
    }

    onDestroy() {
    }

    checkPayment() {
        let self = this;
        $('#invoice_pay_btn').html('<img height="20" src="'+ $('#invoice_pay_btn').data('loader') +'"> Payment en cours');
        this.stripe.confirmCardPayment(this.clientSecret, {payment_method: $("input[name=payment_method]:checked").val()}).then(function(result) {
            if (result.error) {
                self.paymentErrors(result.error.message);
            } else {
                window.location.href = "/checkout/payment_ok"
            }
        });
    }

    paymentErrors(message) {
        $('#invoice_pay_btn').html($('#invoice_pay_btn').data('text'));
        ToastNotification.make(message)
    }
}