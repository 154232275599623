export default class ListLocalitites {

    static buildList(list_id,items){
        items.forEach(function (item, index) {
            $('#'+list_id).append(ListLocalitites.userItem(list_id,item, index%2 === 0));
        })
    }

    static userItem(list_id, item, is_pair){
        return '<div class="row py-2 '+list_id+'-item '+(is_pair ? 'bg-white' : '' )+'">\n' +
            '              <div class="col-5">\n' +
            '                    <span class=" rounded flag-icon flag-icon-fr fs-14 mr-3"></span>\n' +
            '                    <a class="text-secondary fs-14" href="'+item.url+'">'+item.name+'</a>\n' +
            '                    <div class="text-light-gray fs-14"><span class="js-subscribers" data-id="'+item.id+'" data-subscribers="'+item.followers_count+'">'+item.followers_count+'</span> '+I18n.t("website.user.show.side_bar.followers")+'</div>\n' +
            '              </div>\n' +
            '              <div class="col-4 d-flex align-items-center">\n' +
            '                <div class="text-center w-100 fs-14">'+item.track_count+' '+I18n.t("website.user.follow.activities")+'</div>\n' +
            '              </div>\n' +
            '              <div class="col-3 d-flex align-items-center">\n' +
            '                <div class="w-100">\n'+
            '                    <a href="" data-id="'+item.id+'" class="js-unsubscribe btn btn-outline-secondary border w-100 lh-3 '+(item.is_followed ? "" : "d-none")+'"><i class="mdi mdi-check mr-2"></i> '+I18n.t("website.user.show.side_bar.subscribed")+'</a>\n' +
            '                    <a href="" data-id="'+item.id+'" class="js-subscribe btn btn-secondary border w-100 lh-3 '+(item.is_followed ? "d-none" : "")+'">'+I18n.t("website.user.show.side_bar.subscribe")+'</a>\n'+
            '                </div>\n' +
            '              </div>\n' +
            '            </div>';
    }

}