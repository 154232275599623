import ToastNotification from "../utils/ToastNotification";
import AskPremiumModal from "../utils/AskPremiumModal";
import BrowserNavigation from "../utils/browser_navigation";
import * as select2 from "select2";

export default class TrackUpload {

    constructor() {
        $(document).ready(()=> {
            this.initData();
            this.heartbeatInterval = null; 
        });
    }
    initData() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.dropArea = document.getElementById('drop_hexagon');
        this.available_file_types = ["application/gpx+xml","application/octet-stream", "application/x-binary", "application/json"];
        this.available_file_ext = ["xctsk", "XCTSK", "json","JSON","gpx","GPX","igc","IGC", "sbp", "SBP", "txt", "fit", "FIT"];
        this.current_file_names = [];
        this.current_track_ids = [];
        this.uploadingFiles = [];
        this.creditsTiers = [];

        this.counter_draggover = 0;
        this.file_count = 0;

        let self = this;

        if(this.dropArea!=null) {
            this.bindEvents();
        }

        $("#track_activity").on("change",function () {
            $('#track_tags').val(null).trigger('change');
            $('#track_stuff').val(null).trigger('change');
            self.setTagsAndStuff();
        });

        if (window.application.host_used == "birdtracking") {    
            window.application.loadCreditTiers().then(data => {
                this.creditsTiers = data;
                this.bindEvents();
            });
        }

        window.$('.js-select2-multiple-features').select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tags: true,
            tokenSeparators: [",", ';'],
            ajax:{
                delay: 250,
                url: window.application.getAPIUrl()+"/v1/users/current_features",
                data: function (params) {
                    let requestData = {
                        search: params.term,
                        category_id: $("#track_activity").val(),
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function(obj) {
                            return {
                                "id": obj.text,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });

        window.$('.js-select2-multiple-tags').select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tags: true,
            tokenSeparators: [",", ';'],
            ajax:{
                delay: 250,
                url:  window.application.getAPIUrl()+"/v1/users/current_tags",
                data: function (params) {
                    let requestData = {
                        search: params.term,
                        category_id: $("#track_activity").val(),
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function(obj) {
                            return {
                                "id": obj.text,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });

        this.setTagsAndStuff();
    }

    bindEvents(){
        let self = this;

        if (window.application.host_used == "birdtracking") {  
            const slider = document.getElementById("rs-range-line");
            const output = document.getElementById("rs-bullet");
            const totalSimulator = document.getElementById("total_simulator");

            const updateSliderValue = () => window.application.updateSliderValue(slider, output, totalSimulator, this.creditsTiers);
            updateSliderValue();

            slider.addEventListener("input", updateSliderValue);
        }

        $(this.dropArea).bind({
            dragenter: function(ev) {
                ev.preventDefault(); // needed for IE
                self.counter_draggover++;
                $(this).addClass('drag_in');
            },

            dragleave: function() {
                self.counter_draggover--;
                if (self.counter_draggover === 0) {
                    $(this).removeClass('drag_in');
                }
            }
        });

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            self.dropArea.addEventListener(eventName, self.preventDefaults, false)
        });

        this.dropArea.addEventListener('drop', function (event) {
            let el = this;
            $(this).removeClass("drag_in");
            $(this).addClass('dropped');
            setTimeout(function() {
                $(el).removeClass('dropped');
            }, 1500);
            self.handleDrop(event);
        }, false);

        $("#track_file").change(function () {self.handleFiles(this.files);});

        $("#credit_simulator").click(function () {
            $(this).hide();
            $("#simulator_modal").modal({
                 backdrop: true,
                show: true
            });
        });

        $('#simulator_modal').on('hidden.bs.modal', function () {
            $("#credit_simulator").show();
        });

        $("#create_activity").on("click", function (event) {
            event.preventDefault();
            $(this).prop("disabled", true);
            self.send_data();
        });

        $("#cancel_activity").click(function () {
            let bearerToken = window.new_upload_bearer || null;
            if (self.current_track_ids.length > 0 && bearerToken !== null) {
                $.ajax({
                    url: window.application.getAPIUrl() + "/v1/users/cancel_pending_tracks",
                    type: "POST",
                    data: JSON.stringify({ 
                        track_ids: self.current_track_ids 
                    }),
                    contentType: "application/json; charset=utf-8",
                    beforeSend: function(request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                    success: function(response) {
                        console.log("Tracks successfully cancelled");
                        BrowserNavigation.redirectToPrevious();
                    },
                    error: function(xhr, status, error) {
                        console.error("Error while trying to cancel Tracks: ", error);                        
                        BrowserNavigation.redirectToPrevious();
                    }
                });
            } else {             
                BrowserNavigation.redirectToPrevious();
            }            
        })
    }

    handleDrop(e) {
        let dt = e.dataTransfer;
        let files = dt.files;
        this.handleFiles(files);
    }
    
    handleFiles(files) {
        $("#create_activity").prop("disabled", true);
        $("#create_activity").removeClass("btn-secondary");
        $("#create_activity").addClass("btn-outline-secondary");
        let self = this;
        $.each(files,function(idx,elm){
            if(!self.current_file_names.includes(elm.name)
                && (self.available_file_types.includes(elm.type) || self.available_file_ext.includes(elm.name.split('.')[elm.name.split('.').length-1]))
            ){
                let code_name = self.makeId(5);
                self.check_file(elm, code_name, function(_elm_, _code_name_, is_valid) {
                    if (is_valid) {
                        if(window.application.checkTrackUploadLimit(self.file_count)){
                            self.previewFile(elm,code_name, true);
                            self.uploadFile(elm, code_name);
                            self.file_count++;
                            self.updateCounterFile();
                        } else {
                            $("#track_premium").addClass("focus");
                            setTimeout(() => {
                                $("#track_premium").removeClass("focus");
                            }, 2000);
                            $("#track_counter_element").addClass("text-danger");
                        }
                    }
                    else {
                        //Invalid file format
                        self.previewFile(elm,code_name, false);
                    }
                });

            } else {
                if(!self.current_file_names.includes(elm.name)){
                    // $("#file_track_format").addClass("text-danger").addClass("font-weight-bolder")
                }
            }
        });
        $("#track_file").val("");
    }

    preventDefaults (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    previewFile(file, code_name, isValid) {
        let self = this;
        $("#track_file_list").append(this.new_track_file(file.name, file.size, code_name, isValid));
        $("html, body").animate( {scrollTop:$('#' + code_name).position().top - 70}, 500);
        $("#delete_track_"+code_name).on("click",function () {
            self.destroy_track(this, code_name, isValid);
        })
        if(isValid) {
            this.extract_file_start_date(file, code_name);
            window.$('[data-toggle="tooltip"]').tlp();
        }
    }

    new_track_file(name, fileSizeInBytes, code_name, is_valid){
        if (I18n.t("website.checkout.index.credits") === "website.checkout.index.credits") {
            console.log("We got issue to load Translations");
        } 
        
        let parametarized = code_name;
        var html = []
        if(is_valid) {
            html = [
                '<div id="'+parametarized+'" class="col-12 mb-2">',
                    '<div class="bg-light px-3 py-2 rounded">',
                        '<div class="row">',
                            '<div class="col-3">',
                                '<span class="fs-12 font-weight-bolder">'+name+'</span>',
                            '</div>',
                            '<div class="col-2">',
                                '<span class="fs-12" id="start_date_'+parametarized+'"></span>',
                            '</div>',
                            '<div class="col-5 pl-0">',
                                '<div id="progress_indeterminate_'+parametarized+'" class="progress-indeterminate cant_delete">',
                                    '<div class="indeterminate bg-warning"></div>',
                                '</div>',
                                '<div id="progress_'+parametarized+'" class="progress d-none cant_delete" style="height: 4px;margin: 0.8rem 0 0.5rem 0;">',
                                    '<div class="progress-bar bg-secondary" role="progressbar"></div>',
                                '</div>',
                                '<div class="progress can_be_deleted progress-bar-success" style="height: 4px;margin: 0.8rem 0 0.5rem 0;">',
                                    '<div class="progress-bar" role="progressbar"></div>',
                                '</div>',
                            '</div>'
                    ];
        
            if (window.application.host_used == "birdtracking") {    
                html.push(...[                                                        
                            '<div class="col-1 px-0 pr-1 font-weight-bolder text-right">',
                                '<span class="fs-12" id="progress_credits_'+parametarized+ '"></span>&nbsp;<span class="fs-12">' + I18n.t("website.checkout.index.credits") + '</span>',                            
                            '</div>',  
                            '<div class="col-1 px-0 pr-1 text-right">',                      
                ]);
            } else {
                html.push(...[
                            '<div class="col-2 px-0 pr-1 text-right">',                      
                ]);
            }
                
            html.push(...[                            
                                '<span class="fs-12">' +  (fileSizeInBytes / (1024 * 1024)).toFixed(2) + '&nbsp;' + I18n.t("number.human.storage_units.units.mb") + ' ' + 
                                    '<a href="javascript:void(0)" id="delete_track_'+parametarized+'" data-element-id="'+parametarized+'" data-track-id="" data-name="'+name+'" class="ml-2 text-danger cursor-pointer"><i class="mdi mdi-close"></i></a>',
                                '</span>',
                                '<span class="error_text">Error</span>',
                            '</div>',
                        '</div>',
                    '</div>',
                '</div>'
            ]);        
            
            html = html.join("\n");
        }
        else {
            html = [
                '<div id="'+parametarized+'" class="col-12 mb-2">',
                    '<div class="bg-light px-3 py-2 rounded">',
                        '<div class="row">',
                            '<div class="col-4">',
                                '<span class="fs-12 font-weight-bolder">'+name+'</span>',
                            '</div>',
                            '<div class="col-7">',
                                '<span class="fs-12 text-danger">'+I18n.t("website.layout.modals.add_activity.invalid_file_format")+'</span>',
                            '</div>' +
                            '<div class="col-1">' +
                                '<span class="text-right">' +
                                    '<a href="javascript:void(0)" id="delete_track_'+parametarized+'" data-element-id="'+parametarized+'" data-track-id="" data-name="'+name+'" class="text-danger cursor-pointer"><i class="mdi mdi-close"></i> </a>' +
                                '</span>' +
                                '<span class="error_text">Error while trying to remove tracks</span>',
                            '</div>',
                        '</div>',
                    '</div>',
                '</div>'
            ].join("\n"); 
        }

        return html;
    }

    uploadFile(file, code_name) {
        let self = this;
        let fileSizeInBytes = file.size;
        let bearerToken = window.new_upload_bearer || null;

        $.ajax({
            url: window.application.getAPIUrl() + "/v1/users/prepare_track_upload",
            type: "GET",
            data: { 
                type: "track",
                file_size: fileSizeInBytes,
                new_upload_bearer: bearerToken 
             },
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token")); 
            },
            success: function(response) {
                if (response.success) {
                    let formData = new FormData();
                    formData.append('imported_file', file);
                    formData.append('code_name', code_name);
                    formData.append('track_id', response.track_id);

                    if (bearerToken) {
                        self.startHeartbeat();  
                        console.log("Upload allowed. Required Credits: " + response.required_credits + ". User's balance: " + response.estimated_balance);
                    }
    
                    self.uploadingFiles.push({
                        code_name: code_name, 
                        ajax_call: $.ajax({
                            url: "/track/ajax_upload_track",
                            type: "post",
                            data: formData,
                            cache: false,
                            processData: false,
                            contentType: false,
                            enctype: 'multipart/form-data',
                            xhr: function () {
                                let myXhr = $.ajaxSettings.xhr();
                                if (myXhr.upload) {
                                    myXhr.upload.addEventListener('progress', function (e) {
                                        self.updateProgress(e, code_name);
                                    }, false);
                                }
                                return myXhr;
                            },
                            success: function (data) {
                                $("#progress_credits_" + code_name).text(response.required_credits); 
                                $("#" + code_name).addClass("can_delete");
                                $("#delete_track_" + code_name).data("track-id", data['track_id']);

                                self.current_track_ids.push(data['track_id']);
                                self.current_file_names.push(file.name);
                                self.uploadingFiles = self.uploadingFiles.filter(upF => upF.code_name !== code_name);
    
                                if($("#user_balance").length) {
                                    let user_balance = parseInt(response.estimated_balance);
                                    if (!isNaN(user_balance)) {                                        
                                        $("#user_balance").attr("balance", user_balance);
                                        $("#user_balance").empty().append(user_balance);
                                    }
                                }

                                if (self.uploadingFiles.length === 0) {
                                    $("html, body").animate({ scrollTop: $('#create_activity').position().top - 70 }, 500);
                                    $("#create_activity").prop("disabled", false);
                                    $("#create_activity").addClass("btn-secondary").removeClass("btn-outline-secondary").addClass("blink_helper");
                                }
                            },
                            error: function () {
                                console.error("Error while trying to upload file.");
                            }
                        })
                    });
                } else {
                    $("#progress_indeterminate_" + code_name).addClass("d-none"); 
                    $("#progress_" + code_name).addClass("d-none"); 
                    if (response.required_credits) {                        
                        $("#progress_credits_" + code_name).text(response.required_credits); 
                        $("#go_checkout").removeClass("d-none");
                        $("#go_checkout").addClass("blink_helper");
                    }
                    $("#" + code_name).append('<div class="row"><div class="col-3"></div><div class="mt-3 col-6 text-danger text-center">' + response.console + '</div><div class="col-3"></div></div>');
                }
            },
            error: function() {
                console.error("Can't check User's balance.");
            }
        });
    }   

    updateProgress(evt, code_name) {
        if (evt.lengthComputable) {
            let percentComplete = evt.loaded / evt.total;
            if (window.application.host_used == "birdtracking") {    
                let loadedSizeMb = evt.loaded / 1024 / 1024;
                let required_credits = window.application.calculateCost(loadedSizeMb, this.creditsTiers);
                $("#progress_credits_" + code_name).text(required_credits); 
            }
            $("#progress_indeterminate_"+code_name).addClass("d-none");
            $("#progress_"+code_name).removeClass("d-none");
            $("#progress_"+code_name + " div" ).css("width", percentComplete*100+'%');
        } else {
            $("#progress_"+code_name).addClass("d-none")
            $("#progress_indeterminate"+code_name).removeClass("d-none");
        }
    }

    destroy_track(elm, code_name, is_valid) {
        let self = this;
        let trackId = $(elm).data("track-id");
        let elementId = $(elm).data("element-id");
        let name = $(elm).data("name");
        let bearerToken = window.new_upload_bearer || null;
    
        function removeVisualElements() {
            $("#" + elementId).remove();
            self.current_file_names.splice(self.current_file_names.indexOf(name), 1);
            self.current_track_ids.splice(self.current_track_ids.indexOf(trackId), 1);
    
            if(self.current_track_ids.length === 0) {
                window.$("#create_activity").prop("disabled", true);
                $("#create_activity").removeClass("btn-secondary").addClass("btn-outline-secondary").removeClass("blink_helper");
            }
    
            self.file_count--;
            self.updateCounterFile();
        }
    
        let objFile = this.uploadingFiles.find(upF => upF.code_name === elementId);
        if(objFile) {
            objFile.ajax_call.abort();
            removeVisualElements();
            this.uploadingFiles = this.uploadingFiles.filter(upF => upF.code_name !== elementId);
        } else if(is_valid && trackId && bearerToken !== null) {            
            $.ajax({
                url: window.application.getAPIUrl() + "/v1/users/restore_balance",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({ 
                    track_id: trackId 
                }),
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                success: function(response) {
                    console.log("User's balance updated. New estimated User's balance: ", response.estimated_balance);
                    if($("#user_balance").length) {
                        let user_balance = parseInt(response.estimated_balance);
                        if (!isNaN(user_balance)) {                                        
                            $("#user_balance").attr("balance", user_balance);
                            $("#user_balance").empty().append(user_balance);
                        }
                    }
                    removeVisualElements(); // Suppression visuelle après la restauration de la balance
                },
                error: function(xhr, status, error) {
                    console.error("Can't restore User's balance: ", error);
                    removeVisualElements();
                }
            });
        } else {
            removeVisualElements(); 
        }
    }

    clear_form(){
        window.$("#create_activities").modal('hide');
        window.$("#create_activity").prop("disabled", true);
        $("#create_activity").removeClass("btn-secondary");
        $("#create_activity").addClass("btn-outline-secondary");
        this.current_file_names = [];
        this.current_track_ids = [];
        window.$("#track_file_list").html("");
    }

    send_data(){
        let self = this;
        if(this.current_track_ids.length > 0){

            try {
                let is_stuff_saved = false;
                if (localStorage.getItem("track_stuff") !== null && JSON.parse(localStorage.getItem("track_stuff")) !== null && JSON.parse(localStorage.getItem("track_stuff")).length > 0 ){
                    let arrStuff = JSON.parse(localStorage.getItem("track_stuff"));

                    if(!Number.isInteger(arrStuff[0])){
                        let result = arrStuff.find(a => a.category_id === parseInt($("#track_activity").val()))
                        if(result !== undefined){
                            result.values = $("#track_stuff").val();
                        }else{
                            arrStuff.push({category_id: parseInt($("#track_activity").val()), values: $("#track_stuff").val()})
                        }

                        localStorage.setItem('track_stuff', JSON.stringify(arrStuff));
                        is_stuff_saved = true;
                    }
                }

                if(!is_stuff_saved){
                    let save = {category_id: parseInt($("#track_activity").val()), values: $("#track_stuff").val()}
                    localStorage.setItem('track_stuff', JSON.stringify([save]));
                }

                let is_tags_saved = false;
                if (localStorage.getItem("track_tags") !== null  && JSON.parse(localStorage.getItem("track_tags")) !== null && JSON.parse(localStorage.getItem("track_tags")).length > 0 ){
                    let arrTags =  JSON.parse(localStorage.getItem("track_tags"));

                    if(!Number.isInteger(arrTags[0])){
                        let result = arrTags.find(a => a.category_id === parseInt($("#track_activity").val()))
                        if(result !== undefined){
                            result.values = $("#track_tags").val();
                        }else{
                            arrTags.push({category_id: parseInt($("#track_activity").val()), values: $("#track_tags").val()})
                        }

                        localStorage.setItem('track_tags', JSON.stringify(arrTags));
                        is_tags_saved = true;
                    }
                }

                if(!is_tags_saved){
                    localStorage.setItem('track_tags', JSON.stringify([{category_id: parseInt($("#track_activity").val()), values: $("#track_tags").val()}]));
                }
            }
            catch(e) {}

            let data = {
                track_ids: this.current_track_ids,
                category_id: $("#track_activity").val(),
                track_tags: $("#track_tags").val(),
                track_stuff: $("#track_stuff").val(),
                track_private: $("#private-switch-modal").is(":checked"),                   
            }

            if (window.application.host_used == "birdtracking") {    
                const track_setting_data = {};

                document.querySelectorAll('#track_settings input, #track_settings select').forEach(element => {
                    track_setting_data[element.name] = element.value;
                });

                data.track_setting_data = track_setting_data;
            }

            $.ajax({
                url: "/track/ajax_save_tracks",
                type: "post",
                data: data,
                success: function(data) {
                    if(!window.application.isPremium() && data["ad_premium"] === true){
                        AskPremiumModal.make(I18n.t("website.premium.track_upload"));
                    }
                    ToastNotification.postponedMessage(I18n.t("website.notifications.track_process.uploaded"));
                    self.clear_form();     
                    BrowserNavigation.redirectToPrevious(data["return_url"]);
                },
                error: function(data) {
                    ToastNotification.make(I18n.t("website.notifications.track_process.error"));
                }
            })
        }
    }
   
    parametarize(text){
        return text.trim().toLowerCase().replace(/\s/g, "-").replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/g, "");
    }

    makeId(length) {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    updateCounterFile() {
        if(this.file_count > 0){
            $("#track_counter_element").removeClass("d-none");
            $("#track_counter").text(this.file_count);
            if(!window.application.isPremium() && this.file_count >= 5 ){
                $("#track_counter_element").addClass("text-danger");
            }else{
                $("#track_counter_element").removeClass("text-danger");
            }
        }else{
            $("#track_counter_element").addClass("d-none");
        }
    }

    check_file(file, code_name, callback) {
        var freader = new FileReader();
        // Read only the first 1MB of the file (adjust as necessary)
        var chunkSize = 1 * 1024 * 1024;
        var blob = file.slice(0, chunkSize);
        freader.readAsText(blob);
            
        freader.onload = function (e) {
            var isValid = true;
            if(file.name.endsWith(".gpx")) {
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(freader.result,"text/xml");
                isValid = (xmlDoc.getElementsByTagName("gpx").length > 0);
            }
            else if (file.name.endsWith(".igc")) {
                isValid = false;
                var igcContent = freader.result.split("\n", 25);
                var key1 = "HFPLTPILOTINCHARGE";
                var key2 = "HOPLTPILOT";
                var key3 = "HFPLTPILOT";
                var key4 = "HPPLTPILOT";
                var key5 = "HFDTE";
                for(var i=0;i<igcContent.length;i++) {
                  var line = igcContent[i].split(":");
                  if( (line[0]==key1 || line[0]==key2 || line[0]==key3 || line[0]==key4)) {
                      isValid = true;
                      break;
                  } else if (line[0].includes(key5)) {
                    isValid = true;
                      break;
                  }
                }
            }

            callback(file, code_name, isValid);
        };
    }

    extract_file_start_date(file, code_name) {
        var start_date = null;
        
        var freader = new FileReader();
        // Read only the first 1MB of the file (adjust as necessary)
        var chunkSize = 1 * 1024 * 1024;
        var blob = file.slice(0, chunkSize);
        freader.readAsText(blob);        
        freader.onload = function (e) {
            if(file.name.endsWith(".gpx")) {
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(freader.result,"text/xml");
                start_date = xmlDoc.getElementsByTagName("gpx")[0]
                    .getElementsByTagName("trk")[0]
                    .getElementsByTagName("trkseg")[0]
                    .getElementsByTagName("trkpt")[0]
                    .getElementsByTagName("time")[0]
                    .childNodes[0]
                    .nodeValue

                start_date = new Date(start_date).toLocaleString(I18n.locale)

                $("#start_date_"+code_name).text(start_date);
            }
        };
    }

    sendHeartbeat() {
        let bearerToken = window.new_upload_bearer || null;

        if (bearerToken !== null) {
            $.ajax({
                url: window.application.getAPIUrl() + "/v1/users/heart_beat_track_upload",
                type: "PUT",
                contentType: "application/json; charset=utf-8",
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                success: function(response) {
                    console.log("❤️ Heartbeat updated");
                },
                error: function(xhr, status, error) {
                    console.error("Error while trying to handle HeartBeat: ", error);
                }
            });
        }
    }

    startHeartbeat() {        
        this.sendHeartbeat();

        this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), 30000); // 30000 ms = 30 secondes
    }

    setTagsAndStuff(){
        try {
            if (localStorage.getItem("track_stuff") !== null && localStorage.getItem("track_stuff").length > 0 ){
                let arrStuff = JSON.parse(localStorage.getItem("track_stuff"));

                if(!Number.isInteger(arrStuff[0])){
                    let result = arrStuff.find(a => a.category_id === parseInt($("#track_activity").val()))
                    if(result !== undefined){
                        arrStuff = result.values;
                    }else{
                        arrStuff = [];
                    }
                }
                arrStuff.forEach(function (item) {
                    let newOption = new Option(item, item, true, true);
                    $('#track_stuff').append(newOption).trigger('change');
                })
            }

            if (localStorage.getItem("track_tags") !== null && localStorage.getItem("track_tags").length > 0 ){
                let arrTags =  JSON.parse(localStorage.getItem("track_tags"));

                if(!Number.isInteger(arrTags[0])){
                    let result = arrTags.find(a => a.category_id === parseInt($("#track_activity").val()))
                    if(result !== undefined){
                        arrTags = result.values;
                    }else{
                        arrTags = [];
                    }
                }

                arrTags.forEach(function (item) {
                    let newOption = new Option(item, item, true, true);
                    $('#track_tags').append(newOption).trigger('change');
                })
            }
        }
        catch(e) {}
    }
}