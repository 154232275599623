import _ from "lodash"

const TEMPLATE_INTERPOLATION_REGEXP = /\$\{[\w\.?]+\}/g;

export default class TemplateHelper {
    static getTemplate(templateName, data) {
        let templateText = $(`script[data-template="${templateName}"]`).text();

        if (templateText && data) {
            const templateKeys = templateText.match(TEMPLATE_INTERPOLATION_REGEXP);
            for (let templateKey of templateKeys) {

                const variablePath = templateKey.replace(/\$|\{|\}/g, "");
                const value = _.get(data, variablePath, "");

                templateText = templateText.replaceAll(templateKey, value);
            }
        }

        return templateText;
    }
}