const TOAST_TITLE = window.location.host.indexOf('birdtracking') > -1 ? "BirdTracking" : "SportsTrackLive"

export default class ToastNotification {

    static make(message = ""){
        window.$('.notify_toast').remove();
        $(document.body).append(ToastNotification.get_toast());
        window.$('#notify_body').html(message);
        window.$('.notify_toast').toast('show');
        $('.notify_toast').on('hidden.bs.toast', function () {
            window.$('.notify_toast').remove();
        });
        $('.notify_toast').on('hide.bs.toast', function () {
            window.$('.notify_toast').remove();
        })
    }
    static postponedMessage(message='') {
        cookies.create("postponed-toast-notification",message);
    }
    static showPostponedMessage() {
        const  message = cookies.get("postponed-toast-notification");
        if (message) {
            ToastNotification.make(message);
            cookies.delete("postponed-toast-notification");
        }
    }


    static get_toast() {
        return '<div class="fixed-top" style="height: auto;z-index: 1100" >\n' +
            '    <div class="notify_toast toast position-absolute" data-delay="10000" style="top: 80px; right: 20px" role="alert" aria-live="assertive" aria-atomic="true">\n' +
            '      <div class="toast-header">\n' +
            '        <img height="30" class="mr-3" src="'+window.badge_url+'">\n' +
            '        <strong class="mr-auto">'+TOAST_TITLE+'</strong>\n' +
            '        <button class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">\n' +
            '          <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '      </div>\n' +
            '      <div class="toast-body" id="notify_body">\n' +
            '\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>';
    }

}