export default class LiveMap2D {
  
  constructor() {
    this.session = null;
    this.tracks = [];

    this.viewer = null;
    this.inital_camera_position = null;

    this.current_state = 0;
    this.initial_state_zoom = 0;
    this.initial_bearing = 0;

    this._epoch = null;
    this.last_point_date = null;
    this._first_entity_name = null;

    this.players = { }

    this.colors = ["red", "blue", "green", "orange", "purple", "yellow"];

    this.colorCount = null;

    this.globalPolyline = [];
  }

  init(session) {
    var self = this;
    this.session = session;
    this.tracks = session.tracks;

    this.colorCount = 0;

    Object.keys(this.tracks).forEach(function (key) { 
      var track = self.tracks[key];

      self.players[key] = {
        track: track,
        polyline: null,
        marker: null,
        color: self.colors[self.colorCount]
      };

      if(self._first_entity_name==null) {
        self._first_entity_name = key;
      }

      self.colorCount++;

    });

    this._extract_inital_camera_position();
    this.last_point_date = moment();
    this._start();
  }

  _extract_inital_camera_position() {
    this.inital_camera_position = [this.session.initial_position.latitude, this.session.initial_position.longitude, this.session.initial_position.altitude];
    this.initial_bearing = 0;
  }

  _start() {
    var self = this;


    $("#map").height(window.innerHeight-$("#map").offset().top);
    this.map = L.map('map').setView([this.session.initial_position.latitude, this.session.initial_position.longitude], 16);

    L.tileLayer('https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);


    this.globalPolyline =  L.polyline([], {});
    var globalLatLngs = [];


    Object.keys(this.players).forEach(function (key) { 
      var latlngs = self.players[key].track.points.map(a => [parseFloat(a.latitude), parseFloat(a.longitude)]);
      self.players[key].polyline = L.polyline(latlngs, { "color": self.players[key].color });
      //self.globalPolyline.setLatLngs(latlngs);
      globalLatLngs.push(latlngs);

      self.players[key].polyline.addTo(self.map);

      var points = self.players[key].track.points;
      var lPoint = points[points.length-1];

      var marker = L.marker([parseFloat(lPoint.latitude), parseFloat(lPoint.longitude)], { });
      marker.addTo(self.map);
      self.players[key].marker = marker;
      marker.bindTooltip("<b style=\"color:"+self.players[key].color +"\">"+key+"</b><br/><b>Speed:</b> "+(lPoint.speed!=null ? Math.round(lPoint.speed*3.6, 2) : 0 )+"km/h");


      /*if(key==self._first_entity_name) {
        self.map.fitBounds(self.players[key].polyline.getBounds());
      }*/

    });

    this.globalPolyline.setLatLngs(globalLatLngs);
    this.map.fitBounds(this.globalPolyline.getBounds());
  }


  onPointReceived(data) {

    this.last_point_date = moment.unix(data.date);
    var player = this.players[data.uname];
    
    var latLng = L.latLng(parseFloat(data.lat), parseFloat(data.lon));

    //If the player is not found, we should add it now.
    if(player==null) {

      this.colorCount++;

      this.players[data.uname] = {
        track: [],
        polyline: null,
        marker: null,
        color: this.colors[this.colorCount]
      };
      player = this.players[data.uname];
      player.polyline = L.polyline([], { "color": this.players[data.uname].color });
      player.marker = L.marker([parseFloat(data.lat), parseFloat(data.lon)], { });
      player.marker.bindTooltip("<b style=\"color:"+player.color +"\">"+data.uname+"</b><br/><b>Speed:</b> "+(data.speed!=null ? Math.round(data.speed*3.6, 2) : 0 )+"km/h");
      player.marker.addTo(this.map);
    }
    else {

      player.polyline.addLatLng(latLng);
      player.marker.setLatLng(latLng);
      player.marker.getTooltip().setContent("<b style=\"color:"+player.color +"\">"+data.uname+"</b><br/><b>Speed:</b> "+(data.speed!=null ? Math.round(data.speed*3.6, 2) : 0 )+"km/h");
    
       //if(data.uname==this._first_entity_name) {
      //}
    }

    this.globalPolyline.addLatLng(latLng);
    this.map.fitBounds(this.globalPolyline.getBounds());
  }

}