import Particle from "./Particle";

export const globalState = {
	iPerspective: 400,
    iProjSphereX: 0,
    iProjSphereY: 0,
    fAngle: 0,
    fVX: (2 * Math.PI) / 2000,
    fGrowDuration: 200.0,
    fWaitDuration: 50.0,
    fShrinkDuration: 250.0,
    iNewParticlePerFrame: 10,
    aColor: [255, 255, 255],
    iRadiusSphere: 300,
    oRender: { pFirst: null },
    oBuffer: { pFirst: null },
};


export const setGlobalState = (key, value) => {
    if (key in globalState) {
        globalState[key] = value;
    }
};

export const fnSwapList = (p, oSrc, oDst) => {
    if (p) {
    // Remove from oSrc
    if (oSrc.pFirst === p) {
        oSrc.pFirst = p.pNext;
        if (p.pNext) p.pNext.pPrev = null;
    } else {
        p.pPrev.pNext = p.pNext;
        if (p.pNext) p.pNext.pPrev = p.pPrev;
    }
    } else {
    p = new Particle();
    }

    // Prepend to oDst
        p.pNext = oDst.pFirst;
        if (oDst.pFirst) oDst.pFirst.pPrev = p;
        oDst.pFirst = p;
        p.pPrev = null;

    return p;
};
