export default class HomeLiveStandings {

    constructor() {
        this.url = "";
        this.category_id = 0;
        this.tracks_from = 0;
        this.sw_lat = 0;
        this.sw_lng = 0;
        this.ne_lat = 0;
        this.ne_lng = 0;
        this.selected_tracks = [];
        this.fatherClass = null;
        this.bindEvents();
    }


    bindEvents() {
        let self = this;

        $('#home-standigs-modal').unbind("show.bs.modal").on('show.bs.modal', function (e) {
            self.url = "home/ajax_standings";
            self.updateModalData();
        });

        $(".page-link").unbind("click").click(function (e) {
            e.preventDefault();
            if($(this).attr("href")!=null) {
                self.url =  $(this).attr("href");
                self.updateModalData();
            }
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $('#home-standigs-modal').unbind("show.bs.modal");
        $(".page-link").unbind("click");
    }

    updateModalData(){
        let self = this;
        /*$("#home-standings-loader").removeClass("d-none");
        $("#home-standings-loader").addClass("d-flex");*/

        $.ajax({
            url: this.url,
            type: "get",
            data: {
                category_id: this.category_id,
                tracks_from: this.tracks_from,
                include: this.selected_tracks,
                sw_lat: this.sw_lat,
                sw_lng: this.sw_lng,
                ne_lat: this.ne_lat,
                ne_lng: this.ne_lng,
            },
            success: function(html) {
                $("#home-standigs-row").empty();
                $("#home-standings-loader").removeClass("d-flex");
                $("#home-standings-loader").addClass("d-none");
                $("#home-standigs-row").append(html);
                self.bindEvents();
                self.fatherClass.live_btn_listener();
                self.updateSelected()

            },
            error: function(data) {

            }
        });
    }

    updateCategory(category_id){
        this.category_id = category_id;
    }

    updateEventsFrom(from){
        this.tracks_from = from;
    }

    updateBounds(bounds) {
        this.sw_lat = bounds._southWest.lat;
        this.sw_lng = bounds._southWest.lng;
        this.ne_lat = bounds._northEast.lat;
        this.ne_lng = bounds._northEast.lng;
    }

    setFatherClass(father){
        this.fatherClass = father;
    }

    set_tracks_live(selected_tracks){
        this.selected_tracks = selected_tracks;
        this.updateSelected();
    }

    updateSelected() {
        let self = this
        this.selected_tracks.forEach(function (item) {
            $("#"+item+"-switch-standing").prop("checked",true)
        })
        $("input[id$='-switch-standing']").each(function () {
            let id = $(this).data("track-id");
            if(window.$.inArray( id, self.selected_tracks ) < 0){
                $("#"+id+"-switch-standing").prop("checked",false)
            }
        });
    }
}