import AskPremiumModal from "./AskPremiumModal";

export default class Abstract3DMapHandler {

    beforeSendHandler = (request) => {
        request.setRequestHeader("X-STL-Token", $("meta[name=token]").attr("content")); //cookies.get("STL-Token"));
    }

    constructor(first_appear_ad = 100, frequency_ad = 100) {
        this.tracks = [];
        this.points = {};
        this.pointsData = {};
        this.owner_id = null;
        this.localities_around = [];
        this.first_appear_ad = first_appear_ad;
        this.frequency_ad = frequency_ad;
    }

    start_timer(modalType) {
        let time = 0;
        let readable_time_in_minutes = 0;
        let self = this;
        window.setInterval(function () {
            time++;
            if (
                !window.application.isPremium() && 
                (
                    time === self.first_appear_ad || 
                    (time > self.first_appear_ad && (time - self.first_appear_ad) % self.frequency_ad === 0)
                )
            ) {
                readable_time_in_minutes = (Math.round(time / 60 * Math.pow(10, 1) + Number.EPSILON) / Math.pow(10, 1)).toString().replace(".", ",");
                AskPremiumModal.destroy_all();
                AskPremiumModal.make(I18n.t(`website.premium.${modalType}`, { timer: readable_time_in_minutes }));
                CesiumManager.stopModePlayer();
            }
        }, 1000);
    }

    getAlongRoute(byTrackId = 0) {
        return new Promise((resolve, reject) => {
            var ajaxData = {
                url: window.application.getAPIUrl() + "/v1/localities/along_route",
                data: {
                    track_id: byTrackId
                },
                method: "GET",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData).done((data) => {
                if (data.localities != null && data.localities.length > 0) {
                    $.each(data.localities, (idx, locality) => {
                        this.localities_around.push({
                            lat: locality.latitude,
                            lon: locality.longitude,
                            alt: locality.altitude,
                            description: locality.name
                        })
                    });
                }
                resolve();
            
            }).fail((err) => reject(err));
        });
    }

    getAround(byTrackId = 0) {
        const pointsData = this.points[byTrackId];
        if (!pointsData.points_header) {
            return;
        }
        const latKey = pointsData.points_header.indexOf("latitude");
        const lonKey = pointsData.points_header.indexOf("longitude");

        let dataQuery = {
            latitude: pointsData.points.slice(-1)[0][latKey],
            longitude: pointsData.points.slice(-1)[0][lonKey],
            distance: 10
        };

        if (this.owner_id !== undefined) {
            dataQuery["owner_id"] = this.owner_id
         }

        return new Promise((resolve, reject) => {
            var ajaxData = {
                url: window.application.getAPIUrl() + "/v1/localities/around",
                data: dataQuery,
                method: "GET",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData).done((data) => {
                if (data.localities != null && data.localities.length > 0) {
                    $.each(data.localities, (idx, locality) => {
                        this.localities_around.push({
                            lat: locality.latitude,
                            lon: locality.longitude,
                            alt: locality.altitude,
                            description: locality.name
                        })
                    });
                }
                resolve();
            
            }).fail((err) => reject(err));
        });
    }

    onTickChanges(cb = (x) => {}) {
        CesiumManager.setTickCallback((currentTick, trackOffset) => cb(currentTick - (trackOffset ?? 0))); 
    }
}