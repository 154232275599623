export default class LazyLoad {

    static make(){
            let images = new Array()
                , query = LazyLoad.q('img.lazy')
                , processScroll = function(){
                    for (var i = 0; i < images.length; i++) {
                        if (LazyLoad.elementInViewport(images[i])) {
                            LazyLoad.loadImage(images[i], function () {
                                $(images).removeClass("lazy");
                                images.splice(i, 1);
                            });
                        }
                    };
                }
            ;

            for (var i = 0; i < query.length; i++) {
                images.push(query[i]);
            };

            processScroll();
            LazyLoad.customAddEventListener('scroll',processScroll);
    }


    static loadImage (el, fn) {
        let img = new Image()
            , src = el.getAttribute('data-src');
        img.onload = function() {
            if (!! el.parent)
                el.parent.replaceChild(img, el)
            else
                el.src = src;

            fn? fn() : null;
        }
        img.src = src;
    }


    static elementInViewport(el) {
        let rect = el.getBoundingClientRect()

        return (
            rect.top    >= 0
            && rect.left   >= 0
            && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        )
    }

    static q(q, res){
        if (document.querySelectorAll) {
            res = document.querySelectorAll(q);
        } else {
            var d=document
                , a=d.styleSheets[0] || d.createStyleSheet();
            a.addRule(q,'f:b');
            for(var l=d.all,b=0,c=[],f=l.length;b<f;b++)
                l[b].currentStyle.f && c.push(l[b]);

            a.removeRule(0);
            res = c;
        }
        return res;
    }

    static customAddEventListener(evt, fn){
        window.addEventListener
            ? window.addEventListener(evt, fn, false)
            : (window.attachEvent)
            ? window.attachEvent('on' + evt, fn)
            : window['on' + evt] = fn;
    }

    static has(obj, key) {
        return Object.prototype.hasOwnProperty.call(obj, key);
    }

}