export default class ListUsers {

    static buildList(list_id,items){
        items.forEach(function (item, index) {
            $('#'+list_id).append(ListUsers.userItem(list_id,item, index%2 === 0));
        })
    }

    static userItem(list_id, item, is_pair){
        let element = '<div class="row py-2 '+list_id+'-item '+(is_pair ? 'bg-white' : '' )+'">\n' +
            '              <div class="col-5">\n' +
            '                <div class="media">\n' +
            '                  <a href="'+item.url+'"><img height="50" class="rounded-circle mr-3" src="'+item.thumbnail+'"></a>\n' +
            '                  <div class="media-body">\n' +
            '                    <span class="rounded flag-icon flag-icon-fr fs-14 mr-1"></span>\n' +
            '                    <a class="text-secondary fs-14" href="'+item.url+'">'+item.name+'</a>\n' +
            '                    <div class="text-light-gray fs-14">'+item.last_locality_name+'</div>\n' +
            '                  </div>\n' +
            '                </div>\n' +
            '              </div>\n' +
            '              <div class="col-4 d-flex align-items-center">\n' +
            '                <div class="text-center w-100 fs-14">'+item.track_count+' '+I18n.t("website.user.follow.activities")+'</div>\n' +
            '              </div>\n' +
            '              <div class="col-3 d-flex align-items-center">\n' +
            '                <div class="w-100">\n';

        if(item.is_current_user){
            element += '                    <a href="" class="btn btn-white disabled w-100 lh-3">Vous</a>\n';
        }else{
            element += '                    <a href="" data-user-id="'+item.id+'" class="js-unsuscribe-user btn btn-outline-secondary w-100 lh-3 '+(item.is_followed ? "" : "d-none")+'"><i class="mdi mdi-check mr-2"></i> '+I18n.t("website.user.show.side_bar.subscribed")+'</a>\n' +
            '                    <a href="" data-user-id="'+item.id+'" class="js-suscribe-user btn btn-secondary w-100 lh-3 '+(item.is_followed ? "d-none" : "")+'">'+I18n.t("website.user.show.side_bar.subscribe")+'</a>\n';
        }

        element += '                </div>\n' +
            '              </div>\n' +
            '            </div>';
        return element;
    }

}