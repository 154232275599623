
export default class SpotsMap {

    constructor() {
        this.location_id = 0;
        this.track_ids = [];
        this.locality_position = null;
        this.map = null;
        this.marker =null;
        this.marker_pointer = "";
        this.marker_pointer_gray = "";
        this.marker_pointer_shadow = "";
        this.paths = [];


		/*---Icon sizing---*/
		this.percentage = 0.5;
		this.lastIconPos = 0;

		this.iconSize_x = function(){return this.calcByPercent(96)};
		this.iconSize_y =  function(){return this.calcByPercent(109)};

		this.shadowSize_x =  function(){return this.calcByPercent(90)};
		this.shadowSize_y =  function(){return this.calcByPercent(39)};

		this.iconAnchor_x = function(){return this.calcByPercent(48)};
		this.iconAnchor_y =  function(){return this.calcByPercent(109)};

		this.shadowAnchor_x =  function(){return this.calcByPercent(0)};
		this.shadowAnchor_y =  function(){return this.calcByPercent(39)};    

        this.marker_icon = function(){ return L.icon({
            iconUrl: this.marker_pointer,
            shadowUrl:  this.marker_pointer_shadow,

			iconSize:     [this.iconSize_x(), this.iconSize_y()], // size of the icon
			shadowSize:   [this.shadowSize_x(), this.shadowSize_y()], // size of the shadow
			iconAnchor:   [this.iconAnchor_x(), this.iconAnchor_y()], // point of the icon which will correspond to marker's location 99*66
			shadowAnchor: [this.shadowAnchor_x(), this.shadowAnchor_y()],  // the same for the shadow
        });};

        this.marker_icon_gray = function(){ return L.icon({
            iconUrl: this.marker_pointer_gray,
            shadowUrl:  this.marker_pointer_shadow,

			iconSize:     [this.iconSize_x(), this.iconSize_y()], // size of the icon
			shadowSize:   [this.shadowSize_x(), this.shadowSize_y()], // size of the shadow
			iconAnchor:   [this.iconAnchor_x(), this.iconAnchor_y()], // point of the icon which will correspond to marker's location 99*66
			shadowAnchor: [this.shadowAnchor_x(), this.shadowAnchor_y()],  // the same for the shadow
        });};

        this.bindEvents();
        this.initMap();
    }

    bindEvents(){
    }

	calcByPercent(x){
		return Math.round(x * this.percentage)
    }
    
    onDataChanged(data) {
        this.location_id = data.location_id;
        this.track_ids = data.track_ids;
        this.track_ids = data.track_ids;
        this.locality_position = data.locality_position;
        this.marker_pointer = data.marker_pointer;
        this.marker_pointer_gray = data.marker_pointer_gray;
        this.marker_pointer_shadow = data.marker_pointer_shadow;
        this.loadTrackPoints();
        this.loadNearSpots();

        this.marker = L.marker([parseFloat(this.locality_position.lat), parseFloat(this.locality_position.long)], {icon: this.marker_icon()});
        this.marker.addTo(this.map);


        let group = new L.featureGroup([this.marker]);
        this.map.flyToBounds(group, { animate: true, duration: 3, maxZoom: 12 });
    }


    onDestroy(){
        this.map.remove();
    }

    initMap() {
        let height = $("#locality-map").parent().height();
        if(height<400){
            height = 400;
        }
        $("#locality-map").height(height);

        this.map = L.map('locality-map').setView([41.831131, 15.624207], 2);

        L.tileLayer('https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
    }

    loadTrackPoints() {
        let self = this;

        let ajaxData = {
            url: window.application.getAPIUrl() +"/v1/localities/"+this.location_id+"/get_tracks_points",
            data: {},
            method: "get",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                self.drawPath(data);
            }
        }).fail(function(err) {
        });

    }

    loadSpots() {

    }

    centerView(latlong, zoom = null, duration = 3) {
        this.map.setView(latlong, zoom, {
            "animate": true,
            "pan": {
                "duration": duration,
                easeLinearity: 1
            }
        });
    }

    drawPath(paths) {
        let self = this;
        paths.forEach(function (item) {
            // var color;
            // var r = Math.floor(Math.random() * 255);
            // var g = Math.floor(Math.random() * 255);
            // var b = Math.floor(Math.random() * 255);
            // color= "rgb("+r+" ,"+g+","+ b+")";

            var color = "rgb("+item["color"][0]+" ,"+item["color"][1]+","+ item["color"][2]+")"

            // var latlngs = item.point_items_only_coordonates.map(a => [a.latitude, a.longitude]);
            self.paths.push(L.polyline(item.points, {color: "white",weight: 3}));
            self.paths.push(L.polyline(item.points, {color: color,weight: 2}));
        });

        let group = new L.featureGroup(this.paths);
        this.map.flyToBounds(group.getBounds(), { animate: true, duration: 3 });

        this.map.on("moveend", function(){
            self.paths.forEach(function(item){
                item.addTo(self.map);
            })
        });
    }

    loadNearSpots() {
        let self = this;

        let ajaxData = {
            url: window.application.getAPIUrl() +"/v1/localities/"+this.location_id+"/get_near_spots",
            data: {
                activity_type: cookies.get("activity_preference")
            },
            method: "get",
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function(data) {
            if (data !== undefined){
                self.setNearSpots(data);
            }
        }).fail(function(err) {
        });
    }

    setNearSpots(spots) {
        let self = this;
        spots.localities.forEach(function (item) {
            let m = L.marker([parseFloat(item.latitude), parseFloat(item.longitude)], {icon: self.marker_icon_gray()}).addTo(self.map);
            let name = item.name;
            let athletes = item.athletes;
            let track_count = item.track_count;
            let url = "/localities/"+item.country_code+"/"+item.id;

            m.bindPopup(
                '<div class="container-fluid py-2 text-center" style="min-width: 250px;">' +
                '<div class="row">' +
                '<div class="col-12">' +
                '<a href="'+url+'" class="text-secondary fs-14">'+ name +'</a>' +
                '</div>' +
                '</div>' +
                '<div class="row mt-2">' +
                '<div class="col-6 border-right border-lighter-gray">' +
                '<span class="fw-600">'+ athletes +'</span> '+ I18n.t("website.localities.index.spots_map.athletes") +
                '</div>' +
                '<div class="col-6">' +
                '<span class="fw-600">'+ track_count +'</span> '+ I18n.t("website.localities.index.spots_map.tracks") +
                '</div>' +
                '</div>' +
                '</div>' , {direction: "top", offset: [0,-50]});

        })
    }

}