export class HomeLiveMapMarkerManager {
  constructor(parent, markerClusterGroup, markerGenerator, deps = {}) {
    this.parent = parent;
    this.map = parent.map;
    this.markerClusterGroup = markerClusterGroup;
    this.markerGenerator = markerGenerator;
    this.deps = deps;

    this.markers = [];
    this.liveMarkers = [];
    this.liveBufferPoints = [];

    this.selectedMarkers = [];

    this.markerPointer = null;
    this.markerPointerLive = null;
    this.markerPointerShadow = null;
  }

  setMarkerPointerTracker(main, live = undefined, shadow = undefined) {
    this.markerPointer = main;
    this.markerPointerLive = live;
    this.markerPointerShadow = shadow;
  }

  clearMarkers(remainActivities, activityIdSelector) {
    if (this.markers.length > 0) {
      const currentMarkerIds = this.markers.map(({ id }) => id);
      const new_ids = remainActivities.map((activity) => activity[activityIdSelector]);

      const oldMarkerIds = currentMarkerIds.filter((currentMarkerId) => !new_ids.includes(currentMarkerId));

      const markersToRemoving = [];
      oldMarkerIds.forEach((oldMarkerId) => {
        const oldMarker = this.markers.find((marker) => marker.id === oldMarkerId);

        if (oldMarker) {
          markersToRemoving.push(oldMarker.marker);
        }
      });

      this.markers = this.markers.filter((x) => !oldMarkerIds.includes(x.id));

      this.liveMarkers = this.liveMarkers.filter((liveMarker) => !oldMarkerIds.includes(liveMarker.id));
      this.markerClusterGroup.removeLayers(markersToRemoving);
    }
  }

  centerToMarker(id) {
    let currentMarker = this.markers.find((x) => x.id === id);

    if (
      currentMarker !== undefined &&
      this.markerClusterGroup.hasLayer(currentMarker.marker)
    ) {
      this.map.setView(currentMarker.marker.getLatLng());
      this.markerClusterGroup.zoomToShowLayer(
        currentMarker.marker,
        () => currentMarker.marker.openPopup()
      );
    } else {
      currentMarker = this.liveMarkers.find((x) => x.id === id);

      if (currentMarker !== undefined) {
        this.map.setView(currentMarker.marker.getLatLng());
      } else {
        currentMarker = this.selectedMarkers.find((x) => x.id === id);

        if (currentMarker !== undefined) {
          this.map.setView(currentMarker.marker.getLatLng());
        }
      }
    }
  }
}
