export default class WorldMap {

  constructor(liveWorldmap) {
 
    this.live_worldmap = liveWorldmap;

    this.players = this.live_worldmap.players;

    this.colors = ["red", "blue", "green", "orange", "purple", "yellow"];
    this.colorCount = 0;

    $("#world_map").height(600);
    this.map = L.map('world_map').setView([42,2.8], 4);

    L.tileLayer('https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. OpenTopoMap.org'
    }).addTo(this.map);
  }

  panTo(p) {
    var latLng = L.latLng(parseFloat(p.player.last_position.latitude), parseFloat(p.player.last_position.longitude));
    this.map.panTo(latLng);
  }

  onPointReceived(data) {
    var p = this.players[data.uname];
    if(p.polyline==null) {
      p.color = this.colors[this.colorCount];
      this.colorCount++;
      p.polyline = L.polyline([], { "color": p.color });
      p.polyline.addTo(this.map);
    }

    var latLng = L.latLng(parseFloat(data.lat), parseFloat(data.lon));
    p.polyline.addLatLng(latLng);
    p.marker.setLatLng(latLng);
    
  }

  onLiveDataChanged(data) {

    for(var i =0; i< data.users.length; i++) {
      var player = data.users[i];
      if(this.players[player.user_name]==null) {
        this.players[player.user_name] = { player: player };
      }

      if(this.players[player.user_name].marker == null) {
        var marker = L.marker([parseFloat(player.last_position.latitude), parseFloat(player.last_position.longitude)], { });
        marker.addTo(this.map);
        this.players[player.user_name].marker = marker;
        marker.bindTooltip(player.user_name);
      }
      else{
        
        var latLng = L.latLng(parseFloat(player.last_position.latitude), parseFloat(player.last_position.longitude));
        this.players[player.user_name].marker.setLatLng(latLng);
      }
    }
  }
}