import CesiumManagerWrapper from "../utils/CesiumManagerWrapper";
import TrackDetail from "../views/TrackDetail.js"

export default class TrackShowMap {
    
    constructor(isExternal = false) {
        this.isExternal = isExternal;
        this.trackDetail = new TrackDetail();
        window.application.setOnDataChangeListener(this);
    }

    onDataChanged(data) {
        this.trackDetail.onDataChanged(data, this.isExternal);
    }

    onDestroy() {
        if (data.mode == "2D") {
            this.trackDetail.onDestroy();
        }
        if (data.mode == "3D") {
            CesiumManagerWrapper.destroyWidget();
        }
    }
}