//import Confetti from "../utils/Confetti";

export default class CheckoutPaymentOk {

    constructor() {
        //this.confetti = new Confetti("#page-wraper");
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

}