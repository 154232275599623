import ToastNotification from "../utils/ToastNotification";

export default class ConfigurationNotifications {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;
        $("#send_notification").unbind("click").click(function (e) {
            e.preventDefault();
            self.sendForm();
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }


    sendForm() {
        let self = this;

        let data = {
            optin_email: $("input[name='optin_email']:checked").is(":checked"),
            email_new_subscriber: $("input[name='email_new_subscriber']:checked").is(":checked"),
            //email_new_comment: $("input[name='email_new_comment']:checked").is(":checked"),
            //email_like_count_24h: $("input[name='email_like_count_24h']:checked").is(":checked"),
            email_scene_ready: $("input[name='email_scene_ready']:checked").is(":checked"),
            email_live_started: $("input[name='email_live_started']:checked").is(":checked"),
            email_live_ended: $("input[name='email_live_ended']:checked").is(":checked"),
            email_track_ready: $("input[name='email_track_ready']:checked").is(":checked"),
            email_daily_summary: $("input[name='email_daily_summary']:checked").is(":checked"),
        };

        let ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/",
            data: {
                user: data
            },
            method: "PUT",
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData).done(function (data) {
            ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
        }).fail(function (err) {
            ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
        });

    }
}