import "jquery-validation";
import BrowserNavigation from '../utils/browser_navigation'
import * as select2 from "select2";

export default class SceneNew {

    constructor() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.dropArea = document.getElementById('scene_drop_hexagon');
        this.available_file_types = ["application/gpx+xml", "application/octet-stream", "application/x-binary"];
        this.available_file_ext = ["gpx", "GPX", "igc", "IGC", "sbp", "SBP", "txt", "fit", "FIT"];
        this.current_file_names = [];
        this.current_track_ids = [];
        this.players = [];
        this.uploadingFiles = [];
        this.playerIndex = 0;
        this.file_count = 0;
        this.track_limit = 0;
        this.counter_draggover = 0;
        this.validation = null;

        window.application.setOnDataChangeListener(this);
        this.bindEvents();
    }

    onDataChanged(data) {
        this.track_limit = data.track_limit;
    }

    bindEvents() {
        let self = this;

        $(this.dropArea).bind({
            dragenter: function (ev) {
                ev.preventDefault(); // needed for IE
                self.counter_draggover++;
                $(this).addClass('drag_in');
            },

            dragleave: function () {
                self.counter_draggover--;
                if (self.counter_draggover === 0) {
                    $(this).removeClass('drag_in');
                }
            }
        });

        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.dropArea.addEventListener(eventName, self.preventDefaults, false)
        });

        this.dropArea.addEventListener('drop', function (event) {
            let el = this;
            $(this).removeClass("drag_in");
            $(this).addClass('dropped');
            setTimeout(function () {
                $(el).removeClass('dropped');
            }, 1500);
            self.handleDrop(event);
        }, false);

        $("#scene_file").change(function () { self.handleFiles(this.files); });

        $("#cancel_activity").click(function () {
            let bearerToken = window.new_upload_bearer || null;
            if (self.current_track_ids.length > 0 && bearerToken !== null) {
                $.ajax({
                    url: window.application.getAPIUrl() + "/v1/users/cancel_pending_tracks",
                    type: "POST",
                    data: JSON.stringify({ 
                        scene_track_ids: self.current_track_ids 
                    }),
                    contentType: "application/json; charset=utf-8",
                    beforeSend: function(request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                    success: function(response) {
                        console.log("Tracks successfully cancelled");
                        BrowserNavigation.redirectToPrevious();
                    },
                    error: function(xhr, status, error) {
                        console.error("Error while trying to cancel Tracks: ", error);                        
                        BrowserNavigation.redirectToPrevious();
                    }
                });
            } else {             
                BrowserNavigation.redirectToPrevious();
            }            
        })
        $("#create_scene_btn").unbind("click").click(function (e) {
            e.preventDefault();
            let valid_names = true;
            $("input[id^=player_name_]").each(function () {
                if ($(this).val().trim() === "") {
                    valid_names = false;
                    $(this).addClass("border-bottom")
                    $(this).addClass("border-danger")
                }
            });
            if (valid_names) {
                $("#scene_track_name").addClass("d-none");
            } else {
                $("#scene_track_name").removeClass("d-none");
            }
            if (self.current_track_ids.length > 0 && $("#new_scene").valid()) {
                if (valid_names) {
                    self.send_data();
                }
            }
        });

        let validation_options = {
            rules: {
                scene_name: {
                    required: true,
                    normalizer: function (value) {
                        return $.trim(value);
                    }
                }
            },
        }

        if (I18n.locale !== 'en') {
            let localeDepPath = "jquery-validation/dist/localization/messages_" + I18n.locale
            validation_options.messages = require(localeDepPath)
        }

        this.validation = $("#new_scene").validate(validation_options);


        window.$('.js-select2-category').select2({
            theme: 'bootstrap4',
            templateResult: window.application.formatCategory,
            templateSelection: window.application.formatCategory,
            language: I18n.locale
        });
    }

    onDestroy() {
        window.$('.js-select2-category').select2('destroy');
        this.validation.destroy();
    }

    handleDrop(e) {
        let dt = e.dataTransfer;
        let files = dt.files;
        this.handleFiles(files);
    }

    handleFiles(files) {
        $("#create_scene_btn").prop("disabled", true);
        $("#create_scene_btn").removeClass("btn-secondary");
        $("#create_scene_btn").addClass("btn-outline-secondary");
        let self = this;
        $.each(files, function (idx, elm) {
            if (!self.current_file_names.includes(elm.name) && (self.available_file_types.includes(elm.type) || self.available_file_ext.includes(elm.name.split('.')[elm.name.split('.').length - 1]))) {
                let code_name = self.makeId(5);

                self.check_file(elm, code_name, function (_elm_, _code_name_, is_valid) {
                    if (is_valid) {
                        if (self.file_count < self.track_limit) {
                            self.previewFile(elm, code_name, true);
                            self.uploadFile(elm, code_name);
                            self.file_count++;
                            self.updateCounterFile();
                        } else {
                            $("#scene_track_premium").addClass("focus");
                            setTimeout(() => {
                                $("#scene_track_premium").removeClass("focus");
                            }, 2000);
                            $("#scene_track_counter_element").addClass("text-danger");
                        }
                    }
                    else {
                        self.previewFile(elm, code_name, false);
                    }
                });
            }
            else {
                if (!self.current_file_names.includes(elm.name)) {
                    //$("#file_track_format").addClass("text-danger").addClass("font-weight-bolder")
                }
            }
        });
        $("#scene_file").val("");
    }


    updateCounterFile() {
        if (this.file_count > 0) {
            $("#scene_track_counter_element").removeClass("d-none");
            $("#scene_track_counter").text(this.file_count);

            setTimeout(() => {
                if (this.file_count == 1) {
                    $("#single-track-scene-tooltip").removeClass("d-none");
                } else {
                    $("#single-track-scene-tooltip").addClass("d-none");
                }
            }, 1000);

            if (this.file_count >= this.track_limit) {
                $("#scene_track_counter_element").addClass("text-danger");
            } else {
                $("#scene_track_counter_element").removeClass("text-danger");
            }
        } else {
            $("#scene_track_counter_element").addClass("d-none");
        }
    }

    uploadFile(file, code_name) {
        let self = this;
        let fileSizeInBytes = file.size;
        let bearerToken = window.new_upload_bearer || null;
    
        $.ajax({
            url: window.application.getAPIUrl() + "/v1/users/prepare_track_upload",
            type: "GET",
            data: {
                type: "scene",
                file_size: fileSizeInBytes,
                new_upload_bearer: bearerToken
            },
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                if (bearerToken) {
                    request.setRequestHeader("Authorization", `Bearer ${bearerToken}`);
                }
            },
            success: function(response) {
                if (response.success) {
                    let formData = new FormData();
                    formData.append('imported_file', file);
                    formData.append('code_name', code_name);
                    formData.append('track_id', response.track_id);
    
                    if (bearerToken) {
                    console.log("Upload allowed. Required Credits: " + response.required_credits + ". User's balance: " + response.estimated_balance);
                    }
    
                    self.uploadingFiles.push({
                        code_name: code_name, 
                        ajax_call: $.ajax({
                            url: '/scene/ajax_upload_track_scene',
                            type: "post",
                            data: formData,
                            cache: false,
                            processData: false,
                            contentType: false,
                            enctype: 'multipart/form-data',
                            xhr: function () {
                                let myXhr = $.ajaxSettings.xhr();
                                if (myXhr.upload) {
                                    myXhr.upload.addEventListener('progress', function (e) {
                                        self.updateProgress(e, code_name);
                                    }, false);
                                }
                                return myXhr;
                            },
                            success: function (data) {
                                $("#" + code_name).addClass("can_delete");
    
                                self.players.push({ 
                                    code_name: code_name, 
                                    id: data['track_id'] }
                                );
    
                                $("#delete_scene_track_" + code_name).data("track-id", data['track_id']);
                                self.current_track_ids.push(data['track_id']);
                                self.current_file_names.push(data["file_name"]);
                                self.uploadingFiles = self.uploadingFiles.filter(upF => upF.code_name !== code_name);

                                if($("#user_balance").length) {
                                    let user_balance = parseInt(response.estimated_balance);
                                    if (!isNaN(user_balance)) {                                        
                                        $("#user_balance").attr("balance", user_balance);
                                        $("#user_balance").empty().append(user_balance);
                                    }
                                }
    
                                if (self.uploadingFiles.length === 0) {
                                    $("#create_scene_btn").prop("disabled", false);
                                    $("#create_scene_btn").addClass("btn-secondary");
                                    $("#create_scene_btn").removeClass("btn-outline-secondary");
                                }
                            },
                            error: function () {
                                console.error("Error while trying to upload file.");
                            }
                        })
                    });
                } else {
                    $("#progress_indeterminate_" + code_name).addClass("d-none"); 
                    $("#progress_" + code_name).addClass("d-none"); 
                    $("#go_checkout").removeClass("d-none");
                    $("#go_checkout").addClass("blink_helper");
                    $("#" + code_name).append('<div class="mt-3 col-12 text-danger text-center">' + I18n.t("website.layout.modals.add_activity.not_enought_credits", {
                        need_more_credits: response.required_credits,
                      }) + '</div>');
                    console.log("Can't upload file (" + (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " Mo). User's balance: " + response.estimated_balance);
                }
            },
            error: function() {
                console.error("Cannot check user's balance.");
            }
        });
    }
    
    updateProgress(evt, code_name) {
        if (evt.lengthComputable) {
            let percentComplete = evt.loaded / evt.total;
            $("#progress_indeterminate_" + code_name).addClass("d-none");
            $("#progress_" + code_name).removeClass("d-none")
            $("#progress_" + code_name + " div").css("width", percentComplete * 100 + '%')
        } else {
            $("#progress_" + code_name).addClass("d-none")
            $("#progress_indeterminate" + code_name).removeClass("d-none");
        }
    }


    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    previewFile(file, code_name, isValid) {
        let self = this;

        $("#scene_track_file_list").append(this.new_track_file(file.name, code_name, isValid));
        $("html, body").animate( {scrollTop:$('#' + code_name).position().top - 70}, 500);
        $("#delete_scene_track_" + code_name).on("click", function () {
            self.destroy_track(this, code_name, isValid);
        });

        if (isValid) {
            this.extract_player_name(file, code_name);
        }
    }

    extract_player_name(file, code_name) {
        var self = this;
        var freader = new FileReader();
        var playerName = null;
        freader.readAsText(file);
        freader.onload = function (e) {

            if (file.name.toLowerCase().endsWith(".igc")) {
                var igcContent = freader.result.split("\n", 25);

                var key1 = "HFPLTPILOTINCHARGE";
                var key2 = "HOPLTPILOT";
                var key3 = "HFPLTPILOT";
                var key4 = "HPPLTPILOT";
                for (var i = 0; i < igcContent.length; i++) {
                    var line = igcContent[i].split(":");
                    if ((line[0] == key1 || line[0] == key2 || line[0] == key3 || line[0] == key4) && line[1].length > 0 && line[1].trim().indexOf("None") == -1) {
                        playerName = line[1].replace(/[0-9]/g, "").replace("- ", "").trim();
                        $("#player_name_" + code_name).val(self.titleize(playerName));
                        break;
                    }
                }
            }

            if (playerName == null || playerName.length == 0) {
                self.playerIndex++;
                playerName = "Partenaire " + self.playerIndex;
                $("#player_name_" + code_name).val(playerName);
            }
        };

    }

    check_file(file, code_name, callback) {
        var freader = new FileReader();
        freader.readAsText(file);
        freader.onload = function (e) {
            var isValid = true;
            if (file.name.endsWith(".gpx")) {
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(freader.result, "text/xml");
                isValid = (xmlDoc.getElementsByTagName("gpx").length > 0);
            }
            else if (file.name.endsWith(".igc")) {
                isValid = false;
                var igcContent = freader.result.split("\n", 25);
                var key1 = "HFPLTPILOTINCHARGE";
                var key2 = "HOPLTPILOT";
                var key3 = "HFPLTPILOT";
                var key4 = "HPPLTPILOT";
                var key5 = "HFDTE";
                for (var i = 0; i < igcContent.length; i++) {
                    var line = igcContent[i].split(":");
                    if ((line[0] == key1 || line[0] == key2 || line[0] == key3 || line[0] == key4)) {
                        isValid = true;
                        break;
                    } else if (line[0].includes(key5)) {
                        isValid = true;
                          break;
                      }
                }
            }

            callback(file, code_name, isValid);
        };
    }

    new_track_file(name, code_name, is_valid) {
        let param_name = code_name;
        var html = [];
        if (is_valid) {
            html = [
                '<div id="' + param_name + '" class="col-12 mb-2 p-0">',
                '<div class="bg-light px-3 py-2 rounded">',
                '<div class="row">',
                '<div class="col-10 col-md-5 col-lg-3 order-1">',
                '<input class="fs-12" id="player_name_' + param_name + '" type="text" value="" />',
                '</div>',
                '<div class="col-12 col-md-6 col-lg-5 order-3 order-md-2 order-lg-2">',
                '<span class="fs-12">' + name + '</span>',
                '</div>',
                '<div class="col-12 col-lg-3 order-4 order-lg-3 mt-2 mt-lg-0">',
                '<div id="progress_indeterminate_' + param_name + '" class="progress-indeterminate cant_delete">',
                '<div class="indeterminate bg-warning"></div>',
                '</div>',
                '<div id="progress_' + param_name + '" class="progress d-none cant_delete" style="height: 4px;margin: 0.6rem 0 0.5rem 0;">',
                '<div class="progress-bar bg-secondary" role="progressbar"></div>',
                '</div>',
                '<div class="progress can_be_deleted progress-bar-success" style="height: 4px;margin: 0.6rem 0 0.5rem 0;">',
                '<div class="progress-bar" role="progressbar"></div>',
                '</div>',
                '</div>',
                '<div class="col-2 col-md-1 col-md-1 text-right order-2 order-md-3 order-lg-4">',
                '<a href="javascript:void(0)" id="delete_scene_track_' + param_name + '" data-element-id="' + param_name + '" data-name="' + name + '" data-track-id="" class="text-danger cursor-pointer"><i class="mdi mdi-close"></i> </a>',
                '</div>',
                '<div class="error_text"> Erreur au moment de suprimer la trace</div>',
                '</div>',
                '</div>',
                '</div>',

            ].join("\n");
        }
        else {
            html = [
                '<div id="' + param_name + '" class="col-12 mb-2">',
                '<div class="bg-light px-3 py-2 rounded">',
                '<div class="row">',
                '<div class="col-4">',
                '<span class="fs-12 font-weight-bolder">' + name + '</span>',
                '</div>',
                '<div class="col-7">',
                '<span class="fs-12 text-danger">' + I18n.t("website.layout.modals.add_activity.invalid_file_format") + '</span>',
                '</div>' +
                '<div class="col-1">' +
                '<div class="text-right">' +
                '<a href="javascript:void(0)" id="delete_scene_track_' + param_name + '" data-element-id="' + param_name + '" data-track-id="" data-name="' + name + '" class="text-danger cursor-pointer"><i class="mdi mdi-close"></i> </a>' +
                '</div>' +
                '<div class="error_text">Error while trying to remove tracks</div>',
                '</div>',
                '</div>',
                '</div>',
                '</div>'
            ].join("\n");
        }

        return html;
    }

    destroy_track(element, code_name, is_valid) {
        let self = this;
        let trackId = $(element).data("track-id");
        let elementId = $(element).data("element-id");        
        let name = $(element).data("name");
        let bearerToken = window.new_upload_bearer || null;
        
        function removeVisualElements() {
            $("#" + elementId).remove();
            self.current_file_names.splice(self.current_file_names.indexOf(name), 1);
            self.players = self.players.filter(x => x.code_name !== elementId);
            self.current_track_ids.splice(self.current_track_ids.indexOf(trackId), 1);
    
            if (self.current_track_ids.length === 0) {
                $("#create_scene_btn").prop("disabled", true);
                $("#create_scene_btn").removeClass("btn-secondary").addClass("btn-outline-secondary");
            }
            
            $("#scene_track_name").addClass("d-none");
            self.file_count--;
            self.updateCounterFile();
        }
    
        let objFile = this.uploadingFiles.find(upF => upF.code_name === elementId);
        if (objFile) {
            objFile.ajax_call.abort();
            removeVisualElements();
            this.uploadingFiles = this.uploadingFiles.filter(upF => upF.code_name !== elementId);
        } else if (is_valid && trackId && bearerToken) {
            $.ajax({
                url: window.application.getAPIUrl() + "/v1/users/restore_balance",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({
                    scene_track_id: trackId,
                    new_upload_bearer: bearerToken
                }),
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                success: function(response) {
                    console.log("User's balance restored. New estimated balance: ", response.estimated_balance);
                    if($("#user_balance").length) {
                        let user_balance = parseInt(response.estimated_balance);
                        if (!isNaN(user_balance)) {                                        
                            $("#user_balance").attr("balance", user_balance);
                            $("#user_balance").empty().append(user_balance);
                        }
                    }
                    proceedWithDeletion();
                },
                error: function(xhr, status, error) {
                    console.error("Error restoring user's balance: ", error);
                    proceedWithDeletion();
                }
            });
        } else {
            removeVisualElements(); 
        }
    
        function proceedWithDeletion() {
            $.ajax({
                url: "/scene/ajax_destroy_track_scene",
                type: "post",
                data: {
                    scene_track_id: trackId,
                    element_id: elementId
                },
                success: function(data) {
                    removeVisualElements();
                },
                error: function(data) {
                    window.$("#" + elementId).addClass("delete_error");
                }
            });
        }
    }

    parametarize(text) {
        return text.trim().toLowerCase().replace(/\s/g, "-").replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/g, "");
    }

    send_data() {
        let scene_name = $("#scene_name").val().trim();
        if (scene_name !== "") {
            $("#create_scene_btn").prop("disabled", true);
            $("#create_scene_btn").removeClass("btn-secondary");
            $("#create_scene_btn").addClass("btn-outline-secondary");
            let formData = new FormData();
            formData.append('scene[name]', scene_name);
            formData.append('scene[category_id]', $("#scene_category_id").val());
            formData.append('is_private', $("#private-switch-scene").is(":checked"));

            if (window.application.host_used == "birdtracking") {
                formData.append('is_birdtracking', true);
            }

            this.players.forEach(function (item) {
                formData.append('tracks[][scene_track_id]', item.id);
                formData.append('tracks[][user_name]', $("#player_name_" + item.code_name).val());
            });


            $.ajax({
                url: '/scene/',
                type: "POST",
                data: formData,
                cache: false,
                processData: false,
                contentType: false,
                enctype: 'multipart/form-data',
                success: function (data) {

                },
                error: function (data) {

                }
            })
        } else {
            $("#scene_name").addClass("border-danger");
        }

    }

    makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    titleize(sentence) {
        if (!sentence.split) return sentence;
        var _titleizeWord = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
            result = [];
        sentence.split(" ").forEach(function (w) {
            result.push(_titleizeWord(w));
        });
        return result.join(" ");
    }

}