import { HomeLiveMapMarkerManager } from "./HomeLiveMapMarkerManager";
import { PopupTemplates } from "./templates/livemap-popups/popup-templates";

export class TrackerMarkerManager extends HomeLiveMapMarkerManager {
  fetchData(bounds) {
    const ajaxData = {
      url: window.application.getAPIUrl() + "/v1/home/map_trackers",
      method: "GET",
      data: {
        sw_lat: bounds.sw_lat,
        sw_lng: bounds.sw_lng,
        ne_lat: bounds.ne_lat,
        ne_lng: bounds.ne_lng,
      },
    };

    $.ajax(ajaxData)
      .done(({ tracker_activities }) => {
        if (tracker_activities) {
          this.clearMarkers(tracker_activities);
          this.generateMarkers(tracker_activities);
        }
      })
      .fail((reason) => console.error(reason));
  }

  generateMarkers(trackers) {
    const currentMarkerIds = this.markers.map(({ id }) => id);

    const new_trackers = trackers.filter(
      (tracker) => !currentMarkerIds.includes(tracker.tracker_activity_id)
    );

    new_trackers.forEach((tracker) => {
      let icon_url = this.markerPointer;
      let icon_class = "is_tracker";

      let marker_icon = () => {
        return L.divIcon({
          html: this.markerGenerator(icon_url, tracker),
          shadowUrl: this.markerPointerShadow,
          className: icon_class,
          iconSize: [47, 59],
          shadowSize: [59, 33],
          shadowAnchor: [0, 30],
        });
      };

      const leafletMarker = L.marker(
        [tracker.locality_latitude, tracker.locality_longitude],
        {
          icon: marker_icon(),
        }
      );

      let markerObject = { id: tracker.tracker_activity_id, marker: leafletMarker };
      this.markers.push(markerObject);
      this.setLiveMarker(tracker);
      this.markerClusterGroup.addLayer(leafletMarker);

      leafletMarker.addOneTimeEventListener("click", () => {
        if (!leafletMarker.getPopup()) {
          this.generatePopups(tracker.tracker_activity_id);
        }
      });
    });
  }

  generatePopups(markerId, openLive = false) {
    const marker = this.markers.find((value) => value.id === markerId).marker;
    const liveMarker = this.liveMarkers.find((value) => value.id === markerId).marker;

    const popupPlaceholder = PopupTemplates.getPlaceholderPopup();
    const popupOptions = {
      direction: "top",
      offset: [0, -50],
      autoClose: false,
      closeOnClick: true,
      closeOnEscapeKey: false,
      closeButton: false,
      autoPan: false,
    };

    const popup = L.popup(popupOptions);
    const livePopup = L.popup(popupOptions);

    marker.bindPopup(popup);
    liveMarker.bindPopup(livePopup);

    if (openLive) {
      livePopup.setContent(popupPlaceholder);
      liveMarker.openPopup();
    } else {
      popup.setContent(popupPlaceholder);
      marker.openPopup();
    }

    this.updatePopup(markerId, popup, livePopup);
  }

  clearMarkers(trackers) {
    super.clearMarkers(trackers, "tracker_activity_id");
  }

  setLiveMarker(item) {
    const id = item.tracker_activity_id;

    const icon_class = "is_live";
    const icon_url = this.markerPointer;

    const marker_icon = () => {
      return L.divIcon({
        html:
          '<img src="' +
          icon_url +
          '" style="margin-top:-28px;height: 59px;width: 47px;"><div class="position-absolute w-100 text-center text-secondary fw-600 fs-16" style="top:-20px;left: 0;"><img class="rounded-circle" height="31" src="' +
          item.user_profile_image +
          '"></div>',
        shadowUrl: this.markerPointerShadow,
        className: icon_class,
        iconSize: [47, 59], // size of the icon 78*114
        shadowSize: [59, 33], // size of the shadow
        shadowAnchor: [0, 30], // the same for the shadow
      });
    };

    const leafletLiveMarker = L.marker([item.locality_latitude, item.locality_longitude], {
      icon: marker_icon(),
    });

    let markerObject = {
      id: id,
      marker: leafletLiveMarker,
      polyline: null,
      border: null,
    };

    this.liveMarkers.push(markerObject);
  }

  enableLive(tid, selected_tracks, uid) {
    const new_selected_marker = this.markers.find((x) => tid === x.id);
    const live_marker = this.liveMarkers.find((x) => tid === x.id)?.marker;

    if (!new_selected_marker || !live_marker) return;

    this.markerClusterGroup.removeLayer(new_selected_marker.marker);
    this.map.addLayer(live_marker);

    if (live_marker.getPopup()) {
      live_marker.openPopup();
    } else {
      this.generatePopups(tid, true);
    }

    this.selectedMarkers.push({
      id: new_selected_marker.id,
      marker: live_marker,
    });
  }

  disableLive(tid, selected_tracks) {
    const selectedMarker = this.selectedMarkers.find((x) => tid === x.id);
    if (!selectedMarker) return;

    const new_deselected_marker = this.markers.find((x) => tid === x.id).marker;

    const new_deselected_live_marker = selectedMarker.marker;

    this.selectedMarkers = this.liveMarkers.filter((x) =>
      selected_tracks.includes(x.id)
    );

    let pos = this.parent.last_selected_markers.indexOf(tid);
    if (pos >= 0) {
      this.parent.last_selected_markers.splice(pos, 1);
    }

    new_deselected_live_marker.closePopup();

    this.map.removeLayer(new_deselected_live_marker);

    if (new_deselected_marker !== null && new_deselected_marker !== undefined) {
      this.markerClusterGroup.addLayer(new_deselected_marker);
    }
  }

  updateLiveMarker(data) {
    const tid = data.tid;
    const updated_marker = this.liveMarkers.find((x) => tid === x.id)?.marker;

    if (!updated_marker) return;

    updated_marker.setLatLng([data.lat, data.lon]);
  }

  getPopup(item, isLive = false) {
    let google_url = `https://maps.google.com/?q=${item.locality_latitude},${item.locality_longitude}`;

    return PopupTemplates.getTrackerPopup({ ...item, google_url }, isLive);
  }

  updatePopup(trackerId, popup, livePopup) {
    const ajaxData = {
      url: window.application.getAPIUrl() + '/v1/home/get_tracker',
      method: 'GET',
      data: {
        activity_id: trackerId,
      },
    };

    $.ajax(ajaxData)
      .done((tracker) => {
        popup.setContent(this.getPopup(tracker));
        livePopup.setContent(this.getLivePopup(tracker));
      });
  }

  // * if we need another live popup
  getLivePopup(item) {
    return this.getPopup(item, true);
  }
}
