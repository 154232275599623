import ToastNotification from "../utils/ToastNotification";
import { NewRecipientTemplates } from "./templates/whatsapp/new-recipient-templates";

export default class ConfigurationPreferences {
    constructor() {
        this.bindEvents();
        if (application.host_used == "sportstracklive") {
            this.total_recipients = document.getElementById("user-record").getElementsByTagName("form").length;
        }
        //window.application.setOnDataChangeListener(this);
        $(document).ready(_ => {
            window.saveWhatsAppForm = (event) => {
                event.preventDefault()
                const data = Object.fromEntries(new FormData(event.target).entries());
                const phone = event.target.querySelector(".phone");
                var phone_instance = window.intlTelInputGlobals.getInstance(phone);
                data[`phone_number`] = phone_instance.getNumber()
                const isValid = phone_instance.isValidNumber()
                if (!window.application.isPremium()) {
                    ToastNotification.make(I18n.t("website.configuration.index.premium_subscription.premium_description"));
                } else if (isValid) {
                    const ajaxData = {
                        url: window.application.getAPIUrl() + "/v1/whatsapp",
                        method: "POST",
                        data: data,
                        beforeSend: function(request) {
                            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                        },
                    };
                    $.ajax(ajaxData)
                        .done(() => {
                            document.querySelector('#add_more_recipients').disabled = false;
                            const inputs = event.target.querySelectorAll("input")
                            inputs.forEach(function(inp) {
                                inp.setAttribute("readonly", "true");
                            })
                            $(".save-recipients.btn.btn-success").remove()
                            ToastNotification.make(I18n.t("website.configuration.index.whatsapp.success"));
                        })
                        .fail((err) => {
                            console.log(err);
                        });
                    } else {
                        ToastNotification.make(I18n.t("website.configuration.index.whatsapp.in_valid_num"));
                }
            }
        })

        $(document).ready(_ => {
            window.editAndDeleteWhatsAppForm = (event, form_id) => {
                event.preventDefault()
                const button_id = event.submitter.id
                const data = Object.fromEntries(new FormData(event.target).entries());
                const recipient_id = parseInt(event.submitter.attributes.data.value);                
                const phone = event.target.querySelector(".phone");
                const form_data = {
                    email: data["recipient["+ recipient_id +"][email]"],
                    first_name: data["recipient["+ recipient_id +"][first_name]"],
                    last_name: data["recipient["+ recipient_id +"][last_name]"]
                };

                var phone_instance = window.intlTelInputGlobals.getInstance(phone);
                form_data["phone_number"] = phone_instance.getNumber();
                const inputs = event.target.querySelectorAll("input")
                if(button_id.includes("edit")){
                    inputs.forEach(function(inp) {
                        inp.removeAttribute('readonly');
                    })
                    event.submitter.setAttribute("id", "update");
                    event.submitter.textContent = I18n.t("website.configuration.index.whatsapp.edit_recipient.update");
                }
                else if(button_id.includes("delete")){
                    const ajaxData = {
                        url: window.application.getAPIUrl() + `/v1/whatsapp/recipient/${recipient_id}`,
                        method: "DELETE",
                        data: form_data,
                        beforeSend: function(request) {
                            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                        }
                    };
                    $.ajax(ajaxData)
                        .done(() => {
                            $(`#form-div-${form_id}`).hide();
                            ToastNotification.make(I18n.t("website.configuration.index.whatsapp.delete_success"));
                        })
                        .fail((err) => {
                            console.log(err);
                    });
                }
                else if(button_id.includes("disable")){
                    const ajaxData = {
                        url: window.application.getAPIUrl() + `/v1/whatsapp/recipient/${recipient_id}/disable`,
                        method: "PUT",
                        data: form_data,
                        beforeSend: function(request) {
                            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                        }
                    };
                    $.ajax(ajaxData)
                        .done(() => {
                            $(`#disable-${form_id}`).addClass("d-none");
                            $(`#enable-${form_id}`).removeClass("d-none");
                            ToastNotification.make(I18n.t("website.configuration.index.whatsapp.disabled_success"));
                        })
                        .fail((err) => {
                            console.log(err);
                    });
                }
                else if(button_id.includes("enable")){
                    const ajaxData = {
                        url: window.application.getAPIUrl() + `/v1/whatsapp/recipient/${recipient_id}/enable`,
                        method: "PUT",
                        data: form_data,
                        beforeSend: function(request) {
                            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                        }
                    };
                    $.ajax(ajaxData)
                        .done(() => {
                            $(`#disable-${form_id}`).removeClass("d-none");
                            $(`#enable-${form_id}`).addClass("d-none");
                            ToastNotification.make(I18n.t("website.configuration.index.whatsapp.enabled_success"));
                        })
                        .fail((err) => {
                            console.log(err);
                    });
                }
                else if(button_id.includes("update")){
                    const isValid = phone_instance.isValidNumber()
                    if (isValid) {
                        const recipient_id = parseInt(event.submitter.attributes.data.value);
                        const ajaxData = {
                            url: window.application.getAPIUrl() + `/v1/whatsapp/recipient/${recipient_id}`,
                            method: "PUT",
                            data: form_data,
                            beforeSend: function(request) {
                                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                            },
                        };
                        $.ajax(ajaxData)
                            .done(() => {
                                inputs.forEach(function(inp) {
                                    inp.setAttribute("readonly", "true");
                                })
                                event.submitter.setAttribute("id", `edit-${form_id}`);
                                event.submitter.textContent = I18n.t("website.configuration.index.whatsapp.edit_recipient.edit");
                                ToastNotification.make(I18n.t("website.configuration.index.whatsapp.update_success"));
                            })
                            .fail((err) => {
                                console.log(err);
                            });
                        } else {
                            ToastNotification.make(I18n.t("website.configuration.index.whatsapp.in_valid_num"));
                    }
                }
            }
        })

    }

    bindEvents() {
        $("#send_privacy_settings")
        .off("click")
        .on("click", (e) => {
            e.preventDefault();
            this.sendPrivacySetting();
        });

        $("#send_preferences")
            .off("click")
            .on("click", (e) => {
                e.preventDefault();
                this.sendForm();
            });

        $("#send_garmin_preferences")
            .off("click")
            .on("click", (e) => {
                e.preventDefault();
                this.sendGarminForm();
            });
            
        $("#send_account_deletion")
            .off("click")
            .on("click", (e) => {
                e.preventDefault();
                this.sendAccountDeletionForm();
            });

        $("#garmin_integration")
            .off("click")
            .on("click", () => {
                const ajaxData = {
                    url: window.application.getAPIUrl() + "/v1/garmin/integration",
                    method: "GET",
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                };

                $.ajax(ajaxData)
                    .done((data) => {
                        window.open(data.connect_url, "_parent").focus();
                    })
                    .fail((err) => {
                        ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
                        console.log(err);
                    });
            });
            
        $("#suunto_integration")
            .off("click")
            .on("click", () => {
                const ajaxData = {
                    url: window.application.getAPIUrl() + "/v1/suuntos/integration",
                    method: "GET",
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    },
                };

                $.ajax(ajaxData)
                    .done((data) => {
                        window.open(data.connect_url, "_parent").focus();
                    })
                    .fail((err) => {
                        ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
                        console.log(err);
                    });
            });

        $("#suunto_deregistrations")
        .off("click")
        .on("click", () => {
            const ajaxData = {
                url: window.application.getAPIUrl() + "/v1/suuntos/deregistrations",
                method: "POST",
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData)
                .done((data) => {
                    window.open(data.redirect_url, "_parent").focus();
                })
                .fail((err) => {
                    ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
                });
        });
        
        $("#add_more_recipients")
        .on("click", () => {
            if (this.total_recipients >= 5) {
                ToastNotification.make(I18n.t("website.configuration.index.whatsapp.limit_exception"));
                document.querySelector('#add_more_recipients').disabled = true;
            } else {
                const newUser = NewRecipientTemplates.AddNewRecipient();
                $("#user-record").append(newUser);
                this.total_recipients++;
                const phoneInputFields = document.querySelectorAll(".phone");
                const new_phone = phoneInputFields[phoneInputFields.length - 1]
                window.intlTelInput(new_phone, ({
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
                    initialCountry: "auto",
                    geoIpLookup: function(callback) {
                        $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                        var countryCode = (resp && resp.country) ? resp.country : "us";
                        callback(countryCode);
                        });
                    },
                    preferredCountries: ["fr", "us", "gb", "es", "de", "ja"]
                }));
                document.querySelector('#add_more_recipients').disabled = true;
            }
        });

        $(".round-color-div")
        .on("click", (e) => {
            const color_container = e.target.id
            const border_indicator = "border border-danger rounded";
            const ajaxData = {
                url: window.application.getAPIUrl() + "/v1/users/change_track_color",
                method: "PUT",
                data: `color=${color_container}`,
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData)
                .done((data) => {
                    // Remove old check indicators
                    $(".round-color-div").html("");
                    $(".color-div-width").removeClass(border_indicator);
                    // Add check and border for new selected color
                    $(`#${color_container}`).html('<i class="fa fa-check"></i>');
                    $(`#color_${color_container}_container`).addClass(border_indicator);
                    ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
                })
                .fail((err) => {
                    ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
                    console.log(err);
                });
      });
    }
    onDataChanged(data) {}

    onDestroy() {}

    sendForm() {
        const data = {
            measure_unit: $("input[name='measure_unit']:checked").val(),
        };

        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/",
            data: {
                user: data,
            },
            method: "PUT",
            beforeSend: (request) => {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData)
            .done((data) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
            })
            .fail((err) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
            });
    }

    sendAccountDeletionForm() {
        const data = {
            ask_deletion: $("input[name='ask_deletion']:checked").val(),
        };

        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/account_deletion",
            data: {
                user: data,
            },
            method: "PUT",
            beforeSend: (request) => {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData)
            .done((data) => {
                window.location.href = window.application.getPremiumUrl();
                ToastNotification.make(I18n.t("website.configuration.index.message.account_deleted"));
            })
            .fail((err) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.no_change"));
            });
    }

    sendGarminForm() {
        const data = {
            default_import_privacy_type: $("input[name='import_privacy']:checked").val(),
        };

        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/",
            data: {
                user: data,
            },
            method: "PUT",
            beforeSend: (request) => {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData)
            .done((data) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
            })
            .fail((err) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
            });
    }

    sendPrivacySetting() {
        const data = {
            privacy_setting: $("input[name='privacy']:checked").val(),
        };

        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/update_privacy",
            data: {
                user: data,
            },
            method: "PUT",
            beforeSend: (request) => {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData)
            .done((data) => {
                window.location.href = window.location.href.replace( /[\?#].*|$/, "?tab=preferences" );
            })
            .fail((err) => {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
            });
    }
}
