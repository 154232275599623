export default class AskPremiumModal {

    static make(message = "", container = null){

        if(container !== null){
            let id_name = container.replace("#","_id_").replace(".","_class_");

            if(!$('#premium_modal'+id_name).length){
                $(container).append(AskPremiumModal.get_modal(message,id_name));
            }

            if(!($("#premium_modal"+id_name).data('bs.modal') || {_isShown: false})._isShown){
                $("#premium_modal"+id_name).modal("show");
                $("#premium_modal"+id_name).css("z-index","1020");
                $(container).css("position", "relative");
                $(container).css("overflow", "hidden");

                //appending modal background inside the blue div
                $("div").last().appendTo(container).attr("id", "backdrop_premium_modal"+id_name).css("position", "absolute").css("z-index","1000");
                $("#premium_modal"+id_name).css("position", "absolute");

                //remove the padding right and modal-open class from the body tag which bootstrap adds when a modal is shown
                $('body').removeClass("modal-open")
                $('body').css("padding-right","");
            }

            $("#premium_modal"+id_name).on("hidden.bs.modal",function(){
                $("#premium_modal"+id_name).remove();
                $("#backdrop_premium_modal"+id_name).remove();
            });
        }else{
            $("body").append(AskPremiumModal.get_modal(message));
            $("#premium_modal").modal("show");
        }

    }
    static get_modal(message, container = "") {
        return '<div id="premium_modal'+container+'" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">\n' +
            '      <div class="modal-dialog mt-7">\n' +
            '\n' +
            '        <!-- Modal content-->\n' +
            '        <div class="modal-content">\n' +
            '        <div class="modal-header py-1 bg-dark-light">\n' +
            '          <h4 class="modal-title text-white fs-16"><img height="36" class="mr-3" src="'+window.badge_outline_url+'">\n' +
            '            '+I18n.t("website.premium.modal_title")+'\n' +
            '          </h4>\n' +
            '          <button class="btn-close'+container+' close text-white" style="margin-top: -13px;opacity: 1;font-size: 30px;" data-dismiss="modal"><i class="mdi mdi-close"></i> </button>\n' +
            '        </div>' +
            '          <div class="modal-body ff-lato fs-16">\n' +
            message+
            '          </div>\n' +
            '          <div class="modal-footer">\n' +
            '            <button class="btn-close'+container+' btn btn-default ff-lato" data-dismiss="modal">'+I18n.t("website.premium.close")+'</button>\n' +
            '            <a href="'+ window.application.getPremiumUrl() +'" target="_blank" class="btn btn-secondary text-white ff-lato fw-800">'+I18n.t("website.premium.be_premium")+'</a>\n' +
            '          </div>\n' +
            '        </div>\n' +
            '\n' +
            '      </div>\n' +
            '    </div>';
    }

    static destroy_all(){
        $("div[id^=premium_modal]").remove();
        $("div[id^=backdrop_premium_modal]").remove();
        $(".modal-backdrop").remove();
    }

    static onDiscard(action, container= null, context = null){
        let id_name = container.replace("#","_id_").replace(".  ","_class_");
        $('.btn-close' + id_name).off("click").on("click", function (event) {
            action(event,context);
        });
    }
}