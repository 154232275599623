import CesiumManagerWrapper from "./CesiumManagerWrapper";
import Abstract3DMapHandler from "./Abstract3DMapHandler";
import PointsConverter from "./PointsConverter"

export default class LiveMap3D extends Abstract3DMapHandler {

    constructor(first_appear_ad = 100, frequency_ad = 100) {
        super(first_appear_ad, frequency_ad);

        this.socket_points = {}
        this.loaded_ids = [];
        this.lastAddedPoints = {};
        this.players = {};

        this.scene_type = "other";
        this.wind_bearing = 0;

        this.cesium_started = false;
        // this.start_timer();
    }

    start_timer() {
        super.start_timer("live_map");
    }

    async init(session) {
        this.tracks = session.tracks;
        this.scene_type = session.scene_type;
        this.wind_bearing = session.wind_bearing;

        $("#loading_live_modal").modal();

        await this.loadTracks();
    }

    async loadTracks() {
        this.displayLoadingProgress();

        const loading = this.tracks.map(x => this.requestBackTrack(x));
        await Promise.all(loading);
        await this.getAround();

        await this.startCesium();
    }

    requestBackTrack(track) {
        return new Promise((resolve, reject) => {
            const ajaxData = {
                url: window.application.getAPIUrl() + "/v1/users/" + track.user.id + "/track/" + track.id,
                method: "GET",
                data: {
                    type: "lite"
                },
                beforeSend: this.beforeSendHandler,
            };
            $.ajax(ajaxData).done((data) => {
                this.raw = data;
                const json = data.track == null ? decode(data) : data;

                this.points[track.id] = { 
                    points_header: json.track.points_header, 
                    points: json.track.points 
                };
                this.loaded_ids.push(track.id);
                resolve();
            }).fail((err) => reject(err));
        });
    }   

    async getAround() {
        await super.getAround(this.tracks[0].id);
    }

    async startCesium() {
        this.displayLoadingProgress();

        const userData = [];
        for (var i = 0; i < this.tracks.length; i++) {
            const track = this.tracks[i];
            const rawPointsData = this.points[track.id];

            const player = {
                tid: track.id,
                uid: track.user.id,
                uname: track.user.user_name,
                color: track.user.color,
                altitude_offset: 0,
                cesium_ready: true
            }

            const points_header = rawPointsData.points_header;

            if (points_header != null) {
                const points = [].concat(rawPointsData.points); 
                // remove first initial point (as usual it is the same as second one)
                points.shift();
            
                const socket_points = this.socket_points[track.id];
                if (socket_points != null && socket_points.length > 0) {
                    const rawSocketPoints = PointsConverter.convert_to_raw_points(socket_points, points_header);
                    points = points.concat(rawSocketPoints);
                }

                if (points.length == 0) {
                    continue;
                }

                rawPointsData.points = points;

                const track_point_id_header = points_header.findIndex(header => header === "track_point_id");
                player.point_id = points[points.length - 1][track_point_id_header];
                
                this.players[track.user.user_name] = player;
                userData.push({
                    userId: track.user.id,
                    userName: track.user.user_name,
                    profileUrl: track.user.user_url,
                    avatarUrl: track.user.profile_picture_url,
                    isDefault: (i == 0),
                    track: {
                        id: track.id,
                        color: track.user.color,
                        maxAltitude: 2000,
                        started_at: track.started_at,
                        created_at: track.created_at,
                        time_zone: track.time_zone,
                        activity_icon: track.activity_icon,
                        raw_points_data: rawPointsData 
                    }
                });
            }
        }

        await this.initScene(userData);
    }

    async initScene(userData) {
        await CesiumManagerWrapper.initScene("map3D", {
            users: userData,
            sceneType: this.scene_type,
            windBearing: this.wind_bearing,
            chartType: window.data.chart_type || null,
            hostInUse: window.data.host || null,
            activityIcon: window.data.activity_icon || null,
            endTrackOffset: window.data.end_track_offset || 10,
            offlineSetAfterSeconds: window.data.offline_set_after || 10,
            isTracksAlive: true,
            staticLabels: this.localities_around
        });
        this.cesium_started = true;

        setTimeout(() => {
            $("#loading_live_modal").modal("hide");
        }, 500);
    }

    async asyncAddUserToScene(player, hash) {
        await CesiumManager.addUserToScene(hash);
        player.cesium_ready = true;
    }

    isNewPointValid(currentPoint,lastAddedPoint) {
        if (!currentPoint) {
            return false;
        }

       if (lastAddedPoint == null) {
           return true;
       }
       // we need to send only ordered point data
       return currentPoint.tpid > lastAddedPoint.tpid && currentPoint.date > lastAddedPoint.date;
    }

    onPointReceived(data) {
        if ( !this.isNewPointValid(data,this.lastAddedPoints[data.uname])) {
            return;
        }

        this.lastAddedPoints[data.uname] = data;
        const point = {
            track_point_id: data.tpid,
            latitude: data.lat + "",
            longitude: data.lon + "",
            speed: data.speed + "",
            altitude: data.alt,
            computed_altitude: (data.alt),
            moving_type: data.moving_type,
            elevation: data.ele,
            bearing: data.bearing,
            distance: data.dist,
            date: data.date
        }

        if (this.cesium_started == true) {
            var player = this.players[data.uname];

            if (player == null) {
                var rgbColor = this.hexToRgb(data.ucolor);
                var rgbColorArray = [rgbColor.r, rgbColor.g, rgbColor.b];
                this.players[data.uname] = {
                    tid: data.tid,
                    uid: data.uid,
                    uname: data.uname,
                    color: rgbColorArray,
                    point_id: 0,
                    altitude_offset: 0,
                    cesium_ready: false
                }

                this.asyncAddUserToScene(this.players[data.uname], {
                    userId: data.uid,
                    userName: data.uname,
                    track: {
                        id: data.tid,
                        color: rgbColorArray,
                        started_at: moment.unix(data.date).toISOString().replace("Z", ""),
                        created_at: moment.unix(data.date).toISOString().replace("Z", ""),
                        points: [point]
                    }
                });

            }
            else if (player.cesium_ready == true) { 
                point.finalized = data.e == "track_end"
                CesiumManager.addPoint(data.uid, point)
                this.players[data.uname].point_id += 1;
            }
        }
        else {
            if (this.socket_points[data.tid] == null) {
                this.socket_points[data.tid] = [];
            }

            this.socket_points[data.tid].push(point);
        }
    }

    displayLoadingProgress() {
        $("#live_loading_progress_text").html(I18n.t("website.live.show.progress", { user_loaded: this.loaded_ids.length, total_users: this.tracks.length }));
        $("#live_loading_progress").css("width", Math.round(((this.loaded_ids.length / this.tracks.length) * 100)) + "%");
    }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
}