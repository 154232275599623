export default class AllLocalityTracks {

    constructor() {
        this.location_id = 0;
        this.activity_type = 0;
        this.order = 0;
        this.url = "";
        this.bindEvents();
    }

    bindEvents() {
        let self = this;

        $(".js-all_locality_tracks_filter").unbind("click").click(function (e) {
            e.preventDefault();
            self.setFilter(this);
        });

        $(".page-link").unbind("click").click(function (e) {
            e.preventDefault();
            if($(this).attr("href")!=null) {
                self.url =  $(this).attr("href");
                self.reloadTable();
            }
        })
    }


    onDataChanged(data){
        this.location_id = data.location_id;
        this.activity_type = data.activity_type;
        this.url =  "/localities/ajax_all_tracks/"+this.location_id;
        this.reloadTable();
    }

    onDestroy(){
        $(".js-all_locality_tracks_filter").unbind("click");
        $(".page-link").unbind("click")
    }


    setFilter(item) {
        let newOrder = parseInt($(item).data("order"))
        if (this.order !== newOrder){
            this.order = newOrder;
            this.url =  "/localities/ajax_all_tracks/"+this.location_id;
            $("#js-all_locality_tracks_btn").text($(item).data("name-hr"));
            this.reloadTable();
        }
    }

    reloadTable() {
        let self = this;
        $.ajax({
            url: this.url,
            type: "get",
            data: {
                order: this.order,
                activity_type: this.activity_type
            },
            success: function(html) {
                $("#tables-results").empty();
                $("#tables-results").append(html);
                $("#tables-results-loader").removeClass("d-flex");
                $("#tables-results-loader").addClass("d-none");
                self.bindEvents();
                window.application.reloadLazyLoad();
            },
            error: function(data) {
            }
        });
    }
}