export default class SocketHandler {

  constructor(consumer) {
    this.cable = consumer;

    this.channelHash = {};
    this.canRequest = false;
    this.isConnected = false;

    this.willAutoreconnect = false;

    $(document).off('socket-connected').off('socket-disconnected');
  }

  subscribe(opts) {
    const key = JSON.stringify(opts);
    
    const token = cookies.get("STL-Token");
    const channel = opts.channel;

    if (this.channelHash[key] == null) {
      this.channelHash[key] = this.cable.subscriptions.create({ ...opts, token }, {
        connected: () => {
          this._connected(channel);
          this._enableRequest();
        },
        disconnected: () => {
          this._disconnected(channel);
          this._disableRequest();
          this._autoreconnect();
        },
        received: (data) => {
          this._handleResponse(channel, data);
        }
      });
    }

    this._autoreconnect();
  }

  on(channel, callback) {
    $(document).on("socket-" + channel, callback);
  }
  
  off(channel) {
    $(document).off("socket-" + channel);
  }

  unsubscribe(opts) {
    const key = JSON.stringify(opts);
    this.channelHash[key] && this.cable.subscriptions.remove(this.channelHash[key]);
    this.channelHash[key] = null;
  }


  _connected(channel) {
    this.isConnected = true;
    $(document).trigger("socket-connected", {});

  }

  _autoreconnect() {
    var self = this;
    if (this.willAutoreconnect == false && this.cable.connection.disconnected) {
      this.willAutoreconnect = true;
      setTimeout(function () {
        if (self.cable.connection.disconnected) {
          self.cable.connection.open();
          self.willAutoreconnect = false;
          self._autoreconnect();
        }
        else {
          self.willAutoreconnect = false;
        }
      }, 5000)
    }
  }

  flushRequests() {
    /*
      If the queue is not empty, we send all request in the queue.
      It can happen if the user requested on the socket before being connected to it
      or if the connection was lost
    */
    if (this.queue.length > 0) {
      while (this.queue.length > 0) {
        this.send(this.queue.pop());

      }
    }
  }

  _disconnected(channel) {
    this.cable.disconnect();
    this.isConnected = false;
    this._disableRequest();
    $(document).trigger("socket-disconnected", {});
    //Do something when disconnected
  }

  _enableRequest() {
    this.canRequest = true;
  }

  _disableRequest() {
    this.canRequest = false;
  }

  send(channel, data) {
    if (this.cable.connection.disconnected == false && this.canRequest == true) {
      this.channelHash[channel].send(data);
    }
    else {
      //Not connected to the socket, so we put the request in the queue to send it later.
      this.queue.push(data);
    }
  }

  _handleResponse(channel, data) {
    $(document).trigger("socket-" + channel, data);
    // if(data.event=="admin-infos" && data.admin!=null) {
    //   this._enableRequest();
    //   this.flushRequests();
    // }
  }

  _hash64(opts) {
    var str = JSON.stringify(opts);
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
