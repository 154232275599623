
export default class PremiumModal {

    constructor() {
        this.lastExecutionTime = 0;
    }

    bindEvents() {
        if (window.application.host_used == "sportstracklive") {
            $(".checkout-url").unbind("click").click(function (e) {
                const productId = $(this).data("product-id");
                const priceId = $(this).data("price-id");
                var iframeUrl = window.fast_checkout_url;
                iframeUrl+= "?product_id=" + productId;
                iframeUrl+= "&price_id=" + priceId;

                $("#checkout-container").html('<iframe width="100%" height="100%" frameborder="0" background="transparent" src="'+ iframeUrl + '"></iframe>');            
                $("#checkout-container").removeClass("d-none");
                e.preventDefault();
            });
        } else if (window.application.host_used == "birdtracking") {
            $(".checkout-url").unbind("click").click(function (e) {
                const checkout_url = $(this).data("url");
                window.location = checkout_url;
            });
        }
    }

    closeModal() {        
        $("#premium_modal").modal("hide");    
    }

    showModalFromCesium() {
        console.log("Please do not try to Debug our code. Just subscribe to Help Us ❤️ Thanks");
        if (!window.application.isPremium()) {
            
            const currentTime = Date.now();
            const timeSinceLastExecution = currentTime - this.lastExecutionTime;
            const timeRemaining = 60000 - timeSinceLastExecution;

            if (currentTime - this.lastExecutionTime > 60000) {
                this.showModal();
                this.lastExecutionTime = currentTime;
            } else {
                console.log("Time left: " + timeRemaining / 1000 + " secondes");
            }
        }
    }

    showModal() {
        this.bindEvents();

        $("#premium_modal").modal({ backdrop: "static"});
    }
}