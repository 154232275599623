export default class LocalityBestTracksStandings {

    constructor(localityBestsScores) {
        this.localityBestsScores = localityBestsScores;
        this.bindEvents();
    }

    bindEvents(){
        let self = this;

        $('#best_scores_modal').unbind("show.bs.modal").on('show.bs.modal', function (e) {
            self.updateModalData(e);
        })
    }


    onDataChanged(data) {
    }


    onDestroy(){
        $('#best_scores_modal').unbind("show.bs.modal")
    }

    updateModalData(e){
        let button = $(e.relatedTarget);
        let type = button.data('type');

        $('#standigs-type-title').text(I18n.t("website.localities.index.scores.modal_title",{type: I18n.t("website.localities.index.scores.types."+type)}));
        $("#standings-loader").removeClass("d-none");
        $("#standings-loader").addClass("d-flex");
        $("#standings-scores-row").empty();

        $.ajax({
            url: '/localities/ajax_best_scores_standings/'+ this.localityBestsScores.location_id,
            type: "get",
            data: {
                activity_type:  this.localityBestsScores.activity_type,
                selected_range: this.localityBestsScores.selected_range,
                standing_type: type
            },
            success: function(html) {
                $("#standings-loader").removeClass("d-flex");
                $("#standings-loader").addClass("d-none");
                $("#standings-scores-row").append(html);
            },
            error: function(data) {
            }
        });
    }
}