export default class CompareTracks {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    reloadEvents(){
        $("a[id^=track_item_compare_]").unbind("click").click(function (e){
            e.preventDefault();
            let track_id = $(this).data("track-id");
            let url = $(this).data("url");
            let values = []
                $("input[name^=related_track_"+track_id+"]:checked").each(function(){
                    values.push($(this).val());
            });
            var s="";
            for (var i=0;i< values.length;i++)
            {
                s+="&compare_tracks[]="+values[i];
            }
            window.location = url+s;
        })
    }
}