export default class PointsConverter {
    static convert_points(points, headers, other_data = {}) {
        var convertedPoints = [];

        var lastWindData = null;

        for (var i = 0; i < points.length; i++) {
            var p = {};
            for (var j = 0; j < headers.length; j++) {
                p[headers[j]] = points[i][j];
            }

            if (other_data.wind_data != null && other_data.wind_data[p.track_point_id + ""] != null) {
                p["wind"] = other_data.wind_data[p.track_point_id + ""];
                lastWindData = p["wind"];
            }
            else if (lastWindData != null) {
                p["wind"] = lastWindData;
            }
            else {
                p["wind"] = {
                    bearing: -1,
                    speed: 0
                }
            }

            convertedPoints.push(p);
        }

        return convertedPoints;
    }

    static convert_to_raw_points(pointModels, headers) {
        return pointModels.map(pointModel => {
            const raw_point = Array.from({ length: headers.length }, (prop, index) => {
                const headerName = headers[index]

                return pointModel[headerName];
            });

            return raw_point;
        });
    }
}