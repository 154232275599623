export class NewRecipientTemplates {
    static AddNewRecipient() {
        return (
            '<form onSubmit="saveWhatsAppForm(event)" class="whatsapp_form">' +
                '<div class="row mb-2">' +
                    '<div class="col-3">' +
                        '<label class="mt-1 py-2 fs-14">' + I18n.t("website.configuration.index.edit_profile.first_name") + '</label>' +
                    '</div>' +
                    '<div class="col-9 col-md-6">' +
                        '<input type="text" id="first_name" name="first_name" value="" class="form-control form-control-lg" required/>' +
                    '</div>' +
                '</div>' +
                '<div class="row mb-2">' +
                    '<div class="col-3">' +
                        '<label class="mt-1 py-2 fs-14">' + I18n.t("website.configuration.index.edit_profile.last_name") + '</label>' +
                    '</div>' +
                    '<div class="col-9 col-md-6">' +
                        '<input type="text" id="last_name" name="last_name" value="" class="form-control form-control-lg" required/>' +
                    '</div>' +
                '</div>' +                
                '<div class="row mb-2">' +
                    '<div class="col-3">' +
                        '<label class="mt-1 py-2 fs-14">' + I18n.t("website.configuration.index.whatsapp.edit_recipient.phone_number") + '</label>' +
                    '</div>' +
                    '<div class="col-9 col-md-6">' +
                        '<input class="phone form-control form-control-lg" type="tel" name="phone_number" value="" required/>' +
                    '</div>' +
                '</div>' +
                '<div class="row mb-2">' +
                    '<div class="col-3">' +
                        '<label class="mt-1 py-2 fs-14">' + I18n.t("website.configuration.index.whatsapp.edit_recipient.email") + '</label>' +
                    '</div>' +
                    '<div class="col-9 col-md-6">' +
                        '<input type="email" id="email" name="email" value="" class="form-control form-control-lg"/>' +
                    '</div>' +
                '</div>' +
                '<div class="text-center">' +
                    '<button type="submit" class="save-recipients btn btn-success">' + I18n.t("website.configuration.index.whatsapp.edit_recipient.save") + '</button>' +
                '</div>' +
            '</form><hr />'

        );
    }
}
