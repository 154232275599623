import ConfigurationProfile from "../views/ConfigurationProfile";
import ConfigurationPremium from "../views/ConfigurationPremium";
import ConfigurationPreferences from "../views/ConfigurationPreferences";
import ConfigurationNotifications from "../views/ConfigurationNotifications";
import ConfigurationChangeEmail from "../views/ConfigurationChangeEmail";
import ConfigurationFederationFrancaiseVolLibre from "../views/ConfigurationFederationFrancaiseVolLibre";
import ConfigurationPureTrack from "../views/ConfigurationPureTrack";



export default class ConfigurationIndex {

    constructor() {
        this.configurationProfile = new ConfigurationProfile();
        this.configurationChangeEmail = new ConfigurationChangeEmail();
        this.ConfigurationFederationFrancaiseVolLibre = new ConfigurationFederationFrancaiseVolLibre();
        this.ConfigurationPureTrack = new ConfigurationPureTrack();
        this.configurationPremium = new ConfigurationPremium();
        this.configurationPreferences = new ConfigurationPreferences();
        this.configurationNotifications = new ConfigurationNotifications();
        window.application.setOnDataChangeListener(this);
        this.selectTab();
    }

    bindEvents() {
    }

    onDataChanged(data) {
        this.configurationProfile.onDataChanged(data);
        this.configurationPremium.onDataChanged(data);
        this.configurationChangeEmail.onDataChanged(data);
        this.ConfigurationFederationFrancaiseVolLibre.onDataChanged(data);
        this.ConfigurationPureTrack.onDataChanged(data);
    }

    onDestroy() {
        this.configurationProfile.onDestroy();
        this.configurationPremium.onDestroy();
        this.configurationChangeEmail.onDestroy();
        this.ConfigurationFederationFrancaiseVolLibre.onDestroy();
        this.ConfigurationPureTrack.onDestroy();
    }

    selectTab() {
        let url = document.location.toString();
        if (url.match('tab=')) {
            window.$('a[href="#' + url.split('tab=')[1] + '"]').tab('show');
        } else {
            window.$('a[href="#profile"]').tab('show');
        }
    }
}