import TrackMap from "../controllers/TrackMap";
import "chartjs-plugin-annotation";
import "moment-timezone";

export default class TrackDetail {

	MILE_IN_KILOMETER = 1.60934;
	NAUTIC_MILE_IN_KILOMETER = 1.852;
	METER_IN_FEET = 3.28084;
	currentGraphType = 'heart-rate';
	chartLabels = {};
	constructor() {

		this.data = null;
		this.points_header = null;
		this.points_header_withData = [];
		this.points = [];
		this.chart = null;
		this.hover_time = null;
		this.selected_time = null;
		this.mapHandler = null;
		this.step = 0;
		this.current_index = 1;
		this.current_points = [];
		this.position_tooltip_pointer = "left";
		this.user_unit_system = "measure_metric";
		this.ignore_altitude = false;

		this.distanceConversion = 1;
		this.speedConversion = 1;

		this.bindEvents();
	}

	bindEvents() {
		var self = this;
		let clickDisabled = false;

		$('.card .collapse').on('click', function() {
			clickDisabled = true;
		
			setTimeout(function() {
				clickDisabled = false;
			}, 3000);
		});

		$('[data-toggle="collapse"]').on('mouseenter', function() {
			if (!clickDisabled) {
				$(this).parents('.card').find('.collapse').collapse('show');
			}
		});

		$("#show_3d").unbind("click").click(function () {
			window.location.href = window.location.href + "?mode=3D";
		});

		$(".img-to-graph").unbind("click").click(function (e) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: 0 }, 1200, 'easeInOutCubic');
			window.location.hash = 'graph-map';
		});

		$(".graph-to-img").unbind("click").click(function (e) {
			e.preventDefault();
			$('html,body').animate({scrollTop: $("a[name='images_listing']").offset().top}, 1200, 'easeInOutCubic');
			window.location.hash = 'images_listing';
		});

		$("#clear-graph-selection-btn").unbind("click").click(function (e) {
			e.preventDefault();
			self.displayGraph(self.current_points, null, null, true);
		});

		$(".peak-speed-btn").unbind("click").click(function (e) {
			e.preventDefault();
			this.current_points = self.points.slice($(this).data("pointStart"), $(this).data("pointEnd"));
			self.mapHandler.updatePoints(this.current_points);
			self.displayGraph(this.current_points, $(this).data("title"), null, true);
		});

		$(".performance-btn").unbind("click").click(function (e) {
			e.preventDefault();
			this.current_points = self.points.slice($(this).data("pointStart"), $(this).data("pointEnd"));
			self.mapHandler.updatePoints(this.current_points);
			self.displayGraph(this.current_points, $(this).data("title"), null, true);
		});

		$(".split-btn").unbind("click").click(function (e) {
			e.preventDefault();
			this.current_points = self.points.slice($(this).data("pointStart"), $(this).data("pointEnd"));
			self.mapHandler.updatePoints(this.current_points);
			self.displayGraph(this.current_points, $(this).data("title"), null, true);
		});

		$(".graph_switcher").unbind("click").click(function (e) {
			e.preventDefault();
			if (!self.chart || !self.chart.data) {
				return;
			}
			const type = $(this).data("graph-type");
			if (type != self.currentGraphType) {
				self.currentGraphType = type;
				const points = self.data.points;					
				self.displayGraph(points, null, null, false);
				$('.graph_switcher.btn-secondary').removeClass('btn-secondary').addClass('btn-light');
				$(this).removeClass('btn-light').addClass('btn-secondary');
			}
		});

		var trackGraph = document.getElementById("track-graph");

		if (trackGraph != null) {
			trackGraph.onclick = function (evt) {
				self.selected_time = self.hover_time;
				self.chart.options.annotation.annotations[0].value = self.selected_time;
				self.chart.update();
			
				let index = parseInt(self.step * self.current_index);
				$("#replay-position").val(index);

				self.mapHandler.onChartClick(index);
			};
		}

		window.application.setOnDataChangeListener(this);
	}

	updateUnitConversion() {
		this.distanceConversion = 1;
		this.speedConversion = 1;
		this.alternateDistanceConversion = 1;

		if (this.user_unit_system == "measure_imperial") {
			this.distanceConversion = 1.0 / this.MILE_IN_KILOMETER;
			this.alternateDistanceConversion = this.METER_IN_FEET;
			this.speedConversion = 1.0 / this.MILE_IN_KILOMETER;
		}
		else if (this.user_unit_system == "measure_nautic") {
			this.distanceConversion = 1.0 / this.NAUTIC_MILE_IN_KILOMETER;
			this.alternateDistanceConversion = this.METER_IN_FEET;
			this.speedConversion = 1.0 / this.NAUTIC_MILE_IN_KILOMETER;
		}
	}

	getSpeedUnit() {
		if (this.user_unit_system == "measure_imperial") {
			return "mi/h";
		}
		else if (this.user_unit_system == "measure_nautic") {
			return I18n.t("website.units.knots");
		}
		else {
			return "km/h";
		}
	}

	getDistanceUnit() {
		if (this.user_unit_system == "measure_imperial") {
			return "mi";
		}
		else if (this.user_unit_system == "measure_nautic") {
			return "nmi";
		}
		else {
			return "km";
		}
	}

	getAlternateDistanceUnit() {
		if (this.user_unit_system == "measure_imperial") {
			return "ft";
		}
		else if (this.user_unit_system == "measure_nautic") {
			return I18n.t("website.units.knots");
		}
		else {
			return "m";
		}
	}

	onDataChanged(data, isExternal = false) {
		this.data = data;
        if (!data) {
            return ;
        }
		this.mode = data.mode;
		this.ignore_altitude = data.ignore_altitude;
		this.user_unit_system = data.user_unit_system;

		this.updateUnitConversion();

		if (this.data.mode == "3D") {
			this.mapHandler = new TrackMap();
			this.mapHandler.onDataChanged(data, this, isExternal);
		}
		else {

			this.getTrack(this.data.track);
		}
	}

	onPointsReceived(points) {
		this.current_points = points;
		this.data.points = points;

		if (this.data.mode == "2D") {
			this.mapHandler = new TrackMap();
			this.mapHandler.onDataChanged(data, this);
			this.checkVisibilityGraphSwitchers();
			this.setDefaultGraph();
			this.displayGraph(this.current_points, null, null, false);
		}
	}

	getTrack(track) {
		var self = this;
		if (!track || !track.user) {
			return;
		}
		var ajaxData = {
			url: window.application.getAPIUrl() + "/v1/users/" + track.user.id + "/track/" + track.id + "?type=final",
			method: "GET",
			beforeSend: function (request) {
				request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
			},
		};

		$.ajax(ajaxData).done(function (data) {
			var json = data;
			self.points_header = json.points_header;
			self.points = self.convert_points(json.points);
			self.data.utc_offset = -(moment.unix(self.points[0].date).tz(json.time_zone).utcOffset());
			self.onPointsReceived(self.points);
		});
	}

	convert_points(points) {
		this.points_header_withData = [];
		const self = this;
		const convertedPoints = points.map((point) => {
			var convertedPoint = {};
			self.points_header.forEach((header,headerIndex) => {
				convertedPoint[header] = point[headerIndex];
				if (header == "speed" && self.user_unit_system != "measure_metric") {
					convertedPoint[header] = Math.round(point[headerIndex] * self.speedConversion * 100) / 100;
				}
				else if ((header == "altitude" || header == "elevation") && self.user_unit_system != "measure_metric") {
					convertedPoint[header] = Math.round(point[headerIndex] * self.alternateDistanceConversion);
				}

				if (point[headerIndex] && !self.points_header_withData.includes(header)) {
					self.points_header_withData.push(header);
				}
			});			
			return convertedPoint;			
		});
		return convertedPoints;
	}

	checkVisibilityGraphSwitchers() {		
		const _self = this;
		$(".graph_switcher").each((i, x) => {
			if (!$(x).hasClass('d-none')) {
				$(x).addClass('d-none');
			}
			
			const graphType = $(x).data("graph-type");
			const datasetDefinition = _self.graphDefinitions[graphType]

			if (datasetDefinition) {
				const visible = datasetDefinition.dataSets.some(x => _self.points_header_withData.includes(x.property))
				if (visible) {
					$(x).removeClass('d-none');
				}
			}
		});
	}

	setDefaultGraph() {
		const _self = this;
		$(".graph_switcher").each((i, x) => {
			const graphType = $(x).data("graph-type");
			const isHidden = $(x).hasClass('d-none');
			if (isHidden && _self.currentGraphType == graphType) {
				_self.currentGraphType = '';
			}
		});
		if (!_self.currentGraphType) {
			_self.currentGraphType = 'altitude';
		}

		$(`.graph_switcher[data-graph-type='${_self.currentGraphType}']`).removeClass('btn-light').addClass('btn-secondary');		
	}

	displayGraph(_points_, title, anotation_value = null, scrollTop = false) {
		if (scrollTop) {
			$('html, body').animate({ scrollTop: 0 }, 600, 'easeInOutCubic');
		}
		const self = this;

		if (anotation_value === null) {
			anotation_value = self.selected_time || (_points_[0].date + self.data.utc_offset) * 1000;
		}
		var isSubPoint = (_points_.length < this.points.length)
		var time_step = Math.round(_points_.length / 6);

		if (_points_.length > 1000) {
			this.step = Math.round(_points_.length / 1000);
			var aux_points = [];

			for (let i = 0; i < _points_.length; i += this.step) {
				aux_points.push(_points_[i]);
			}
			_points_ = aux_points;
		} else {
			this.step = 1;
		}
		this.current_points = _points_;
		if (title != null) {
			$("#track-graph-title-container").removeClass("d-none");
		}
		else {
			$("#track-graph-title-container").addClass("d-none");
		}
		$("#track-graph-title").html(title != null ? title : "");
		$("#track-graph").css("height", 200);
		var ctx = document.getElementById('track-graph').getContext('2d');
		if (this.chart != null) {
			this.chart.destroy();
		}

		Chart.defaults.LineWithLine = Chart.defaults.line;
		Chart.controllers.LineWithLine = Chart.controllers.line.extend({
			draw: function (ease) {
				Chart.controllers.line.prototype.draw.call(this, ease);

				if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
					var activePoint = this.chart.tooltip._active[0],
						ctx = this.chart.ctx,
						x = activePoint.tooltipPosition().x,
						topY = this.chart.scales['B'].top,
						bottomY = this.chart.scales['B'].bottom;

					self.current_index = activePoint._index;
					self.hover_time = this.chart.chart.data.labels[activePoint._index]._i;
					// draw line
					ctx.save();
					ctx.beginPath();
					ctx.moveTo(x, topY);
					ctx.lineTo(x, bottomY);
					ctx.lineWidth = 1;
					ctx.strokeStyle = '#07C';
					ctx.stroke();
					ctx.restore();

					self.mapHandler.onChartHover(self.step * self.current_index);
				}
			}
		});
		var firstPointDate = _points_[0].date + self.data.utc_offset;
		var lastPointDate = _points_[_points_.length - 1].date + self.data.utc_offset;
		var diffPointsDate = lastPointDate - firstPointDate;

		let displayFormat;
		let diffHours = diffPointsDate / 3600;

		if (diffHours > 4) {
			displayFormat = moment.localeData().longDateFormat('L') + " HH:mm";
		} else if (diffHours > 1) {
			displayFormat = 'HH:mm'; 
		} else {
			displayFormat = 'HH:mm:ss'; 
		}

		Chart.Tooltip.positioners.custom = function (elements, position) {
			if (!elements.length) {
				return false;
			}
			var offset = 0;
			// Adjust offset left or right depending on the event position
			if (elements[0]._chart.width * 0.7 > position.x) {
				offset = 0;
				self.position_tooltip_pointer = "left";
			} else {
				offset = -170;
				self.position_tooltip_pointer = "right";
			}
			return {
				x: position.x + offset,
				y: position.y
			}
		}
		const graphDefinition = this.getCurrentGraphDefinition();
		const speedDatasetDefinition = this.getSpeedDatasetDefinition();
		const dataSets = [this.getDataSet(speedDatasetDefinition, (x) => (x * 3.6).toFixed(2))];
		graphDefinition.dataSets.forEach(x => {
			dataSets.push(this.getDataSet(x));
		});

		this.chart = new Chart(ctx, {
			type: "LineWithLine",
			data: {
				labels: _points_.map(a => moment.unix(a.date + self.data.utc_offset)),
				datasets: dataSets
			},
			// Configuration options go here
			options: {
				animation: {
					duration: 5000, 
					easing: 'easeOutQuart' ,
				},
				hover: {
					animationDuration: 100
				},
				events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
				legend: { display: false },
				tooltips: {
					position: 'custom',
					intersect: false,
					enabled: false,
					mode: "index",
					callbacks: {
						label: function (tooltipItem, data) {
							const index = tooltipItem.datasetIndex;
							return `${data.datasets[index].label}: <span class="fw-600 index_marker"> ${tooltipItem.yLabel}</span>${data.datasets[index].measureUnit}`;
						}
					},
					custom: function (tooltipModel) {
						// Tooltip Element
						var tooltipEl = document.getElementById('chartjs-tooltip');

						// Create element on first render
						if (!tooltipEl) {
							tooltipEl = document.createElement('div');
							tooltipEl.id = 'chartjs-tooltip';
							tooltipEl.innerHTML = '<table></table>';
							document.body.appendChild(tooltipEl);
						}

						// Hide if no tooltip
						if (tooltipModel.opacity === 0) {
							tooltipEl.style.opacity = 0;
							return;
						}

						self.tooltip_node = tooltipEl;

						// Set caret Position
						tooltipEl.classList.remove('above', 'below', 'no-transform');
						if (tooltipModel.yAlign) {
							tooltipEl.classList.add(tooltipModel.yAlign);
						} else {
							tooltipEl.classList.add('no-transform');
						}

						function getBody(bodyItem) {
							return bodyItem.lines;
						}

						// Set Text for tooltip
						if (tooltipModel.body) {							
							var titleLines = tooltipModel.title || [];
							var bodyLines = tooltipModel.body.map(getBody);

							var innerHtml = '<div>';							

							titleLines.forEach(function (title) {
								innerHtml += '<div><h6 class="fs-16 fw-400 ff-lato">' + title +  '</h6></div>';
							});
							innerHtml += '</div><div id="custom_tooltip" class="' + self.position_tooltip_pointer + '-pointer position-relative py-2 px-3" style="min-width:140px; background-color: rgba(40, 43, 52, 0.75); border-radius: 5px; color: white;">';

							bodyLines.forEach(function (body, i) {
								innerHtml += '<div><span class="fs-14">' + body + '</span></div>';
							});

							if (tooltipModel.dataPoints) {
								var dataIndex = tooltipModel.dataPoints[0].index; 
								var datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
								var trackPointId = dataSets[datasetIndex].data[dataIndex].track_point_id;
						
								innerHtml += '<div><span class="fs-14">Point Id: <span class="fw-600 index_marker"> ' + new Intl.NumberFormat(I18n.locale).format(trackPointId) + '</span></span></div>';
							}

							innerHtml += '</div>';

							var tableRoot = tooltipEl.querySelector('table');
							tableRoot.innerHTML = innerHtml
						}

						// `this` will be the overall tooltip
						var position = this._chart.canvas.getBoundingClientRect();

						// Display, position, and set styles for font
						tooltipEl.style.opacity = 1;
						tooltipEl.style.position = 'absolute';
						tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
						tooltipEl.style.top = position.top + window.pageYOffset + 'px';
						tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
						tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
						tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
						tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
						tooltipEl.style.pointerEvents = 'none';
					}
				},
				annotation: {
					annotations: [
						{
							drawTime: "afterDatasetsDraw",
							type: "line",
							mode: "vertical",
							scaleID: "x-axis-0",
							value: anotation_value,
							borderWidth: 1,
							borderColor: "red",
						}
					]
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							type: 'time',
							gridLines: {
								display: false,
							},
							time: {
								round: true,
								minUnit: 'second',
								unit: 'second',
								tooltipFormat: displayFormat,
								stepSize: time_step,
								displayFormats: {
									second: displayFormat
								},
							},
							ticks: {
								padding: -5,
								autoSkip: false,
								maxTicksLimit: 15,
								fontColor: "#282B34",
								callback: function (value, index, values) {
									if (index !== 0 && (values[index].value < ((lastPointDate - (0.1 * diffPointsDate)) * 1000))) {
										return value;
									} else {
										return "";
									}
								},
							}
						}
					],
					yAxes: [{
						stacked: true,
						id: 'A',
						type: 'linear',
						position: 'left',
						gridLines: {
							color: "#ced4da"
						},
						ticks: {
							beginAtZero: false,
							padding: -5,
							max: graphDefinition.dataSets[0].max,
							min: graphDefinition.dataSets[0].min,
							mirror: true,
							stepSize: graphDefinition.step,
							callback: function (value, index, values) {
								return value + graphDefinition.dataSets[0].unit;
							},
							fontColor: "#282B34",
						}
					},
					{
						id: 'B',
						type: 'linear',
						position: 'right',
						gridLines: {
							display: false,
						},
						ticks: {
							minor: {
								display: "top"
							},
							major: {
								display: "top"
							},
							padding: -5,
							mirror: true,
							beginAtZero: (!isSubPoint),
							stepSize: 20,
							callback: function (value, index, values) {
								return value + speedDatasetDefinition.unit;
							},
							fontColor: "#282B34"
						}
					}
					]
				}
			},
			plugins: [{
				beforeDraw: function (data) {
					self.drawYAxisBackground(self,data, graphDefinition.backgroundRules);
				},
				afterDraw: function (data) {
					self.drawLabels(self,data);
				}
			}],
		});
	}

	drawLabels(self,data) {
		const labels = self.chartLabels[self.currentGraphType];
		if (labels && labels.length) {
			var ctx = data.chart.ctx;
			ctx.font = "12px Lato";
			ctx.textAlign = "start";
			ctx.textBaseline = "bottom";
			ctx.fillStyle = "#000000A0"

			labels.forEach(x => {
				ctx.fillText(x.text, 65, x.yPos);
			})
		}
	}

	drawYAxisBackground(self,data, backgroundRules) {
		if (!backgroundRules) {
			return;
		}
		self.chartLabels[self.currentGraphType] = [];
		const labels = self.chartLabels[self.currentGraphType];
		var ctx = data.chart.ctx;

		var rules = backgroundRules;
		var yaxis = data.chart.scales["A"];
		var xaxis = data.chart.scales["x-axis-0"];
		const maxYValue = yaxis.max;
		const minYValue = yaxis.min;
		if (maxYValue <= minYValue) {
			return;
		}

		var color = ctx.createLinearGradient(xaxis.left + (xaxis.width - xaxis.left) / 2, yaxis.top + yaxis.height, xaxis.left + (xaxis.width - xaxis.left) / 2, yaxis.top);


		for (var i = 0; i < rules.length; i++) {
			const rule = rules[i];
			if (i == 0) {
				color.addColorStop(0, rule.backgroundColor);
			}
			let ruleValue = rule.yAxisSegment < minYValue ? minYValue : rule.yAxisSegment;
			ruleValue = ruleValue > maxYValue ? maxYValue : ruleValue;
			const normPoint = (ruleValue - minYValue) / (maxYValue - minYValue);
			color.addColorStop(normPoint, rule.backgroundColor);
			if (i == rules.length - 1) {
				color.addColorStop(1, rule.backgroundColor);
			}
			if (rule.label) {
				labels.push({ text: rule.label, yPos: Math.round(yaxis.top + yaxis.height * (1 - normPoint)) + 5 });
			}

		}
		ctx.fillStyle = color;
		ctx.fillRect(xaxis.left, yaxis.top, xaxis.width, yaxis.height);
	}

	getDataSet(datasetDefinition, mappingFunction) {
		return {
			lineTension: 0,
			steppedLine: datasetDefinition.steppedLine ? datasetDefinition.steppedLine : false,
			hidden: false,
			// type: datasetDefinition.type ? datasetDefinition.type : 'line',
			min: 20,
			max: 30,
			yAxisID: datasetDefinition.yAxis,
			label: datasetDefinition.label,
			pointBackgroundColor: datasetDefinition.pointBackgroundColor,
			borderColor: datasetDefinition.borderColor,
			backgroundColor: datasetDefinition.backgroundColor ? datasetDefinition.backgroundColor : 'rgb(245, 246, 247, 0.5)',
			pointRadius: 0,
			borderWidth: datasetDefinition.borderWidth ? datasetDefinition.borderWidth : 1,
			pointHoverRadius: 0,
			data: this.current_points.map(a => ({
				x: moment.unix(a.date).toDate(), 
				y: mappingFunction ? mappingFunction(a[datasetDefinition.property]) : a[datasetDefinition.property],
				track_point_id: a.track_point_id 
			})),
			parsing: {
				xAxisKey: 'x',
				yAxisKey: 'y'
			},
			order: datasetDefinition.order,
			borderDash: datasetDefinition.borderDash ? datasetDefinition.borderDash : false,
			fill: datasetDefinition.fill ? datasetDefinition.fill : false,
			measureUnit: datasetDefinition.unit
		}

	}
	onTick(currentPosition) {
		var step = this.step;
		if (this.current_points > 1000) {
			step = step * 2;
		}
		if (currentPosition % step === 0) {
			let index = parseInt(currentPosition / step);
			if (index < this.current_points.length) {
				this.chart.options.annotation.annotations[0].value = (this.current_points[index].date + this.data.utc_offset) * 1000;
				this.chart.update();
			}
		}
	}

	onDestroy() {
		this.mapHandler && this.mapHandler.onDestroy();

		$("#clear-graph-selection-btn").unbind("click");
		$(".peak-speed-btn").unbind("click");
		$(".performance-btn").unbind("click");
		$(".split-btn").unbind("click");
		$(".graph_switcher").unbind("click");
	}

	getCurrentGraphDefinition() {
		const graphType = this.currentGraphType;
		return this.graphDefinitions[graphType];
	}
	getSpeedDatasetDefinition() {
		return this.graphDefinitions['speed'].dataSets[0];
	}
	graphDefinitions = {		
		'cadence': {
			step: 25,
			steppedLine: true,
			dataSets: [
				{
					type: 'bar',
					yAxis: 'A',
					steppedLine: 'before',
					label: I18n.t('website.track.chart.cadence'),
					pointBackgroundColor: 'rgb(35, 180, 241)',
					borderColor: 'rgb(50, 50, 64)',
					borderWidth: 1,
					property: 'cadence',
					unit: ''
				}
			],
			backgroundRules: [{
				backgroundColor: '#669999',
				yAxisSegment: 1
			},
			{
				backgroundColor: '#FFAAAA',
				yAxisSegment: 360
			}
			]
		},
		'heart-rate': {
			step: 25,
			dataSets: [
				{
					min: 40,    
					max: 200,
					yAxis: 'A',
					label: I18n.t('website.track.chart.heart-rate'),
					pointBackgroundColor: 'rgb(35, 120, 200)',
					borderColor: 'rgb(135, 120, 200)',
					property: 'heart_rate',
					unit: 'BPM'
				}
			],
			backgroundRules: [{
				backgroundColor: '#9DBFDB',
				yAxisSegment: 80
			},
			{
				backgroundColor: '#99EA99',
				yAxisSegment: 100,
				label: I18n.t('website.track.chart.bearing')
			},
			{
				backgroundColor: '#FFFC99',
				yAxisSegment: 120,
				label: I18n.t('website.track.chart.heart-rate-labels.fat-burn')
			},
			{
				backgroundColor: '#FFDC99',
				yAxisSegment: 140,
				label: I18n.t('website.track.chart.heart-rate-labels.aerobic')
			},
			{
				backgroundColor: '#FFC199',
				yAxisSegment: 160,
				label: I18n.t('website.track.chart.heart-rate-labels.great')
			},
			{
				backgroundColor: '#FE999B',
				yAxisSegment: 190,
				label: I18n.t('website.track.chart.heart-rate-labels.red-line')
			}
			],

		},
		'bearing': {
			step: 15,
			dataSets: [
				{
					yAxis: 'A',
					label: I18n.t('website.track.chart.bearing'),
					pointBackgroundColor: 'rgb(10, 240, 241)',
					borderColor: '#000',
					borderWidth: 1,
					property: 'bearing',
					unit: '°'
				}
			],
			backgroundRules: [{
				backgroundColor: '#FFF',
				yAxisSegment: 1
			},
			{
				backgroundColor: '#ccc',
				yAxisSegment: 360
			}
			]
		},
		'altitude': {
			step: 100,
			dataSets: [
				{
					steppedLine: 'before',
					yAxis: 'A',
					label: I18n.t('website.track.chart.altitude'),
					pointBackgroundColor: 'rgb(35, 75, 90)',
					borderColor: 'rgb(235, 50, 35)',
					borderWidth: 1,
					property: 'altitude',
					unit: this.getAlternateDistanceUnit()
				},
				{
					yAxis: 'A',
					label: I18n.t('website.track.chart.elevation'),
					pointBackgroundColor: 'rgb(180, 99, 77, 0.3)',
					borderWidth: 1,
					fill: true,
					backgroundColor : 'rgb(235, 50, 35, 0.2)',
					borderColor: 'rgb(235, 50, 35, 0.5)',
					property: 'elevation',
					unit: this.getAlternateDistanceUnit()
				}
			],
			backgroundRules: [{
				backgroundColor: '#f0fcbb',
				yAxisSegment: 1
			},
			{
				backgroundColor: '#a9d1e2',
				yAxisSegment: 5000
			}
			]
		},
		'speed': {
			step: 20,
			steppedLine: true,
			dataSets: [
				{
					yAxis: 'B',
					label: I18n.t('website.track.chart.speed'),
					pointBackgroundColor: 'rgb(35, 110, 190)',
					borderWidth: 1,
					fill: true,
					borderColor: 'rgb(35, 110, 190)',
					property: 'speed',
					order: 1,
					unit: this.getSpeedUnit()
				}
			]
		},
	}
}

