import TrackDetail from "../views/TrackDetail.js"
import SocialActions from "../utils/SocialActions"
import ToastNotification from "../utils/ToastNotification";
import ConfirmationModal from "../../common/ConfirmationModal";
import EditTimelineItem from "../views/EditTimelineItem";


export default class UserShow {
    constructor() {
        this.trackDetail = new TrackDetail();
        this.socialActions = new SocialActions();
        this.confirmationModal = new ConfirmationModal();
        window.application.setOnDataChangeListener(this);
        this.editTimelineItem = new EditTimelineItem();

        this.bindEvents();
    }

    onDataChanged(data) {
        this.trackDetail.onDataChanged(data);
        this.editTimelineItem.onDataChanged(data);
    }

    onUpdate(){
        this.socialActions.onUpdate();
    }

    onDestroy() {
        $("#switch-private").off("change");
        this.socialActions.onDestroy();
        this.trackDetail.onDestroy();
        this.editTimelineItem.onDestroy();
    }

    bindEvents() {
        const self = this;
        $("#private-switch").off("change").on("change", function () {
            const ajaxData = {
                url: window.application.getAPIUrl()+"/v1/track/"+$(this).data("track-id"),
                data: {track: {privacy_type: ($(this).is(":checked") ? "private_type" : "public_type")}},
                method: "PUT",
                beforeSend: (request) => {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };
            $.ajax(ajaxData).done((data) => {
                ToastNotification.make(I18n.t("website.notifications.privacy.status"));
            }).fail((err) => {});
        })

        $(".home-range-popup").off("click").on("click", function(e) {
            e.preventDefault();
    
            var screenWidth = window.innerWidth;
            var screenHeight = window.innerHeight;
            var width = screenWidth * 0.9; 
            var height = screenHeight * 0.9;
    
            var left = (screenWidth - width) / 2;
            var top = (screenHeight - height) / 2;
            var url = $(this).attr('href');
    
            var windowOptions = 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top + ',scrollbars=yes';
    
            window.open(url, 'Popup', windowOptions);
        });

        $(".js-delete-track").off("click").on("click", function(e){
            e.preventDefault();
            const element = this;
            self.confirmationModal.displayConfirm(I18n.t("website.track.delete_track.title"),
                I18n.t("website.track.delete_track.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.track.delete_track.positive_btn"),
                        callback: function() {
                            self.deleteTrack($(element).data("track-id"), $(element).data("username"));
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.track.delete_track.negative_btn")
                    }
                }                
            );
        });
    }

    deleteTrack(track_id, username) {
        const ajaxData = {
            url: window.application.getAPIUrl()+"/v1/track/"+track_id,
            data: {},
            method: "DELETE",
            beforeSend: (request) => {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(() => {
            $("#page-wraper").css("transition", "3s").css("opacity", "0");
            ToastNotification.make(I18n.t("website.track.delete_track.deleted_ok"));
            setTimeout(() => { document.location.href = "/user/"+username; }, 3000);
        }).fail((err) => {
            ToastNotification.make(I18n.t("website.track.delete_track.deleted_ko"));
        });
    }
}