import ToastNotification from "../utils/ToastNotification";

export default class ConfigurationFederationFrancaiseVolLibre {

    constructor() {
        this.validation = null;

        $.validator.addMethod(
            "uniqueUserKey",
            function(value, element) {
                let response = $.ajax({
                    type: "GET",
                    url: window.application.getAPIUrl()+"/v1/users/check_ffvl_user_key",
                    data: {ffvl_user_key: value},
                    async: false,
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    }
                });

                return response.responseJSON.exists !== true
            },
            I18n.t("website.configuration.index.ffvl.key_already_used")
        );

        this.validation_options = {
            rules:{
                "user[ffvl_user_key]": {
                    minlength: 8,
                    required: true,
                    uniqueUserKey: true
                }
            }
        }

        this.bindEvents();
    }


    bindEvents() {
        let self = this;

        $("#change_ffvl_key").unbind("click").click(function (e) {
            e.preventDefault();
            self.changeFederationFrancaiseVolLibreKey();
        });
    }

    onDataChanged(data) {
        let self = this;

        if(I18n.locale !== 'en'){
            let localeDepPath = "jquery-validation/dist/localization/messages_"+I18n.locale;
            this.validation_options.messages = require(localeDepPath)
        }
        this.validation = $("#ffvl_key_change").validate(this.validation_options);
    }

    onDestroy() {
        $("#change_ffvl_key").unbind("click");
        this.validation.destroy();
    }


    changeFederationFrancaiseVolLibreKey() {
        if($("#ffvl_user_key").val() !== "" && this.validation.form()){
            let self = this;
            let data = {
                ffvl_user_key: $("#ffvl_user_key").val()
            };

            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/profile/update_ffvl_user_key",
                data: {
                    user: data
                },
                method: "PUT",
                enctype: 'multipart/form-data',
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };


            $.ajax(ajaxData).done(function (data) {
                ToastNotification.make(I18n.t("website.configuration.index.ffvl.key_changed"));
            }).fail(function (err) {
                ToastNotification.make(err.responseJSON.errors.join("<br>"));
            });
        }
    }
}