import CesiumManagerWrapper from "./CesiumManagerWrapper";
import Abstract3DMapHandler from "./Abstract3DMapHandler";
import ConfirmationModal from "../../common/ConfirmationModal";
import PointsConverter from "./PointsConverter"

export default class ExternalTrackMap3DHandler extends Abstract3DMapHandler {

    constructor(first_appear_ad = 100, frequency_ad = 100) {
        super(first_appear_ad, frequency_ad);
        
        this.loaded_counter = 0;

        this.scene_type = "other";
    }

    start_timer() {
        super.start_timer("replay_map");
    }

    async init(data) {
        this.tracks = data.session.tracks;
        this.scene_type = data.scene_type;
        this.track_ids = data.track_ids;
        // this.show_related_tracks = data.show_related_tracks;
        // this.start_in_fpv_mode = data.start_in_fpv_mode;
        // this.activity_type = data.activity_type,
        // this.category_name = data.category_name,
        // this.started_at_date = data.started_at_date;
        // this.started_at_time = data.started_at_time;
        this.host = data.host;
        
        this.related_tracks = data.related_tracks;

        $("#loading_track_modal").modal({
            backdrop: 'static',
            keyboard: false
        });
        await this.loadTracks();

    }
    async loadTracks(related = false) {     
        const loading = this.tracks.map((x,i) => this.getTrack(x,i));
        await Promise.all(loading);
        
        await this.getAlongRoute(this.tracks[0].id);
        await this.startCesium();
    }

    async startCesium() {
        var userData = [];
        for (var i = 0; i < this.tracks.length; i++) {
            var data = {
                userId: this.tracks[i].user.id,
                userName: this.tracks[i].user.user_name,
                profileUrl: this.tracks[i].user.user_url,
                avatarUrl: this.tracks[i].user.profile_picture_url,
                track: {
                    id: this.tracks[i].id,
                    color: this.tracks[i].user.color,
                    maxAltitude: this.tracks[i].max_altitude,
                    started_at: this.tracks[i].started_at,
                    finished_at: this.tracks[i].finished_at,
                    created_at: this.tracks[i].created_at,
                    activity_icon: this.tracks[i].activity_icon,
                    time_zone: this.tracks[i].time_zone,
                    raw_points_data: this.points[i]
                },
            }
            if (i == 0) {
                data["isDefault"] = true;
            }
            userData.push(data);
        }

        await CesiumManagerWrapper.initScene("map3D", {
            users: userData,
            checkContestType: false,
            sceneType: this.scene_type,
            pathsLength: this.path_length,
            isFpvModeByDefault: this.start_in_fpv_mode,
            hostInUse: this.host,
            staticLabels: this.localities_around
        });


        setTimeout(function () {
            $("#loading_track_modal").modal("hide");
        }, 500)
    }

    getTrack(track,index) {
        var self = this;
        return new Promise((resolve, reject) => {
            var ajaxData = {
                url: window.application.getAPIUrl() + "/v1/external_track/" + track.id,
                method: "GET",
                beforeSend: this.beforeSendHandler,
            };

            $.ajax(ajaxData).done(function (data) {
                self.raw = data;
                var json = data;            
                self.points[index] = { 
                    points: json.points, 
                    points_header: json.points_header
                };
                resolve();
            }).fail((err) => reject(err));
        });
    }

}