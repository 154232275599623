import "jquery-validation"
import ToastNotification from "../utils/ToastNotification";
import * as select2 from "select2";

export default class CheckoutIndex {

    constructor() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.stripe = null;
        this.cardNumber = null;
        this.cardExpiry = null;
        this.cardCvc = null;
        this.validation = null;
        this.couponEl = null;
        this.couponRequest = null;
        this.couponData = null;
        this.creditsTiers = [];
        $("#billing").hide();
        
        this.style = {
            base: {
                iconColor: '#23B4F1',
                color: '#1D1F25',
                fontWeight: 400,
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#1D1F25',
                },
                '::placeholder': {
                    color: '#979797',
                },
            }
        };
        window.application.setOnDataChangeListener(this);

        $("#get_great_access").click(function() {
            $('html, body').animate({
                scrollTop: $('#start_great_access').offset().top - 70
            }, 500);
        });        

        window.application.loadCreditTiers().then(data => {
            this.creditsTiers = data;
            this.bindEvents();
        });

        $(document).ready(() => {
            this.scrollToAnchor();
        });            
    }

    scrollToAnchor() {
        const hash = window.location.hash;
        if (hash) {
            this.smoothScrollTo(hash);
        }
    }

    smoothScrollTo(selector) {
        const target = $(selector);
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - 100
            }, 500);
        } else {
            console.error('Target not found:', selector);
        }
    }

    bindEvents() {
        let self = this;

        $('input[name=plan]').change(function () {
            $('#description_box').text($(this).data('description'))
            self.setActualPrice();
        })

        const slider = document.getElementById("rs-range-line");
        const output = document.getElementById("rs-bullet");
        const totalSimulator = document.getElementById("total_simulator");

        const updateSliderValue = () => window.application.updateSliderValue(slider, output, totalSimulator, this.creditsTiers);
        updateSliderValue();

        slider.addEventListener("input", updateSliderValue);

        let options = {
            rules: {
                cc_name: {
                    required: true
                },
                cname: {
                    required: true
                },
                country: {
                    required: true
                },
                city: {
                    required: true
                },
                street: {
                    required: true
                },
                zip: {
                    required: true
                }
            }
        }

        if (I18n.locale !== 'en') {
            let localeDepPath = "jquery-validation/dist/localization/messages_" + I18n.locale
            options.messages = require(localeDepPath)
        }

        self.validation = $("#payment-form").validate(options);


        window.$('#js-country-select').select2({
            theme: 'bootstrap4',
            templateResult: this.formatCountry,
            templateSelection: this.formatCountry,
            language: I18n.locale
        });


        $('b[role="presentation"]').hide();
        $('.select2-selection__arrow').append('<i class="fas fa-chevron-down fs-12"></i>');
        $('.select2-selection__arrow').addClass("custom_arrow_select2");


        $("form#payment-form").on('ajax:beforeSend', function (e) {
            e.preventDefault();

            $("#checkout_btn").prop('disabled', true);

            $('#waiting_payment').modal({
                keyboard: false,
                backdrop: 'static'
            });

            $('#checkout_btn').html('<img height="20" src="' + $('#checkout_btn').data('loader') + '"> Payment in Progress')
            if (self.validation.valid()) {

                self.stripe.createPaymentMethod({
                    type: 'card',
                    card: self.cardNumber,
                    billing_details: {
                        name: $('input[name=cc_name]').val(),
                    }
                }).then(function (result) {

                    if (result.error) {
                        self.cardError(result.error.message);
                    } else {

                        // Otherwise send paymentMethod.id to your server (see Step 2)
                        let data = {
                            payment_method_id: result.paymentMethod.id,
                            product_id: $("input[type=radio][name=plan]:checked").data('product-id'),
                            country: parseInt($('select[name=country]').val()),
                            cc_name: $('input[name=cc_name]').val(),
                            coupon_code: $('input[name=coupon_code]').val()
                        }

                        if ($('input[name=billing_address]').is(':checked')) {
                            data.street = $('input[name=street]').val();
                            data.number = $('input[name=number]').val();
                            data.zip = $('input[name=zip]').val();
                            data.city = $('input[name=city]').val();
                            data.cname = $('input[name=cname]').val();
                            data.billing_address = $('input[name=billing_address]').val();

                        }

                        let ajaxData = {
                            url: "/checkout/payment",
                            method: "POST",
                            data: data
                        };

                        $.ajax(ajaxData).done(function (data) {
                            self.handleServerResponse(data);
                        }).fail(function (err) {

                            self.cardError(I18n.t("website.checkout.index.errors." + err.responseJSON.error.message));
                        });
                    }
                });
            } else {
                setTimeout(function () { $('#waiting_payment').modal('hide') }, 500)
            }
        });

        if ($("#billing_address").is(":checked")) {
            $("#billing").show("fast");
        } else {
            $("#billing").hide("fast");
        }

        $("#billing_address").unbind("change").change(function () {
            if ($(this).is(":checked")) {
                $("#billing").show("fast");
            } else {
                $("#billing").hide("fast");
            }
        })
    }

    onDataChanged(data) {
        let self = this;
        this.stripe = Stripe(data.stripe_public_key);
        this.initStripe();
        this.couponEl = $('input[name=coupon_code]');

        this.cardNumber.on('change', function (event) {
            let displayError = document.getElementById('card-errors');
            if (event.error) {
                $("#card_container").addClass("border-danger");
                $("#card_container").removeClass("border-lighter-gray");
                displayError.textContent = event.error.message;
            } else {
                $("#card_container").removeClass("border-danger");
                $("#card_container").addClass("border-lighter-gray");
                displayError.textContent = '';
            }
        });

        this.cardExpiry.on('change', function (event) {
            let displayError = document.getElementById('cardExpiry-errors');
            if (event.error) {
                $("#cardExpiry_container").addClass("border-danger");
                $("#cardExpiry_container").removeClass("border-lighter-gray");
                displayError.textContent = event.error.message;
            } else {
                $("#cardExpiry_container").removeClass("border-danger");
                $("#cardExpiry_container").addClass("border-lighter-gray");
                displayError.textContent = '';
            }
        });

        this.cardCvc.on('change', function (event) {
            let displayError = document.getElementById('cardCvc-errors');
            if (event.error) {
                $("#cardCvc_container").addClass("border-danger");
                $("#cardCvc_container").removeClass("border-lighter-gray");
                displayError.textContent = event.error.message;
            } else {
                $("#cardCvc_container").removeClass("border-danger");
                $("#cardCvc_container").addClass("border-lighter-gray");
                displayError.textContent = '';
            }
        });

        this.couponEl.off('focus').on('focus', () => {           
            this.couponEl.removeClass("border-danger");
            this.couponEl.addClass("border-lighter-gray");            
        })
        this.couponEl.off('blur').on('blur', () => {
            this. hideCouponMessages();
            this.processCouponOut();
        });;
    }
    
    calculateCost(size) {   
        let totalCredits = 0;
        let sizeInMb = size; 
    
        this.creditsTiers.forEach(tier => {
            if (sizeInMb > tier.min_mb_size) {
                const sizeInTier = Math.min(sizeInMb, tier.max_mb_size) - tier.min_mb_size;
                const creditsInTier = Math.ceil(sizeInTier / tier.cost_in_tier);
                totalCredits += creditsInTier;
            }
    
            if (sizeInMb <= tier.max_mb_size) {
                return false; 
            }
        });
    
        return Math.ceil(totalCredits);
    }    
      
    processCouponOut() {
        this.couponData = null;
        const discountCode = this.couponEl.val();
        if (!discountCode) {            
            this.setActualPrice();
            return;
        }
        if (this.couponRequest) {
            this.couponRequest.abort();
        }
        let ajaxData = {
            url: window.application.getAPIUrl() + "/v1/stripe/get_coupon/" + discountCode,
            method: "get"
        };
        let self = this;
        this.couponRequest = $.ajax(ajaxData);
        this.couponRequest.done((data) => {
            if (data && data.valid == false) {
                self.setCouponError("invalid");
                return;
            }
            if (data && data.error) {
                self.setCouponError(data.error.message);
                return;
            }
            if (data && data.valid) {
                this.couponData = data;
                $("#coupon_code-valid").show();
            }


        }).fail((err) => {
            self.setCouponError(err.responseJSON.error.message)
        }).always(() => {
            self.couponRequest = null;
            self.setActualPrice();
        });;

    }
    setActualPrice() {
        let currentPriceData = $("input[type=radio][name=plan]:checked").data('price');

        if (this.couponData) {
            const priceData = currentPriceData.replace("$","$ ").replace("£","£ ").split(" ");
            if (priceData.length == 2) {

                let priceValueIndex = !isNaN(parseFloat(priceData[0])) ? 0 : 1;
                let priceCurrencyIndex = isNaN(parseFloat(priceData[0])) ? 0 : 1;
                const strPrice = priceData[priceValueIndex];
                let amount = parseFloat(priceData[priceValueIndex])
                if (this.couponData.amount_off) {
                    amount -= this.couponData.amount_off/100;
                }
                if (this.couponData.percent_off) {
                    amount *= (1 - this.couponData.percent_off/100);
                }

                priceData[priceCurrencyIndex] = priceData[priceCurrencyIndex].trim();
                priceData[priceValueIndex] = this.getFormattedPrice(amount,this.getSeparator(strPrice));
                currentPriceData = priceData.join(" ");
            }
        }

        $('#price_box').text(currentPriceData);

    }
    getFormattedPrice(price,separator) {
        return `${Math.trunc(price)}${separator}${(price % 1*100).toFixed(0)}`
    }
    getSeparator(strPrice) {
        return strPrice.replace(/\d+/g, '');

    }
    hideCouponMessages() {
         $("#coupon_code-errors").hide();
         $("#coupon_code-valid").hide();
        
    }
    setCouponError() {
        
        this.couponEl.addClass("border-danger");
        this.couponEl.removeClass("border-lighter-gray");
        $("#coupon_code-errors").show();
    }
    onDestroy() {
        window.$('#js-country-select').select2('destroy');
        if (this.validation !== null && this.validation !== undefined) {
            this.validation.destroy();
        }
    }

    initStripe() {
        this.elements = this.stripe.elements({ locale: I18n.locale });
        this.cardNumber = this.elements.create('cardNumber', { style: this.style });
        this.cardNumber.mount('#cardNumber');
        this.cardExpiry = this.elements.create('cardExpiry', { style: this.style });
        this.cardExpiry.mount('#cardExpiry');
        this.cardCvc = this.elements.create('cardCvc', { style: this.style });
        this.cardCvc.mount('#cardCvc');
    }

    // Submit the form with the token ID.
    stripeTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById('payment-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
    }

    cardError(text) {
        /*let errorElement = document.getElementById('card-errors');
        errorElement.textContent = text;*/
        ToastNotification.make(text);
        $('#checkout_btn').prop('disabled', false);
        $('#checkout_btn').html($('#checkout_btn').data('text'));
        setTimeout(function () { $('#waiting_payment').modal('hide') }, 500)
    }

    formatCountry(act) {
        let $act = "";
        if (!act.id) {
            return act.name;
        } else if ($(act.element).val() === "0") {
            $act = $(
                '<span class="lh-7 fs-16 px-2">' + act.text + '</span>'
            );
        } else {
            $act = $(
                '<span class="lh-7 fs-16">\n<span class="mx-2 rounded flag-icon flag-icon-' + $(act.element).data("country-code") + '"></span>' + act.text + '</span>'
            );
        }
        return $act;
    };

    handleServerResponse(response) {
        let self = this;
        if (response.error != undefined) {
            this.cardError(response.error);
        } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            this.stripe.confirmCardPayment(
                response.payment_intent_client_secret
            ).then(function (result) {
                if (result.error) {
                    let message = "";
                    if (result.error.decline_code !== undefined) {
                        message = result.error.decline_code;
                    } else if (result.error.code !== undefined) {
                        message = result.error.code;
                    } else {
                        message = result.error;
                    }
                    self.cardError(I18n.t("website.checkout.index.errors." + message));
                } else {
                    let data = {
                        payment_intent_id: result.paymentIntent.id,
                        product_id: $("input[type=radio][name=plan]:checked").data('product-id'),
                        country: parseInt($('select[name=country]').val()),
                        cc_name: $('input[name=cc_name]').val(),
                        coupon_code: $('input[name=coupon_code]').val()
                    }

                    if ($('input[name=billing_address]').is(':checked')) {
                        data.street = $('input[name=street]').val();
                        data.number = $('input[name=number]').val();
                        data.zip = $('input[name=zip]').val();
                        data.city = $('input[name=city]').val();
                        data.cname = $('input[name=cname]').val();
                        data.billing_address = $('input[name=billing_address]').val();
                    }

                    fetch(window.application.getAPIUrl()+"/v1/checkout/confirm_payment/", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-STL-Token': cookies.get("STL-Token"),
                        },
                        body: JSON.stringify(data)
                    }).then(function (confirmResult) {
                        return confirmResult.json();
                    }).then(self.handleServerResponse);
                }
            });
        } else {
            window.location = response.return_url;
        }
    }
}